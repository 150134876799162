import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromApp from 'src/app/store/';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { browserRefresh } from 'src/app/app.component';


@Injectable()

export class PageEnterGuard implements CanActivate {
  subscriptions: Subscription[] = [];
  constructor(
    private store$: Store<fromApp.State.State>,
    private router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (browserRefresh.refreshed && environment.name !== 'default') {
      return this.router.navigateByUrl('registration-dashboard');
    } else if (!browserRefresh.refreshed && environment.name !== 'default') {
      this.subscriptions.push(
        this.store$.pipe(select(fromApp.Selectors.getLoaders)).subscribe(
          loaders => {
            console.log('[Page Enter Guard] Get initial loading state: ' + JSON.stringify(loaders));
            if (loaders.length > 0) {
              return this.isStoreNotEmpty();
            }
          }
        )
      );
    }
    return true;
  }

  private isStoreNotEmpty() {
    this.store$.pipe(select(fromApp.Selectors.getAppState)).subscribe(
      appstate => {
        console.log('[Page Enter Guard] getAppState: ' + JSON.stringify(appstate));
        if (appstate.internetRegistration) {
          return true;
        } else {
          this.unsubscribe();
          return this.router.navigateByUrl('registration-dashboard');
        }
      }
    );
  }

  public unsubscribe() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }
}
