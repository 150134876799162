import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  private config = {
    instrumentationKey: environment.appInsights.instrumentationKey,
    autoTrackPageVisitTime: true
  };
  private appInsights: ApplicationInsights;
  constructor() {
    if (!this.appInsights) {
      this.appInsights = new ApplicationInsights({config: this.config});
      this.appInsights.loadAppInsights();
      this.loadCustomTelemetryProperties();
      // If you want to log UserId in metrices
      // Logic to get logged in User
      // var user = User.GetUser();
      // AppInsights.setAuthenticatedUserContext(user);
    }
  }

  logPageView(name?: string, uri?: string) {
      this.appInsights.trackPageView({name, uri});
  }
  startNavigationEvent(url: string) {
    this.appInsights.startTrackEvent('Navigation ' + url);
  }
  endNavigationEvent(url: string) {
    this.appInsights.stopTrackEvent('Navigation ' + url, { type: 'PAGE LOAD TIME' });
  }
  logStartTrackpage(name?: string) {
    this.appInsights.appInsights.startTrackPage(name);
  }
  logStopTrackpage(name?: string, uri?: string, properties?: any) {
    this.appInsights.appInsights.stopTrackPage(name, uri, properties);
    this.flush();
  }
  logEvent(name: string, properties?: any) {
    this.appInsights.appInsights.trackEvent({name}, properties);
  }
  logException( error: Error, properties?: any ) {
    this.appInsights.appInsights.trackException({exception: error}, properties);
  }
  logTrace(message: string, properties?: any) {
    this.appInsights.appInsights.trackTrace({message}, properties);
  }
  flush() {
    this.appInsights.flush();
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  startTraceServiceEvent(url: string) {
    this.appInsights.startTrackEvent('AppService ' + url);
  }
  endTraceServiceEvent(url: string) {
    this.appInsights.stopTrackEvent('AppService ' + url, { type: 'Service time' });
  }

  private loadCustomTelemetryProperties() {
    this.appInsights.addTelemetryInitializer(envelope => {
        const item = envelope.baseData;
        item.properties = item.properties || {};
        item.properties['ApplicationPlatform'] = 'WEB';
        item.properties['ApplicationName'] = 'Registration-UI';
      }
    );
  }

  // https://tutorialsforangular.com/2020/02/03/adding-azure-application-insights-to-your-angular-app/
}
