import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AppInsightsService } from 'src/app/core/appinsights.service';


@Component({
  selector: 'app-idle-user-modal',
  templateUrl: './idle-user-modal.component.html',
  styleUrls: ['./idle-user-modal.component.scss']
})
export class IdleUserModalComponent implements OnInit, OnDestroy {
  sessionTimeoutTitle = 'Need more time?';
  @Input() public timeoutDuration = environment.timeoutDuration;
  timeoutRemaining: string;
  interval;
  constructor(public activeModal: NgbActiveModal,
              private appInsightService: AppInsightsService) { }

  ngOnInit() {
    this.startTimer();
  }

  ngOnDestroy(): void {
    this.pauseTimer();
  }

  logEvent(event: string): void {
    this.appInsightService.logEvent( 'idle-user event clicked: ' + event);
  }

  private startTimer() {
    this.interval = setInterval(() => {
      if (this.timeoutDuration > 0) {
        this.timeoutDuration--;
        this.timeoutRemaining = this.toHHMMSS(this.timeoutDuration);
      } else {
        this.sessionTimeoutTitle = 'Your session has timed out';
      }
    }, 1000);
  }

  private pauseTimer() {
    clearInterval(this.interval);
  }

  private toHHMMSS(sec_num: number) {
    const hours   = Math.floor(sec_num / 3600);
    const minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    const seconds = sec_num - (hours * 3600) - (minutes * 60);
    const hoursStr   =  hours < 10 ? '0' + hours : hours;
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    const secondsStr = seconds < 10 ? '0' + seconds : seconds;

    return minutesStr + ':' + secondsStr;
}
}

