import { AppState } from 'src/app/store/reducers/app.state';
import { ContactInfoState } from '../reducers/contact-info.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Selector functions
const getAppState = createFeatureSelector<AppState>('appstate');

export const getLoaders = createSelector(
  getAppState,
  state => state.loaders
);

export const getInternetRegistration = createSelector(
  getAppState,
  ( state: AppState ) => state.internetRegistration
);

export const getPhoneExtn = createSelector(
  getAppState,
  ( state: AppState ) => state.idam.phoneExtnHomeOwner
);

export const getContactInfoState = createFeatureSelector<ContactInfoState>('contactinfostate');

export const getContactInfo = createSelector(
  getContactInfoState,
  state => state.contactInfo
);


export const getContactInfoUpdatedFlag = createSelector(
  getContactInfoState,
  state => state.updated
);
