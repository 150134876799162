import { Store } from '@ngrx/store';
import { AuthService } from '@wsbc/ux-lib-security';
import * as fromApp from 'src/app/store';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { browserRefresh } from 'src/app/app.component';

export function initApp(authService: AuthService, store$: Store<fromApp.State.State>) {
    return () => new Promise<void>((resolve) => {
      console.log('Registration app is initializing');
      browserRefresh.refreshed = true;
      authService.initialize()
        .then(() => {
          authService.isAuthenticated.pipe(take(1)).subscribe(isAuthenticated => {
            if (isAuthenticated) {
              authService.user.pipe(take(1)).subscribe(u => {
                console.log('User is logged in', u);
                const email = environment.name === 'default' ? u.userName : u.profile.email;
                const phoneExtnHomeOwner = environment.name === 'default' ? '' : ((u.profile.extension && u.profile.extension.length <= 5) ? u.profile.extension : '') ;
                const idamUserId = environment.name === 'default' ? '' : u.profile.userid;
                const initialState = {
                  internetRegistration: {
                    email: email,
                    userChoice: {}
                  },
                  idam: {}
                };

                if (environment.name === 'default') {
                  const [firstName, lastName] = u.name.split(',');

                  initialState.internetRegistration.userChoice = {
                    contactNameLast: firstName,
                    contactNameFirst:  lastName,
                    contactNameMiddle: firstName.charAt(0),
                    contactTelephone: '6546464644',
                    contactEmail: u.userName,
                  };
                } else if (u && u.profile) {
                  initialState.internetRegistration.userChoice = {
                    contactTelephone: u.profile.telephoneNumber,
                    contactNameFirst: u.profile.firstname,
                    contactNameMiddle: u.profile.middle,
                    contactNameLast: u.profile.lastname,
                    contactEmail: u.profile.email
                  };
                }
                // save original idam user info for late use.
                initialState.idam = {
                  email: email,
                  userChoice: initialState.internetRegistration.userChoice,
                  phoneExtnHomeOwner: phoneExtnHomeOwner,
                  userId:idamUserId
                };

                store$.dispatch(fromApp.Actions.SaveAllAppState({payload: initialState}));
              });
              resolve();
            } else {
              console.log('User is not logged in2');
            }
          });
        })
        .catch(() => {
          console.log('User is not logged in1');
          authService.redirectToSignInPage();
        });
    });
  }
