import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { AppInsightsService } from 'src/app/core/appinsights.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { Store, select } from '@ngrx/store';
import * as fromApp from '../../store';
import { CommonFunctions } from '../common/common-functions';
import * as AppActions from '../../store/actions/app.actions';

@Injectable()
export class BrowserStorageHandler {
  subscriptions: Subscription[] = [];
  constructor(
    private appInsightService: AppInsightsService,
    private localStorageService: LocalStorageService,
    private store$: Store<fromApp.State.State>,
    private commonFunctions: CommonFunctions,
  ) { }
  public startLocalStorageProcess() {
    // Todo: this localstorage usage is only for testing and it should be replaced with get api service.
    if (environment.name === 'default' ) {
      this.subscriptions.push(
        this.store$.pipe(select(fromApp.Selectors.getAppState)).subscribe(
          appstate => {
            setTimeout(() => {
              this.storeOrRetrieveAppStateFromLocalStorage(appstate);
            });
          }
        )
      );
    }
  }

  private storeOrRetrieveAppStateFromLocalStorage(appstate: any) {
    // if server response stored in app store then save them into local storage
    if (appstate.internetRegistration != null && !this.commonFunctions.isEmptyObject(appstate.internetRegistration)) {
      // console.log('[App] Saving AppState to local storage: ' + JSON.stringify(appstate));
      this.localStorageService.storeOnLocalStorage(appstate);
    } else { // if first time loading or store cleared after refreshing page then reload from local storage if any.
      // console.log('[App] Current AppState is: ' + JSON.stringify(appstate));
      const appstateFromLocal = this.localStorageService.getAppStateFromLocalStorage();
      if (appstateFromLocal != null) {
        const internetRegistration = appstateFromLocal.internetRegistration;
        if (internetRegistration != null && !this.commonFunctions.isEmptyObject(internetRegistration)) {
          // console.log('[App] Loading AppState from local storage: ' +  + JSON.stringify(appstateFromLocal));
          this.store$.dispatch(
            AppActions.SaveAllAppState(
               {payload: appstateFromLocal}));
        }
      }
    }
  }

  public unsubscribe() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }

  public cleanAppStateFromLocalStorage() {
    this.localStorageService.cleanAppStateFromLocalStorage();
  }
}
