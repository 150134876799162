import { ContactInfoState, initialState } from './contact-info.state';
import * as ContactInfoActions from '../actions/contact-info.actions';
import { createReducer, on, Action } from '@ngrx/store';

export const contactInfoReducer = createReducer(
    initialState,
    on(ContactInfoActions.SendContactInfoSuccess, (state, {payload}) => ({
        ...state,
        contactInfo: payload,
        updated: true
    })),
    on(ContactInfoActions.ClearContactInfoUpdatedFlagSuccess, (state) => ({
        ...state,
        updated: false
      }))
  );

export function reducer(state: ContactInfoState | undefined, action: Action) {
    return contactInfoReducer(state, action);
}