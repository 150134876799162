import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpInterceptor,
  HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AppInsightsService } from 'src/app/core/appinsights.service';

@Injectable()
export class StatusInterceptor implements HttpInterceptor {

  iregRefNum: string;
  dateStarted: string;
  dateSent: string;
  constructor(
    private router: Router,
    private appInsightService: AppInsightsService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.setHttpHeaders(request);
    return next.handle(request).pipe(
      tap(response => {
        if (response instanceof HttpResponse) {
          if (response.body != null &&  response.body.registrationSession != null) {
            this.iregRefNum = response.body.registrationSession.iRegReferenceNumber;
            //The initial datetime value in the database, when first save happened             
            this.dateStarted =new Date(response.body.registrationSession.iregMstrBusinessAddedDate).toUTCString();                                  
            this.dateSent = new Date().toUTCString(); //for AI report, keep track of each sent request's date
            this.appInsightService.logEvent('Registration-StatusInterceptor',
              {
                application: 'Registration-StatusInterceptor',
                registrationRefNum: this.iregRefNum,
                routerUrl: this.router.url,
                apiUrl: response.url,
                dateStarted: this.dateStarted,
                dateSubmitted : this.dateSent
              }
            );
          }
        }
      }),

      catchError(response => {
        const requestBody = JSON.parse(request.body);
        if ( requestBody != null && requestBody.registrationSession != null
          && requestBody.registrationSession.iRegReferenceNumber != null ) {
          this.iregRefNum = requestBody.registrationSession.iRegReferenceNumber;
        }

        this.appInsightService.logException(
          new Error('Registration-StatusInterceptor error'),
          {
            application: 'Registration-StatusInterceptor error',
            message: response.message,
            registrationRefNum: this.iregRefNum,
            routerUrl: this.router.url,
            apiUrl: response.url
          }
        );

        const urlName = `https://${response.url.split('/')[2]}`;
        if (response instanceof HttpErrorResponse) {
          if (urlName === environment.registrationApiUrl) {
            this.router.navigateByUrl('error-page');
          }
        }
                
        const  err = new Error(response);
        return throwError(() => err);
        
      })
    );
  }

  private setHttpHeaders(request: HttpRequest<any>):  HttpRequest<any>{
    request = request.clone({ setHeaders: { 'Content-Type': 'application/json'} });
    request = request.clone({ setHeaders: { 'Origin':  window.location.origin} });
    request = request.clone({ setHeaders: { 'Accept': 'application/json'} });
    request = request.clone({ setHeaders: { 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT'} });
    request = request.clone({ setHeaders: { 'Access-Control-Allow-Origin': 'https://*.worksafebc.com'} });

    return request;
  }
}
