import { Inject, Injectable } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AppSessionState, SurveyPopupState, RefreshPagePopupState, NacisAutoRedirectState, AutoSavePopupState } from './app.session.state';
import { EASConstants, EnvironmentSettings } from '../shared/common/common-constants';
// key that is used to access the data in local storage
const REFRESH_PAGE_POPUP_KEY = 'RefreshPagePopupState';
const STORAGE_KEY = 'AppSessionState';
const NAICS_AUTOREDIRECT_KEY = 'NaicsAutoRedirectState';
const EAS_KEY = 'EasKey';
const ENVIRONMENT_SETTINGS = 'EnvironmentSettings';
const AUTO_SAVE_POPUP_KEY = 'AutoSavePopupState';
const UXENVIRONMENT_SETTINGS_SOLEPROP = 'EnvironmentSettings_SoleProp';

export const SESSION_SERVICE_STORAGE = SESSION_STORAGE;
@Injectable()
export class SessionStorageService {
    constructor(@Inject(SESSION_SERVICE_STORAGE) private storage: StorageService) { }

    public storeOnSessionStorage(appSessionState: AppSessionState): void {
        // insert appstate to local storage
        this.storage.set(STORAGE_KEY, appSessionState);
        console.log(this.storage.get(STORAGE_KEY) || 'Session storage is empty');
    }

    public getAppSessionStateFromSessionStorage(): AppSessionState {
        const appSessionState = this.storage.get(STORAGE_KEY);
        return appSessionState ? appSessionState : null;
    }

    public cleanAppSessionStateFromSessionStorage(): void {
        this.storage.remove(STORAGE_KEY);
    }

    public storeNaicsAutoRedirectOnSessionStorage(nacisAutoRedirectState: NacisAutoRedirectState): void {
        // insert appstate to local storage
        this.storage.set(NAICS_AUTOREDIRECT_KEY, nacisAutoRedirectState);
        console.log(this.storage.get(STORAGE_KEY) || 'Session storage is empty');
    }

    public getNacisAutoRedirectStateFromSessionStorage(): NacisAutoRedirectState {
        const nacisAutoRedirectState = this.storage.get(NAICS_AUTOREDIRECT_KEY);
        return nacisAutoRedirectState ? nacisAutoRedirectState : null;
    }

    public cleanNacisAutoRedirectStateFromSessionStorage(): void {
        this.storage.remove(NAICS_AUTOREDIRECT_KEY);
    }

    public storeOnSurveyPopupSessionStorage(storageKey: string, surveyPopupState: SurveyPopupState): void {
        this.storage.set(storageKey, surveyPopupState);
        console.log(this.storage.get(storageKey) || 'Session storage is empty');
    }

    public getSurveyPopupStateFromSessionStorage(storageKey: string): SurveyPopupState {
        const surveyPopupState = this.storage.get(storageKey);
        return surveyPopupState ? surveyPopupState : null;
    }

    public cleanSurveyPopupStateFromSessionStorage(storageKey: string): void {
        this.storage.remove(storageKey);
    }

    public setSkipRefreshPagePopupInSessionStorage(): void {
        this.storage.set(REFRESH_PAGE_POPUP_KEY, new RefreshPagePopupState(true));
        console.log(this.storage.get(REFRESH_PAGE_POPUP_KEY) || 'Session storage is empty');
    }


    public getRefreshPagePopupStateFromSessionStorage(): RefreshPagePopupState {
        const refreshPagePopupState = this.storage.get(REFRESH_PAGE_POPUP_KEY);
        return refreshPagePopupState ? refreshPagePopupState : null;
    }

    public cleanRefreshPagePopupStateFromSessionStorage(): void {
        this.storage.remove(REFRESH_PAGE_POPUP_KEY);
    }

    public setEASconstants(values): void {
        const jsonObject = {};
        values.map((ele) => {
            jsonObject[ele.code] = ele.value;
        });

        this.storage.set(EAS_KEY, jsonObject);
    }

    public setEnvironmentSettings(values): void {
        const jsonObject = {};
        values.map((ele) => {
          jsonObject[ele.key] = ele.value;
        });

      this.storage.set(ENVIRONMENT_SETTINGS, jsonObject);
    }

    public getEASconstants(key: EASConstants) {
        return this.storage.get(EAS_KEY)[key];
  }

    public getEnvironmentSettings(key: EnvironmentSettings) {
      return this.storage.get(ENVIRONMENT_SETTINGS)[key];
    }

    public storeOnAutoSavePopupStateSessionStorage(popupState: AutoSavePopupState): void {
        this.storage.set(AUTO_SAVE_POPUP_KEY, popupState);
        console.log(this.storage.get(AUTO_SAVE_POPUP_KEY) || 'Session storage is empty');
    }

    public getAutoSavePopupStateFromSessionStorage(): AutoSavePopupState {
        const popupState = this.storage.get(AUTO_SAVE_POPUP_KEY);
        return popupState ? popupState : null;
    }
    
    public setUXEnvironmentSettingsSoleProp(value): void {
        this.storage.set(UXENVIRONMENT_SETTINGS_SOLEPROP, value);
        console.log(this.storage.get(UXENVIRONMENT_SETTINGS_SOLEPROP) || 'Session storage is empty');
    }

    public getUXEnvironmentSettingsSoleProp() {
        return this.storage.get(UXENVIRONMENT_SETTINGS_SOLEPROP);
    }


}
