import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppInsightsService } from './core/appinsights.service';
import { BrowserStorageHandler } from './shared/browser-storage-handler/browser-storage-handler';
import { Subscription } from 'rxjs';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CanadaPostApiService } from './shared/canada-post/canada-post-api.service';



export let browserRefresh = { refreshed: false };
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  title = '<h1>registration</h1>';
  constructor(
    private gtmService: GoogleTagManagerService,
    private appInsightService: AppInsightsService,
    private browserStorageHandler: BrowserStorageHandler,
    private router: Router,
    private canadaPostApiService: CanadaPostApiService) {
    this.subscriptions.push(
      router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          browserRefresh.refreshed = !router.navigated; // if no router history, assume it is refreshed
        }
      })
    );
  }

  ngOnInit(): void {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'appPageview',
          appPagepath: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
    this.browserStorageHandler.startLocalStorageProcess();
    if (this.router && this.router.events) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.appInsightService.startNavigationEvent(event.url);
        } else if (event instanceof NavigationEnd) {
          this.appInsightService.endNavigationEvent(event.url);
        }
      });
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.canadaPostApiService.load('id'), 0);
  }

  ngOnDestroy(): void {
    console.log('[App] on destroy is triggered');
    this.browserStorageHandler.unsubscribe();
    this.browserStorageHandler.cleanAppStateFromLocalStorage();
    this.unsubscribeAll();
  }
  private unsubscribeAll() {
    console.log('[App] subscriptions length: ' + JSON.stringify(this.subscriptions[0]));
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }
}
