import { ErrorHandler, Injector, Injectable } from '@angular/core';
import { AppInsightsService } from './appinsights.service';

@Injectable()
export class ApplicationInsightsErrorHandler implements ErrorHandler {

  constructor(private injector: Injector)
  {
  }

  handleError(error: any): void {
    try {
      this.injector.get<AppInsightsService>(AppInsightsService).logException(error);
    } catch (ex) {
      console.log('[ApplicationInsightsErrorHandler] ex:' + ex);
    }
    console.log('[ApplicationInsightsErrorHandler] error:' + error);
  }
}