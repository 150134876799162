import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ContentLayoutComponent } from '../../layouts/content-layout/content-layout.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmLeaveComponent } from '../confirm-leave-modal/confirm-leave.component';
import { LocationStrategy } from '@angular/common';
import { AuthService } from '@wsbc/ux-lib-security';
import { SessionStorageService } from 'src/app/services/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PageExitGuard implements CanDeactivate<ContentLayoutComponent> {

  subscriptions: Subscription[] = [];
  constructor(private modalService: NgbModal,
              private location: LocationStrategy,
              private authService: AuthService,
              private sessionStorageService: SessionStorageService) {
  }

  canDeactivate(component: ContentLayoutComponent,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    // This is to ensure that back btn doesn't go to previous page for application-confirm and error-page.
    // InternetRegistration/Default.aspx - This is the old ireg starting page
    if (nextState && nextState.url &&
      (currentState.url.includes('/application-confirm') &&  nextState.url.includes('/review-submit')) ||
      (currentState.url.includes('/applicationconfirm') &&  nextState.url.includes('/reviewsubmit')) ||
      (currentState.url.includes('/error-page') &&
      !nextState.url.includes('/InternetRegistration/Default.aspx') &&
      !nextState.url.includes('/externalRedirect'))) {
      if (history) {
        history.pushState(null, null, window.location.href);
        this.location.onPopState(() => {
          history.pushState(null, null, window.location.href);
        });
      }
      return false;
    }

    // When exit from landing page or confirm page and not from OneStop or
    // When click the exit from error page
    // Sign out and let IDAM redirect to OP page
    if (nextState.url.includes('/externalRedirect')
        && (currentState.url.includes('/landingpage') || currentState.url.includes('/confirm') || currentState.url.includes('/error-page')
        || currentState.url.includes('/application-confirm') || currentState.url.includes('/applicationconfirm'))
        && !currentState.url.includes('sessionID')) {
        this.sessionStorageService.setSkipRefreshPagePopupInSessionStorage();
        this.authService.signOut();
        return false;
    } else if ((currentState.url.includes('/registration-dashboard') || currentState.url.includes('/error-page')) && nextState.url.includes('/externalRedirect')) {
      this.authService.signOut();
    } else if (nextState.url.includes('/externalRedirect')) {
        return new Promise((resolve) => {
          this.getConfirmation().then(x => {
            resolve(x);
          }).catch(err => {
            resolve(false);
          });
        });
    }

    return true;
  }

  getConfirmation(): Promise<boolean> {
    const config: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      windowClass : 'registration-modal medium-modal'
    };
    const modal = this.modalService.open(ConfirmLeaveComponent, config);
    return modal.result;
  }

}
