import { AppState } from 'src/app/store/reducers/app.state';
import * as fromRoot from 'src/app/store';

export interface State extends fromRoot.State.State {
    appstate: AppState;
    contactinfostate: ContactInfoState;
  }

export interface ContactInfoState {
    contactInfo: any;
    updated: any;
}

export const initialState: ContactInfoState = {
    contactInfo: {},
    updated: {}
};
