import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppInsightsService } from 'src/app/core/appinsights.service';
import { Router } from '@angular/router';
import { SessionStorageService } from 'src/app/services/session-storage.service';

@Component({
  selector: 'app-simple-modal',
  templateUrl: './simple-modal.component.html',
  styleUrls: ['./simple-modal.component.scss']
})
export class SimpleModalComponent {
  message: string = 'Message here...';
  constructor(public activeModal: NgbActiveModal,
              private appInsightService: AppInsightsService,
              private router: Router,
              private sessionStorageService: SessionStorageService) { }

  okClicked(): void {
    this.appInsightService.logEvent(this.router.url + ' OK button clicked from Exit modal');
    this.sessionStorageService.setSkipRefreshPagePopupInSessionStorage();
    this.activeModal.close(true);
  }
}

