import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as RegValidators from 'src/app/shared/reactive-validators/fields.validator';
import { FormFunctions } from '../../form-functions';
import { ContinueBtnHoverTxt } from 'src/app/shared/common/common-constants';
import { ClassificationSerachInfo, ClassificationUnit } from 'src/app/store/feature-stores/classification-search-store/models/classification-unit';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { CommonFunctions } from 'src/app/shared/common/common-functions';


@Injectable()
export class ClassificationSearchViewCoreFunctions {
  classificationUnits?: ClassificationUnit[];
  
  clickSearchButtonToProceedErrorMessage = 'Please click the \'Search\' button to proceed.';
  summaryOfOperationsInvalidMsg = 'Please enter a description of the business operations in B.C.';
  summaryOfOperationsMinCharsMsg = 'Please enter at least 4 characters the description of the business operations in B.C.';
  productOrServiceInvalidMsg = "Please enter at least 1 character.";

  productDetails1Fields = {
    description1: new UntypedFormControl('',[RegValidators.requiredValidator(this.productOrServiceInvalidMsg, 1)]),
    percentage1: new UntypedFormControl('')
  }
  productDetails2Fields = {
    description2: new UntypedFormControl('',[RegValidators.requiredValidator(this.productOrServiceInvalidMsg, 1)]),
    percentage2: new UntypedFormControl('')
  }
  productDetails3Fields = {
    description3: new UntypedFormControl('',[RegValidators.requiredValidator(this.productOrServiceInvalidMsg, 1)]),
    percentage3: new UntypedFormControl('')
  }
  
  classificationSearchFields = {      
    classificationUnit: new UntypedFormControl('', [Validators.required]),
    classificationUnitScore: new UntypedFormControl(''),
    noMatchingClassificationUnitsFound: new UntypedFormControl(''),
    searchButtonClicked: new UntypedFormControl(''),
    searchKey: new UntypedFormControl('',  [RegValidators.requiredAndMinCharValidator(this.summaryOfOperationsInvalidMsg, 4,this.summaryOfOperationsMinCharsMsg)])
  };  

  constructor( 
    private formFunctions: FormFunctions,
    private commonFunctions: CommonFunctions,
    private sessionStorageService: SessionStorageService) {  }

  public createClassificationSearchForm(formGroup: UntypedFormGroup) {   
    try
    {
      this.formFunctions.addFormFields(this.classificationSearchFields, formGroup);
      this.formFunctions.addFormFields(this.productDetails1Fields, formGroup);
    } catch(ex) {
      console.log('createClassificationSearchForm ex=' + ex.message);
    }    
  }

  
  public getSearchPayload(formGroup: UntypedFormGroup, irRefNumber: string, searchByML: string) {    
    const businessSummary = this.getFormControlValue(formGroup.controls.searchKey);  
    let descriptions = this.getFormControlValue(formGroup.controls.description1) + 
          ' ' + this.getFormControlValue(formGroup.controls.description2) +
          ' ' + this.getFormControlValue(formGroup.controls.description3);
    descriptions = descriptions.trim();
    var mergedClassificationPredictionPayload = {
      payload: {
        businessDescription: businessSummary,
        productsServices: descriptions,        
        irRefNumber: irRefNumber,
        searchByML: searchByML
      }};  
    return mergedClassificationPredictionPayload;
  }  

  public getTotalPercentage(formGroup: UntypedFormGroup) {
    let total = 0;
    try {
      total = this.getFormControlValueNumber(formGroup.controls.percentage1) + 
          this.getFormControlValueNumber(formGroup.controls.percentage2) +
          this.getFormControlValueNumber(formGroup.controls.percentage3);
    }
    catch(ex) {
      console.log('getTotalPercentage ex=' + ex);
    }
    return total;
  }

  public totalPercentageIsValid(formGroup: UntypedFormGroup) {
    const total = this.getTotalPercentage(formGroup);
    return total > 100? false: true;
  }

  public getSaveClassificationPayload(formGroup: UntypedFormGroup) : ClassificationSerachInfo {    
    const selectedClassId = formGroup.controls.classificationUnit.value;  
    const selectedClass = this.getSelectedClassification(selectedClassId, this.classificationUnits);
    const selectedClassName = selectedClass.name;
    const selectedClassCode = selectedClass.classificationUnitCode;
    const classificationUnits = this.getClassificationPredictionPayload(selectedClassId, this.classificationUnits);    
    const payload = {   
      classificationId: selectedClassId,
      classificationUnitCode: selectedClassCode,
      classificationName: selectedClassName,
      classificationUnits: classificationUnits,
      businessOperationsDesc: this.getFormControlValue(formGroup.controls.searchKey),
      description1: this.getFormControlValue(formGroup.controls.description1),
      percentage1: this.getFormControlValue(formGroup.controls.percentage1),
      description2: this.getFormControlValue(formGroup.controls.description2),
      percentage2: this.getFormControlValue(formGroup.controls.percentage2),
      description3: this.getFormControlValue(formGroup.controls.description3),
      percentage3: this.getFormControlValue(formGroup.controls.percentage3)
    };
    return payload;
  }  

  
  public addProductDetails(index: number, formGroup: UntypedFormGroup) {      
    try {       
      if (index === 2) {
        this.formFunctions.addFormFields(this.productDetails2Fields, formGroup);  
      }      

      if (index === 3) {
        this.formFunctions.addFormFields(this.productDetails3Fields, formGroup);  
      }
      
    } catch(ex) {
      console.log('[ClassificationSearchViewCoreFunctions] addProductDetails ex' + ex.message);
    }              
  }

  public removeProductDetails(index: number, formGroup: UntypedFormGroup) {      
    try {  
      this.setFormValue(formGroup, 'description' + index, '');
      this.setFormValue(formGroup, 'percentage' + index, '');  

      if (index === 2) {
        this.formFunctions.removeFormFields(this.productDetails2Fields, formGroup);  
      }      

      if (index === 3) {
        this.formFunctions.removeFormFields(this.productDetails3Fields, formGroup);  
      }
    } catch(ex) {
      console.log('[ClassificationSearchViewCoreFunctions] removeProductDetails ex' + ex.message);
    }              
  } 

  public loadButtonHoverText(formGroup: UntypedFormGroup): string {
    let continueButtonHoverText = '';
    if (formGroup.controls.searchButtonClicked.value !== 'Y') {
      continueButtonHoverText = this.clickSearchButtonToProceedErrorMessage;
    } else {
      continueButtonHoverText =
      formGroup.controls.classificationUnit &&
      formGroup.controls.classificationUnit.invalid ? ContinueBtnHoverTxt : '';
    }
    return continueButtonHoverText;
  }

  public isFormValid(formGroup: UntypedFormGroup) {
    let isValid = false;
    if (formGroup.valid && 
        this.isSearchButtonClicked(formGroup) &&
        formGroup.controls.classificationUnit &&
        formGroup.controls.classificationUnit.valid) {
      isValid = true;
    }
    return isValid;
  }

  public getSelectClassificationUnitDesc(formGroup: UntypedFormGroup, selectedClassId: any = null) {
    let display = '';
    if (this.isSearchButtonClicked(formGroup)) {    
      if (selectedClassId == null) {
        selectedClassId=  formGroup.controls.classificationUnit.value;
      }
      const selectedClass = this.getSelectedClassification(selectedClassId, this.classificationUnits);
      display = selectedClass && selectedClass.classId > 0 ? selectedClass?.name : '';
    }        
    return display;   
  }

  public isClassificationUnitRelatedToVehicle(formGroup: UntypedFormGroup, selectedClassId: any = null) {
    let isVehicleCu = false;
    if (this.isSearchButtonClicked(formGroup)) {  
      if (selectedClassId == null) {
        selectedClassId=  formGroup.controls.classificationUnit.value;
      }
      const selectedClass = this.getSelectedClassification(selectedClassId, this.classificationUnits);
      if (selectedClass && selectedClass.classificationUnitCode > 0) {
        isVehicleCu = this.commonFunctions.isVehicleClassificationUnit(this.sessionStorageService, selectedClass.classificationUnitCode);      
      }
    }  
    return isVehicleCu;   
  }


  public getClassificationUnitsData(): ClassificationUnit[] {
    return this.classificationUnits;
  }

  public setClassificationUnitsData(classificationUnits: ClassificationUnit[]) {
    this.classificationUnits = classificationUnits;
  }
  
  public addNewClassificationUnit(classification: ClassificationUnit) {
    this.classificationUnits.push(classification);
  }
  
  public isSearchButtonClicked(formGroup: UntypedFormGroup) {
    let isClicked = false;
    if (formGroup.controls.searchButtonClicked &&
        formGroup.controls.searchButtonClicked.value === 'Y') {
          isClicked = true;
    }
    return isClicked;
  }

  private setFormValue(formGroup: UntypedFormGroup, name: string, value: any) {    
    try
    {
      let control = formGroup.get(name);
      if (control) {
        control.setValue(value); 
      }      
    } catch(ex) { 
      console.log('setFormValue formGroup=' + formGroup + ' name=' + name + ' ex=' + ex.message);
    }        
  } 

  private getFormControlValue(control: any) {    
    let value = control?.value;
    if (value == null || value == undefined) {
      value = '';
    }

    value = value.trim();
    return value;
  }  

  private getFormControlValueNumber(control: any) {    
    let valueInt = 0;
    try
    {
      let value = control?.value;
      if (value !== null && value == undefined) {
        value = '';
      }

      value = value.trim();
      if (value != '') {
        valueInt = parseInt(value); 
      }
      
    } catch(ex) { 
      console.log('getFormControlValueNumber ex=' + ex.message);
    }  
    return valueInt;
  }  

  private getClassificationPredictionPayload(selectedClassId: any, mlSearchResponse: ClassificationUnit[]): any {    
   
    let cus= mlSearchResponse;
    let finalList = [];
    if (cus != null){
      cus.forEach(cu => {     
        if (this.isValidClassificationUnit(cu)) {
          finalList.push(
            {           
              classId : cu.classId,
              score: cu.score,
              classificationUnitCode: cu.classificationUnitCode,
              selected: selectedClassId == cu.classId? true: false,
              mlSearch: cu.mlSearch
            }
          ); 
        }   
             
      });
    }
    const classificationPrediction = finalList;   
    return classificationPrediction;
  }
  
  isValidClassificationUnit(cu: any) {
    let valid = false;
    try {
      if (cu != null && cu.classId != null ) {
        const classId = Number.parseInt(cu.classId);
        if (classId > 0) {
          valid = true;
        }
      }      
    } catch (ex) {                
      const errroMessage = 'ClassificationSearchViewCoreFunctions: isValidClassificationUnit ex=' + ex.message;
      console.log(errroMessage);
    }
    return valid;
  }

  private getSelectedClassification(selectedClassId: any, mlSearchResponse: ClassificationUnit[]): ClassificationUnit {    
    let cu = new ClassificationUnit();
    cu.classId = 0;  
    cu.name = '';  
    cu.classificationUnitCode = 0;  

    var cus= mlSearchResponse;  
    if (cus !=null) {
      const find = cus.find(c=>c.classId ==selectedClassId);
      if (find) {
        cu.classId = find.classId;  
        cu.name = find.name;  
        cu.classificationUnitCode = find.classificationUnitCode;  
      }      
    } 
    return  cu;
  } 
}
