export class CoverageQuestion {
    pop: POP;
    regPOPCoverage: RegPOPCoverage;
}

export class POP {
    popSettings: POPSettings;
    popPayment: POPPayment;
}

export class RegPOPCoverage {
    popCoverageStartDate: string;
    popCoverageEndDate: string;
    popCoverageMonthlyAmount: string;
    applicantIsAuthorizedRepresentativeIndicator: string;
    agreePOPTermsAndConditionsIndicator: string;
    popRequiredIndicator: string;
    popAcceptedIndicator: string;
    acceptedCU: string;
    mustRedoIndIndicator: string;
}

export class POPPayment {
    paymentAmount: string;
    paymentTypeId: string;
    item: {};
  }

export class POPSettings {
    popRequiredIndicator: string;
    spouseRequiredIndicator: string;
    popInvoiceFrequencyTypeCode: string;
    spousePopRefusedIndicator: string;

}

export class Payroll {
    payrollEffectiveDate: string;
    payrollEndDate: string;
    estimatedPayrollAmount: string;
    payrollTypeCode: string;
    payrollSubTypeCode: string;
    quarter: string;
    payrollSourceIndicator: string;
    payrollTypeCD: string;
}

export class PartnerPOPCoverage {
    participationId: string;
    name: string;
    regPOPCoverage: RegPOPCoverage;
}

export class PartnerCoverageQuestion {
    pop: POP;
    partners: PartnerPOPCoverage[];
}
