import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers/app.state';

// Selector functions
export const getAppState = createFeatureSelector<AppState>('appstate');

export const getInternetRegistration = createSelector(
  getAppState,
  state => state.internetRegistration
);

export const getClassification = createSelector(
  getAppState,
  state => state.internetRegistration.classification
);

export const getSelectedClassificationUnit = createSelector(
  getAppState,
  state => state.selectedClassificationUnitInfo
);

export const getClassificationPredictionInfo = createSelector(
  getAppState,
  state => state.classificationPredictionInfo
);

export const getEligibility = createSelector(
  getAppState,
  state => state.internetRegistration.eligibility
);

export const getPayroll = createSelector(
  getAppState,
  state => state.internetRegistration.payroll
);

export const getRegistrationSession = createSelector(
  getAppState,
  state => state.internetRegistration.registrationSession
);

export const getLoaders = createSelector(
  getAppState,
  state => state.loaders
);

export const getIsWorkerPayrollRequired = createSelector(
  getAppState,
  state => state.isWorkerPayrollRequired
);

export const getUserChoice = createSelector(
  getAppState,
  state => state.internetRegistration.userChoice
);

export const getUserSearchKeyWord = createSelector(
  getAppState,
  state => state.userSearchKeyWord
);

export const getPOPEstimatedMonthlyPremium = createSelector(
  getAppState,
  state => state.popEstimatedMonthlyPremium
);

export const getCurrentDateTime = createSelector(
  getAppState,
  state => state.currentDateTime
);

export const getLastSavedDateTimeLocal = createSelector(
  getAppState,
  state => state.lastSavedDateTimeLocal
);
