<div class="modal-header">
    <h2 class="modal-title p-0" id="modal-basic-title">{{this.sessionTimeoutTitle}}</h2>
    <button type="button" class="close" aria-label="Close" (click)="this.logEvent('close'); activeModal.close(true)">
        <i tabindex="0" class="fa fa-times"></i>
      </button>
    <!-- <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button> -->
</div>
<div class="modal-body pb-0">
    <form>
        <div class="form-group m-0">
            <div class="row" *ngIf="this.timeoutDuration > 0">
                <div class= "col-12">
                    <p>
                        Your session is about to expire due to inactivity. Your application has been saved up to the previous page, and will close in
                    </p>
                    <p class="countdown">{{this.timeoutRemaining}}</p><br />
                    <p>Would you like to continue with your application? </p>
                </div>
            </div>
            <div class="row" *ngIf="this.timeoutDuration <= 0">
                <div class= "col-12">
                    <p>
                        For security reasons, your session closed due to inactivity. Your application has been saved.                       
                    </p>
                    <p>                       
                        You may complete your application any time in the next 30 days.
                    </p>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
        <button id="logout" *ngIf="this.timeoutDuration > 0"  type="button" ngbAutofocus  class="btn btn-md btn-outline-blue mr-1 mb-1"  (click)="this.logEvent('leave'); activeModal.close(false)">No, close</button>
        <button id="stay" *ngIf="this.timeoutDuration > 0" type="button" class="btn btn-md btn-blue mb-1 ml-2 wide"   (click)="this.logEvent('stay'); activeModal.close(true)">Yes, continue</button>
        <button id="exit" *ngIf="this.timeoutDuration <= 0" type="button" ngbAutofocus  class="btn btn-md btn-blue mb-1"  (click)="this.logEvent('leave'); activeModal.close(false)">Close</button>
</div>
