export class ErrorMessage {

  // Default angular validation
  static readonly charsNotAllowed = 'Please remove invalid special characters.';
  static readonly notOneLetterOrDigit = 'Please enter a full street address.';
  static readonly addressTooShort = 'Address too short. Please enter the complete address.';
  static readonly cityTooShort = 'City name is too short. Please enter complete city name.';
  static readonly citynotOneLetterOrDigit = 'Please enter complete city name.';
  static readonly nonzeroInvalid = 'Number should be greater than 0.';
  static readonly genericTooShort = 'Please enter more information.';

  static readonly nameValidation = {
    emptyMsg: (propertyName) => `Please enter a valid ${propertyName} name.`,
    emptyMsgGeneric: 'Please enter a valid name.',

    minLength: (propertyName) => `Please enter a ${propertyName} name with at least two characters.`,
    minLengthGeneric: (minLength) => `Please enter a name with at least ${minLength} characters.`,

    maxLength: (propertyName, maxLength) => `Please enter a valid ${propertyName} name that is ${maxLength} characters or less.`,
    maxLengthGeneric: (maxLength) => `Please enter a valid name that is ${maxLength} characters or less.`,

    lettersOnly: (propertyName) => `Please enter only letters for the ${propertyName} name.`,
    nameRequired: (propertyName) => `Please enter a valid ${propertyName} name.`,
    firstNameRequired: 'Please enter a valid legal first name.',
    middleNameRequired: 'Please enter a valid legal middle name.',
    lastNameRequired: 'Please enter a valid legal last name.',
  };
  static readonly totalPercentageExceed100 = 'The total percentage cannot exceed 100%.';
  static readonly emailValidation = {
    emptyMsg: 'Please enter an email address.',
    invalidMsg: 'Please enter your email address in the format name@email.com.',
    badCharactersMsg: 'Please enter your email address in the format name@email.com.',
    minLength: 'Email address is too short.',
    maxLength: 'Email exceeds maximum allowed characters.'
  };

  static readonly phoneValidation = {
    emptyMsg: 'Please enter a phone number.',
    invalidMsg: 'Please enter a valid 10-digit phone number.',
    invalidExtension: 'Please enter an extension that is six digits or less.'
  };

  static readonly workLocationValidation = {
    emptyMsg: 'Please enter a work location address.'
  };

  static readonly dateOfBirthMsg = 'Please enter your date of birth.';
  static readonly totalPayrollAmountMsg = 'Current year entire payroll amount should be greater than zero.';

  static readonly postalCodeValidation = {
    emptyMsg: 'Please enter a postal code.',
    invalidMsg: 'Please enter a valid postal code in the format A2A 2A2.',
    cityTooShort: 'City name is too short. Please enter complete city name.',
    postalCodeCanada: 'Please enter a valid postal code in the format A2A 2A2.',
    zipCodeUnitedStates: 'Please enter a valid ZIP code in the format 12345 or 12345-6789.',
    postalCodeInternational: 'Please remove invalid special characters.',
  };

  static readonly relationshipMsg = 'Please select your relationship to the applicant.';

  static readonly addressValidation = {
    emptyMsg: 'Please enter your address.',
    invalidMsg: 'Please enter a valid street number and street name.',
    stateRequired: 'Please select a state from the list.'
  };

  static readonly addressTypeValidation = {
    emptyMsg: 'Please indicate the mailing address type'
  };

  static readonly businessLegalNameValidation = {
    emptyMsg: 'Please enter a valid business legal name.'
  };

  static readonly workingRelationValidation = {
    emptyMsg: 'Please describe the working relationship they have.'
  };

  static readonly previousRegisteredBusinessNameValidation = {
    emptyMsg: 'Please enter a previously registered business name.'
  }

  static readonly businessTradeNameValidation = {
    emptyMsg: 'Please enter your business trade name.'
  };

  static readonly businessIncorporationNumberValidation = {
    emptyMsg: 'Please enter your business incorporation number.',
    invalidMsg: 'Please enter a valid street number and street name.',
    stateRequired: 'Please select a state from the list.'
  };

  static readonly cityValidation = {
    emptyMsg: 'Please enter a city.',
    invalidMsg: 'Please enter a valid city name.'
  };

  static readonly stateEmptyMsg = 'Please select a state from the list.';
  static readonly provinceEmptyMsg = 'Please select a province from the list.';
  static readonly faxInvalidMsg = 'Please enter a valid 10-digit fax number.';
  static readonly summaryWorkInvalidMsg = 'The summary of work is too short. Please tell us a little more about the work being done.';
  static readonly worksafeAccountInvalid = 'Please enter a 6 or 9-digit WorkSafeBC account number.';
  static readonly craBusinessNumInvalid = 'Please enter a valid business number.';
  static readonly nameOrWorksafeAcctNumEmptyMsg = 'Please provide the name of the person or business to whom you are contracting.';

  static readonly workerCountValidation = {
    invalidMsg: 'The number of workers must be greater than 0. Please enter a number between 1 and 999.',
    emptyMsg: 'Please enter the number of workers.'
  };

  static readonly corporationWorkerCountValidation = {
    invalidMsg: 'The number of worker employed cannot be zero',
    emptyMsg: 'Please indicate how many workers the business employs'
  };

  static readonly activeShareholdersCountValidator = {
    invalidMsg: 'All incorporated businesses have at least one shareholder',
    emptyMsg: 'Please indicate the number of active shareholders in the business'
  };

  

  static readonly corporationWorkerCountExcludingShareholdersValidation = {
    invalidMsg: 'The number of worker employed in B.C. cannot be zero',
    emptyMsg: 'Please indicate how many workers the business employs in B.C.'
  };

  static readonly partnerCountValidation = {
    emptyMsg: 'Please enter the number of partners in the partnership.',
    invalidMsg: 'The number of partners in the partnership must be at least two.'
  };

  static readonly shareholderCountValidation = {
    emptyMsg: 'Please enter the number of active shareholders in the firm.',
    invalidMsg: 'There should be at least one active shareholder in the firm. '
  };

  // These are fall back error messages
  static readonly dateValidator: DateValidatorMessages = {
    emptyMsg: 'Please select the start date.',
    minDate: 'Please select the start date.',
    maxDate: 'Please enter a date before ',
  };

  static readonly workerStartDtValidator: DateValidatorMessages = {
    emptyMsg: 'Please select the start date of the first worker.',
    minDate: 'Please select the start date of the first worker.',
    maxDate: 'Please enter a date before ',
  };

  static readonly workerEndDtValidator = {
    emptyMsg: '',
    minDate: 'Work end date needs to be greater than work start date.',
    maxDate: 'Please leave end date empty if the work ends after ',
  };

  static readonly incorporatedWorkerDtValidator: DateValidatorMessages = {
    emptyMsg: 'Please select the start date of the first worker.',
    minDate: 'The start date of the first worker cannot be earlier than the start date of the firm\'s operations',
    maxDate: 'Please enter a date before ',
  };

  static readonly incorporatedDtValidator: DateValidatorMessages = {
    emptyMsg: 'Please select the start date of the firm\'s operations.',
    minDate: 'Please select the start date of the firm\'s operations.',
    maxDate: 'Please enter a date before ',
  };

  static readonly startDateFirstWorkderHiredDtValidator: DateValidatorMessages = {
    emptyMsg: 'Please enter the start date of the first worker',
    minDate: 'The start date of the first worker cannot be earlier than the start date of the business operations',
    maxDate: 'Please enter a date before ',
  };

  
  static readonly incorporationDtValidator: DateValidatorMessages = {
    emptyMsg: "Please enter the business's incorporation date.",
    minDate: 'min date is imp',
    maxDate: 'max date is imp',
  };

  static readonly businessBeingOperatingDtValidator: DateValidatorMessages = {
    emptyMsg: "Please indicate when the business began operating",
    minDate: 'The business began operating date cannot be earlier than the business’s incorporation date',
    maxDate: '',
  };

  static readonly hiredFirstWorkerStartDtValidator: DateValidatorMessages = {
    emptyMsg: "Please indicate when the business began operating in B.C.",
    minDate: '',
    maxDate: '',
  };

  static readonly spouseCoverageStartDtValidator = {
    emptyMsg: 'Please indicate when you would like the voluntary spousal coverage to begin.',
    minDate: 'Please enter the coverage start date of a spouse after current date.',
    maxDate: 'Please enter a date before ',
  };

  static readonly nameOrAcctNumValidator = {
    emptyMsg: 'Please provide the name of the person or business to whom you are contracting.',
    invalidMsg: 'Please enter at least two characters for the name or account number.'
  };

  // Business details Invalid Message
  static readonly firmTypeEmptyMsg = 'Please select the type of business from the list that best describes the firm';

  // Business Address Invalid Messages
  static readonly invalidWebsiteAddress = 'Please provide a valid website address in the format www.business.com.';
  static readonly requiredRelationshipMsg = (name: string) => `Please select your relationship to the ${name}.`;
  static readonly requiredAddrTypeMsg = 'Please select address type';
  static readonly legalNameEmptyMsg = 'Please enter a valid legal name of the firm.';

  // Business Operations Invalid Messages
  static readonly accountNumbersInvalidMsg = 'Please enter the full name or account number. The minimum length is four characters.';
  static readonly financialInterestDescriptionInvalidMsg = (name: string) => `The description provided is too short. Please tell us a little more about the financial interest of the ${name}.`;
  static readonly summaryOfOperationsInvalidMsg = (name: string) => `Please tell us a little more about the ${name}\'s current business operations.`;
  static readonly typeOfVehicleBasedServiceInvalidMsg = (name: string) => `Please tell us more about the type of vehicle-based services the ${name} provides.`;
  static readonly leasedFromInvalidMsg = 'Please provide the full name of the lessor.';
  static readonly yearMakeVehicleRegisteredInvalidMsg = 'Please provide at least the full year or make of the vehicles.';
  static readonly yearMakeEquipmentsInvalidMsg = 'Please provide at least the full year,  make, or model of the revenue-producing equipment.';
  static readonly primaryMaterialsInvalidMsg = (name: string) => `Please tell us more about the major materials the ${name} supplies.`;

  // Classification Invalid Messages
  static readonly classificationInvalidMsg = 'Please enter a minimum of four characters.';

  // Personal Optional Protection Invalid Messages
  static readonly popStartDateEmptyMsg = 'Please select the date when you would like the Personal Optional Protection coverage to begin.';
  static readonly popMonthlyCoverageAmountInvalidMsg = (min: string, max: string) => `Please enter your desired monthly coverage. The amount can be between ${min} and ${max}.`;

  // Payroll Invalid Messages
  static readonly estimatedPayrollAmountInvalidMsg = 'Please enter the estimated payroll for the year. The amount must be greater than 0.';

  //  shareholder business details
  static readonly shareholderWorkSafeBCAccountDescInvalidMsg = `Please tell us a little more about the the name(s) and/or WorkSafeBC account number(s) of these businesses.`;
  static readonly shareholderWorkingRelationshipDescInvalidMsg = `Please tell us a little more about the working relationship they have.`;

  static readonly classificationSearchModelValidation = {
    emptyMsg: 'Please indicate the make and model.'
  };

  static readonly classificationSearchYearValidation = {
    emptyMsg: 'Please indicate the year',
    validYearMsg: 'Please enter a valid year'
  };

  static readonly classificationSearchLeaseVehicle = {
    emptyMsg: 'Please describe the business lease vehicle.',
    validMsg: 'Please enter at least 4 characters to describe the business vehicle.'
  };

}

export interface DateValidatorMessages {
  emptyMsg: string;
  minDate: string;
  maxDate: string;
}
