export const ExternalLinks = {
  payrollCoverageCosts: 'https://www.worksafebc.com/en/insurance/know-coverage-costs'
};

// ProfileType == InternetRegistrationAccountType
// order by number in InternetRegistrationAccountType
// InternetRegistrationAccountType other includes Proprietor, Partner and Incorporated
// [Obsolete("Use AccountType")]
export enum ProfileType {
  homeOwner = 'homeOwner',  // =0
  retailer = 'retailer', // =1
  restaurant = 'restaurant', // =2
  other = 'other' // =3
}

export enum FirmType {
  proprietor = 'pr', // Self-Employed Coverage:  pr - Proprietor
  partner = 'pa', // Self-Employed Coverage: pa – Partner
  limitedCompany = 'lc', // Employer Coverage: lc - Limited/Inc company
  association = 'a', // Employer Coverage:a – Association
  band = 'bn', // Employer Coverage:bn -Band (First Nations)
  church = 'c', // Employer Coverage:c – Church
  cooperative = 'coo', // Employer Coverage:coo – Co-operative
  society = 'soc', // Employer Coverage:soc – Society
  union = 'u' // Employer Coverage:u - Union
}

export enum PayrollTypeCD {
  worker = 'W',
  spouse = 'S'
}

export const BusinessLegalEntityLimitedCompany = 'lc';

export enum LegalEntityCode {
  business = 'busn',
  personal = 'prsn'
}

export enum JurisdictionCode {
  federal = 'FED',
  provincial = 'PRV',
  foreign = 'FOR'
}

export enum EASConstants {
  FuturePayPeriod = 'ireg_futpyrlalw',
  POPMaxAnnualPayment = 'qtr_pop_min_amt',
  POPMinAmount = 'pop_min',
  POPMaxAmount = 'pop_mid',
  BNFirstDigit = 'bn9_1st_dig_val'
}

export enum EnvironmentSettings {
  MLToggleIndicator = 'MLKEY',
  OrgBookToggleIndicator = 'ORGBOOLKEY',
  Vehicles = 'VEHICLECUS'
}

export const JurisdictionDropDownLst = [
  { code: '', display: ' -- Select -- ', value: '', display2: ' -- Select -- ' },  
  { code: 'BC', display: 'British Columbia', value: 'britishcolumbia', display2: 'British Columbia' },
  { code: 'AB', display: 'Alberta', value: 'alberta', display2: 'Alberta', code2: 'A' },    
  { code: 'SK', display: 'Saskatchewan', value: 'saskatchewan', display2: 'Saskatchewan' },  
  { code: 'MB', display: 'Manitoba', value: 'manitoba', display2: 'Manitoba' },
  { code: 'ON', display: 'Ontario', value: 'ontario', display2: 'Ontario' },
  { code: 'QC', display: 'Quebec', value: 'quebec', display2: 'Quebec' },
  { code: 'NB', display: 'New Brunswick', value: 'newbrunswick', display2: 'New Brunswick' },
  { code: 'PE', display: 'Prince Edward Island', value: 'princeedwardisland', display2: 'Prince Edward Island' },
  { code: 'NS', display: 'Nova Scotia', value: 'novascotia', display2: 'Nova Scotia' },
  { code: 'NL', display: 'Newfoundland and Labrador', value: 'newfoundlandandlabrador', display2: 'Newfoundland and Labrador' },
  { code: 'YT', display: 'Yukon', value: 'yukon', display2: 'Yukon' },
  { code: 'NT', display: 'Northwest Territories', value: 'northwestterritories', display2: 'Northwest Territories' },
  { code: 'NU', display: 'Nunavut', value: 'nunavut', display2: 'Nunavut' },
  { code: 'FED', display: 'Federal', value: 'federal', display2: 'its home jurisdiction' },
  { code: 'FOR', display: 'International', value: 'international', display2: 'its home jurisdiction' }
];


/*
Keep same as [IREGN_LOC_TYPE] to support IRV, IRU and EAS
LOCT_CD	LOCT_NM
ab	Accountant/Bookkeeper
al	Alternate Location
ho	Head Office
r	Residence
*/
export const AddressTypesDropDownLst =   [
    { code: '', display: ' -- Select -- ', value: '' },
    { code: 'ab', display: 'Accountant or bookkeeper', value: 'ab' },
    { code: 'ho', display: 'Head Office', value: 'ho' },
    { code: 'r', display: 'Residence', value: 'r'},
    { code: 'al', display: 'Other', value: 'al' }
]; 

export const AddressTypes = [
  { value: 'ab', label: 'Accountant or bookkeeper\'s office' },
  { value: 'al', label: 'Alternate location' },
  { value: 'ho', label: 'Head office' },
  { value: 'r', label: 'Residence' },
];

export const PhysicalAddressTypesDropDownLst = [
  { code: '', display: ' -- Select -- ', value: 'al' },
  { code: 'al', display: 'Alternate location', value: 'al' },
  { code: 'ho', display: 'Head Office', value: 'ho' },
  { code: 'r', display: 'Residence', value: 'o' }
];

export const PhysicalAddressTypes = [
  { value: 'al', label: 'Alternate location' },
  { value: 'ho', label: 'Head office' },
  { value: 'r', label: 'Residence' }
];

export class CommonConstants {
  static EquipmentDropdownValues = [
    { display: '-- Select --', code: '' }, { display: '1', code: 1 }, { display: '2', code: 2 },
    { display: '3', code: 3 }, { display: '4', code: 4 }, { display: '5', code: 5 }, { display: '6', code: 6 },
    { display: '7', code: 7 }, { display: '8', code: 8 }, { display: '9', code: 9 }, { display: '10', code: 10 }];
}

export class QuarterConstants {
  static Quarters = [
    { quarterId: 'Q1', quarterName: 'January 1 - March 31', startMonth: 1, startDay: 1, endMonth: 3, endDay: 31 },
    { quarterId: 'Q2', quarterName: 'April 1 - June 30', startMonth: 4, startDay: 1, endMonth: 6, endDay: 30 },
    { quarterId: 'Q3', quarterName: 'July 1 - September 30', startMonth: 7, startDay: 1, endMonth: 9, endDay: 30 },
    { quarterId: 'Q4', quarterName: 'October 1 - December 31', startMonth: 10, startDay: 1, endMonth: 12, endDay: 31 }
  ];
}

export const EnglishNumbers = ['', 'one', 'two', 'three', 'four', 'five'];

export const CanadaPostValidated = 'VLDTD';
export const CanadaPostInvalidated = 'INVLD';

export const ContinueBtnHoverTxt = 'You must complete all required fields to proceed. Fields are required unless marked as optional.';

// CanadaPost service response codes
export enum CanadaPostResponse {
  success = 'S',
  error = 'E',
  fullConfidence = '100',
}

export enum RegistrationPaths {
  Partnership = 'partnership',
  SoleProprietorship = 'proprietor',
  HomeOwner = 'homeowner',
  Incorporated = 'incorporated',
  Corporation = 'corporation',
  Payroll = 'payroll',
  SoleProprietor = 'soleproprietor'
}

export enum CountryEnum {
  Canada = 'CA',
  US = 'US'
}

export const WorkStartTooltip = 'If the start date was more than 10 years ago, please enter the earliest date you are able to enter here. There is an area at the end of the application where you can enter additional information, please enter the actual start date there.';
export const AffiliationTextMsg ='There is an affiliation if:\n\n - One firm controls another firm, or both firms are controlled by the same person or group of persons \n\n - The firms are controlled by immediate and/or extended family members or those in equivalent relationships';
export const OwnerOrShareholderTypeCode='sh';

export enum WorkDays {
  workDays_lessThan9 = 'lessThan9',
  workDays_10To14 = '10To14',
  workDays_15More = '15More'
}


export enum Trips {
  trips_lessThan3 = 'lessThan3',
  trips_3More = '3More',
  trips_notSure = 'notSure'
}