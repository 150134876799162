import {
  ActionReducerMap
} from '@ngrx/store';
import * as fromRoot from 'src/app/store';
import * as Actions from './actions/contact-info.actions';
import * as Selectors from './selectors/contact-info.selectors';
import * as State from './reducers/contact-info.state';
import * as Reducer from './reducers/contact-info.reducer';
import * as Effects from './effects/contact-info.effects';
import * as Model from './models';

export const reducers: ActionReducerMap<State.State> = {
  appstate: fromRoot.Reducer.reducer,
  contactinfostate: Reducer.reducer
};

// export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];


export {
  Actions,
  Selectors,
  State,
  Effects,
  Reducer,
  Model
};
