export class AppSessionState {
    isTimeout: boolean;
    constructor(isTimeout: boolean) {
        this.isTimeout = isTimeout;
    }
}

export class SurveyPopupState {
    isDisplayed: boolean;
    constructor(isDisplayed: boolean) {
        this.isDisplayed = isDisplayed;
    }
}

export class RefreshPagePopupState {
    skipRefresh: boolean;
    constructor(skipRefresh: boolean) {
        this.skipRefresh = skipRefresh;
    }
}

export class NacisAutoRedirectState {
    isNaicsAutoRedirect: boolean;
    constructor(isNaicsAutoRedirect: boolean) {
        this.isNaicsAutoRedirect = isNaicsAutoRedirect;
    }
}


export class AutoSavePopupState {
    isDisplayed: boolean;
    constructor(isDisplayed: boolean) {
        this.isDisplayed = isDisplayed;
    }
}