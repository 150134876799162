import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppInsightsService } from 'src/app/core/appinsights.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private http: HttpClient, private appInsightService: AppInsightsService,) { }
  postApp(requestUrl: string, payload: any): Observable<any> {
    console.log('[AppService] Current Payload: ' + JSON.stringify(payload));
    console.log('[AppService] Request Url: ' + requestUrl);
    this.appInsightService.startTraceServiceEvent(requestUrl);
    return this.http.post<any>(requestUrl, JSON.stringify(payload), { headers:  new HttpHeaders() })
      .pipe(
        tap(data => {
          this.appInsightService.endTraceServiceEvent(requestUrl);
          // console.log("[AppService] response: " + JSON.stringify(data));
        }),
        catchError(this.handleError)
      );
  }

  getApp(requestUrl: string): Observable<any> {
    console.log('[AppService] Request Url: ' + requestUrl);
    this.appInsightService.startTraceServiceEvent(requestUrl);
    return this.http.get<any>(requestUrl, { headers:  new HttpHeaders() })
      .pipe(
        tap(data => {
          this.appInsightService.endTraceServiceEvent(requestUrl);
          // console.log("[AppService] response: " + JSON.stringify(data));
        }),
        catchError(this.handleError)
      );
  }

  private findByActionName(name: string, actions: string[]): string {
    return actions.find(action => action.toLocaleLowerCase() === name.toLocaleLowerCase());
  }
  private handleError(err) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
    }
    console.error(JSON.stringify(err));
    return throwError(errorMessage);
  }
}
