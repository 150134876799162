import { NgModule, InjectionToken } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { SelectiveStrategy } from './selective-strategy.service';
import { PageExitGuard } from './shared/page-exit/page-exit.guard';
import { PageEnterGuard } from './shared/page-enter/page-enter.guard';
import { StartPageViewLogGuard } from './shared/start-page-view-log/start-page-view-log.guard';
import { environment } from 'src/environments/environment';
import { IdamAuthenticationGuard } from './shared/idam-auth-guard/idam-auth-guard';
import { LandingPageGuard } from './shared/landing-page-guard/landing-page-guard';
import { OidcAuthenticationGuard } from './services/authServices/oidc/oidc-auth-guard';
import { ExternalRedirectComponent } from './external-redirect.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
const deactivateGuard = new InjectionToken('deactivateGuard');

// replace to msalConfig.addGuards when need to debug login related tasks
const AuthGuard = environment.name === 'default' ? environment.addGuards : environment.ciamEnabled ? IdamAuthenticationGuard : OidcAuthenticationGuard;
console.log('app-routing.module AuthGuard environment.name=' + environment.name + ' environment.ciamEnabled=' + environment.ciamEnabled);

const routes: Routes = [
  {
    path: 'registration-dashboard',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard, StartPageViewLogGuard],
    canDeactivate: [PageExitGuard],
    data: { preload: true },
    loadChildren: () =>
    import('./modules/registration-dashboard/registration-dashboard.module').then(m => m.RegistrationDashboardModule)
  },
  {
    path: 'landingpage',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard, StartPageViewLogGuard, LandingPageGuard],
    canDeactivate: [PageExitGuard],
    data: { preload: true },
    loadChildren: () =>
      import('./modules/landing-page/landing-page.module').then(m => m.LandingPageModule)
  },
  {
    path: 'coverage-notrequired',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard, StartPageViewLogGuard],
    canDeactivate: [PageExitGuard],
    data: { preload: true },
    loadChildren: () =>
      import('./modules/coverage-notrequired/coverage-notrequired.module').then(m => m.CoverageNotrequiredModule)
  },
  {
    path: 'early-coverage',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard, StartPageViewLogGuard],
    canDeactivate: [PageExitGuard],
    data: { preload: true },
    loadChildren: () =>
      import('./modules/early-coverage/early-coverage.module').then(m => m.EarlyCoverageModule)
  },
  {
    path: 'homeowner',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard, PageEnterGuard],
    data: { preload: false },
    loadChildren: () =>
      import('./modules/home-owner/home-owner.module').then(m => m.HomeOwnerModule)
  },
  {
    path: 'corporation',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard, PageEnterGuard],
    data: { preload: false },
    loadChildren: () =>
      import('./modules/corporation/corporation.module').then(m => m.CorporationModule)
  },
  {
    path: 'retailer',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard, PageEnterGuard],
    data: { preload: false },
    loadChildren: () =>
      import('./modules/retailer/retailer.module').then(m => m.RetailerModule)
  },
  {
    path: 'restaurant',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard, PageEnterGuard],
    data: { preload: false },
    loadChildren: () =>
      import('./modules/restaurant/restaurant.module').then(m => m.RestaurantModule)
  },
  {
    path: 'selfemployed',
    component: ContentLayoutComponent,
    data: { preload: false },
    canActivate: [AuthGuard, StartPageViewLogGuard],
    loadChildren: () =>
      import('./modules/self-employed/self-employed.module').then(m => m.SelfEmployedModule)
  },
  {
    path: 'error-page',
    component: ContentLayoutComponent,
    canDeactivate: [PageExitGuard],
    data: { preload: true },
    loadChildren: () => import('./modules/error-page/error-page.module').then(m => m.ErrorPageModule)
  },
  {
    path: 'externalRedirect',
    canActivate: [externalUrlProvider],    
    component: ExternalRedirectComponent,
  },
  {
    path: 'signin-callback',
    data: { preload: true },
    loadChildren: () => import('./modules/signin-callback/signin-callback.module').then(m => m.SignInCallbackModule)
  },
  { path: '', redirectTo: 'registration-dashboard', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    enableTracing: false,
    preloadingStrategy: SelectiveStrategy,
    scrollPositionRestoration: 'enabled',
    useHash: false
})
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get('externalUrl');
        console.log('[External Url] ' + externalUrl);
        window.open(externalUrl, '_self');
      },
    },
    {
      provide: deactivateGuard,
      useValue: () => {
        console.log('Guard function is called!');
        return false;
      }
    }
  ]
})
export class AppRoutingModule { }
