import { Inject, Injectable, InjectionToken } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AppState } from '../store/reducers/app.state';
// key that is used to access the data in local storage

const STORAGE_KEY = 'AppState';
export const LOCAL_SERVICE_STORAGE = LOCAL_STORAGE;
@Injectable()
export class LocalStorageService {
     constructor(@Inject(LOCAL_SERVICE_STORAGE) private storage: StorageService) { }

     public storeOnLocalStorage(appState: AppState): void {
        // insert appstate to local storage
        this.storage.set(STORAGE_KEY, appState);
        // console.log('[Local Storage Service] saved AppState: ' + JSON.stringify(this.storage.get(STORAGE_KEY)));
    }

    public getAppStateFromLocalStorage(): AppState {
        const appstate = this.storage.get(STORAGE_KEY);
        return appstate ? appstate : null;
    }

    public cleanAppStateFromLocalStorage(): void {
        this.storage.remove(STORAGE_KEY);
        // console.log('[Local Storage Service] removed AppState');
    }
}
