import { ProfileType } from 'src/app/shared/common/common-constants';

export interface AccountRegistrationDto {
    email?: string;
    accountType?: ProfileType;
    internetRegistrationId?: string;
    internetRegistrationMasterId?: string;
    currentInternetRegistrationActionId?: string;
    registrationSession?: RegistrationSessionDto;
    eligibility?: EligibilityDto;
    participation: ParticipantDto[];
    classification?: ClassificationDto;
    payroll?: PayrollPeriodDto[];
    pop?: POPDto;
    userChoice?: UserChoiceDto;
    errors?: ErrorDto[];
    isMailingAddressValidationEnabled?: boolean;
    firmType?: string;
    registrationReviewReason?: RegistrationReviewReasonDto[];
    registrationReviewNote?: RegistrationReviewNoteDto[];
    regSharedDataPool?: RegSharedDataPoolDto;
    regSharedDataPoolTransaction?: RegSharedDataPoolTransactionDto;
}

export interface EligibilityDto {
    eligibilityResponseSet1?: EligibilityResponseSet1Dto;
    eligibilityResponseSet3?: EligibilityResponseSet3Dto;
    eligibilityResponseSet4?: EligibilityResponseSet4Dto;
    eligibilityResponseSet5?: EligibilityResponseSet5Dto;
    eligibilityResponseSet6?: EligibilityResponseSet6Dto;
    eligibilityQuestion7?: EligibilityQuestion7Dto;
    eligibilityResponseAffiliation?: EligibilityResponseAffiliationDto;
    eligibilityResponseWorkerSubcontractor?: EligibilityResponseWorkerSubcontractorDto;
    eligibilityResponseBusinessOperation?: EligibilityResponseBusinessOperationDto;
    eligibilityResponseEquipmentMaterial?: EligibilityResponseEquipmentMaterialDto;
}

export interface EligibilityQuestion7Dto {
    applicantTypeCode?: string;
    contractsIndicator?: string;
    numberOfPiecesOfEquipmentCode?: string;
    equipmentMakeAndModelText?: string;
    labourAndMaterialsFixedPriceIndicator?: string;
    materialsSuppliedAndValueText?: string;
    generalPublicIndicator?: string;
    employingNannyIndicator?: string;
    farmOrMedicalOrDentalIndicator?: string;
}

export interface EligibilityResponseAffiliationDto {
    firmAffiliationIndicator?: string;
    firmAffiliationText?: string;
    servicesProductsIndicator?: string;
    servicesProductsText?: string;
    financialInterestIndicator?: string;
    financialInterestText?: string;
}

export interface EligibilityResponseBusinessOperationDto {
    truckingCourierServiceIndicator?: string;
    truckingCourierServiceText?: string;
    truckingOtherProvinceIndicator?: string;
    businessVehicleDescriptionText?: string;
    vehicleOwnershipDescriptionText?: string;
    vehicleLeasingFirmName?: string;
    productsServicesDescriptionText?: string;
    topCompetitorsName?: string;
    workLocationText?: string;
    otherProvinceIndicator?: string;
    servicesPaidDescriptionText?: string;
    advertiseServicesIndicator?: string;
    advertisingMethodsDescriptionText?: string;
}

export interface EligibilityResponseSet1Dto {
    accountRevivalIndicator?: string;
    businessNumber?: string;
    currentWorkerIndicator?: string;
    workerStartDate?: string;
    workerEndDate?: string;
    opStartDate?: string;
    registeredWorkerCount?: number;
    questionTypeCount?: string;
    publiclyTradedIndicator?: string;
    movieTVProductionIndicator?: string;
    businessNumberActionCode?: string;
    businessNumberReasonCode?: string;
    businessNumberReasonTypeCode?: string;
    defaultWorkerStartDateIndicator?: string;
    defaultOPStartDateIndicator?: string;
    workerFirstName?: string;
    workerMiddleName?: string;
    workerLastName?: string;
    workerisaSpouseIndicator?: string;
    workerSpouseDutyText?: string;
    partnerCount?: string;
    activeShareHolderCount?: string;
    userComment?: string;
}

export interface EligibilityResponseEquipmentMaterialDto {
    revenueProducingEquipmentCount?: number;
    revenueProducingEquipmentText?: string;
    equipmentLeaseIndicator?: string;
    equipmentLeasingCompanyName?: string;
    majorMaterialIndicator?: string;
    majorMaterialText?: string;
    fixedContractPriceIndicator?: string;
}

export interface EligibilityResponseSet3Dto {
    contractsIndicator?: string;
    equipmentIndicator?: string;
    makeOrModel?: string;
    businessPremiseIndicator?: string;
    labourAndMaterialIndicator?: string;
}

export interface EligibilityResponseSet4Dto {
    contractsText?: string;
    equipmentText?: string;
    makeModelText?: string;
    materialText?: string;
    materialDescriptionText?: string;
    labourContractorIndicator?: string;
}

export interface EligibilityResponseSet5Dto {
    previousAccountNumber?: string;
    previousAccountName?: string;
}

export interface EligibilityResponseSet6Dto {
    providesServices?: string;
    provideMultipleContractsIndicator?: string;
    majorEquipmentIndicator?: string;
    equipmentMakeAndModelText?: string;
    businessPremiseIndicator?: string;
    supplyLabourAndMaterials_Fixed_PriceIndicator?: string;
    multipleOperationalActivitiesIndicator?: string;
    multipleOperationalActivitiesText?: string;
    estimatedAnnualPayrollAmount?: number;
    organizationActitives?: string;
}

export interface EligibilityResponseWorkerSubcontractorDto {
    subcontractorHiringIndicator?: string;
    subcontractorHiringText?: string;
    subcontractorFirmIndicator?: string;
    subcontractorFirmText?: string;
    subcontractorGeneralPublicIndicator?: string;
    multipleContractsIndicator?: string;
    workerStatusDescriptionText?: string;
    employmentTypeText?: string;
    hoursEmploymentText?: string;
}

export interface ParticipantDto {
    participationId?: string;
    regParticipation?: RegParticipationDto;
    regLegalEntity?: RegLegalEntityDto;
    regPOPCoverage?: RegPOPCoverageDto;
    item?: any;
    registrant?: string;
    legalRepresentative?: string;
    bookKeeper?: string;
    shareHolder?: string;
    spouse?: string;
}

export interface RegParticipationDto {
    participationTypeCode?: string;
    primaryRegistrantIndicator?: string;
    easCreateIndicator?: string;
    uploadSuccessfulIndicator?: string;
}

export interface RegPOPCoverageDto {
    popCoverageStartDate?: string;
    popCoverageEndDate?: string;
    popCoverageMonthlyAmount?: string;
    applicantIsAuthorizedRepresentativeIndicator?: string;
    agreePOPTermsAndConditionsIndicator?: string;
    popRequiredIndicator?: string;
    popAcceptedIndicator?: string;
    acceptedCU?: string;
    mustRedoIndIndicator?: string;
}

export interface RegLegalEntityDto {
    internetLegalEntityId?: string;
    internetLegalEntity?: InternetLegalEntityDto;
    legalEntityAddress?: LegalEntityAddressDto[];
    item: any;
}

export interface InternetLegalEntityDto {
    legalEntityTypeCode?: string;
    easLegalEntityIdentifier?: string;
    duplicateIndicator?: string;
}

export interface LegalEntityAddressDto {
    sequenceNumber?: string;
    addressTypeCode?: string;
    streetLine1?: string;
    streetLine2?: string;
    city?: string;
    provinceCode?: string;
    country?: string;
    postalCode?: string;
    phoneAreaCode?: string;
    phoneNumber?: string;
    phoneExtension?: string;
    faxAreaCode?: string;
    faxNumber?: string;
    email?: string;
    locationTypeCode?: string;
    webPage?: string;
    canadaPostValidateCode?: string;
    isSamePhysicalAddress?: boolean;
}

export interface BusinessLegalEntityDto {
    firmTypeCode?: string;
    tradeName?: string;
    legalName?: string;
    incorporationNumber?: string;
    incorporationDate?: string;
    incorporationJurisdictionCode?: string;
    incorporationProvinceCode?: string;
    contactRelationshipTypeCode?: string;
    contactName?: string;
    contactPhoneNumber?: string;
    contactDescription1?: string;
    contactDescription2?: string;
    contactDescription3?: string;
    contactDescription4?: string;
    businessNumberParticipant?: string;
    wCBAccountNumberParticipant?: string;
    wCBReferenceNumberParticipant?: string;
}

export interface PersonalLegalEntityDto {
    personalTitleTypeCode?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    birthDate?: string;
}

export interface RegistrationSessionDto {
    lastPageVisited?: string;
    versionNumber?: string;
    sourceCode?: string;
    updateNumber?: string;
    iRegReferenceNumber?: string;
    accountEffectiveDate?: string;
    versionEffectiveDate?: string;
    iregMstrBusinessAddedDate?: string;
}

export interface ClassificationDto {
    classificationSummary?: ClassificationSummaryDto;
    classificationSession?: ClassificationSessionDto[];
}

export interface ClassificationSummaryDto {
    finalClassificationUnit?: string;
    finalClassificationUnitDerivedName?: string;
    finalNaicsCode?: string;
    finalNaicsName?: string;
    finalStandardIndustryCode?: string;
    finalSessionNumber?: string;
    policyClassificationId?: string;
}

export interface ClassificationSessionDto {
    sessionNumber?: string;
    userSupplyKeywordHistory?: UserSupplyKeywordDto[];
    classificationAssignment?: ClassificationAssignmentDto[];
    classificationSessionHistory?: ClassificationSessionHistoryDto;
    exceptionQuestionsHistory?: ExceptionQuestionHistoryDto[];
    classificationKeywordHistory?: ClassificationKeywordDto[];
    classificationSubsectorHistory?: ClassificationSubsectorDto[];
}

export interface ClassificationKeywordDto {
    sequenceNumber?: string;
    typeCode?: string;
    keyword?: string;
}

export interface ClassificationAssignmentDto {
    classificationId?: string;
}

export interface ClassificationReference {
    classificationId?: string;
}

export interface UserSupplyKeywordDto {
    sequenceNumber?: string;
    classificationKeywordTypeCode?: string;
    userSuppliedKeyword?: string;
}

export interface ClassificationSessionHistoryDto {
    classificationUnit1?: string;
    classificationUnit2?: string;
    classificationUnit3?: string;
    classificationUnit4?: string;
    classificationUnit5?: string;
}

export interface ClassificationSubsectorDto {
    sequenceNumber?: string;
    subsectorIdentifier?: string;
}

export interface ExceptionQuestionHistoryDto {
    sequenceNumber?: string;
    exceptionQuestionIdentifier?: string;
    exceptionQuestionTypeCode?: string;
    exceptionQuestionResponse?: string;
}

export interface PayrollPeriodDto {
    payrollEffectiveDate?: string;
    payrollEndDate?: string;
    estimatedPayrollAmount?: number;
    payrollTypeCode?: string;
    payrollSubTypeCode?: string;
    quarter?: string;
    payrollSourceIndicator?: string;
    payrollTypeCD?: string;
}

export interface POPDto {
    popPayment?: POPPaymentDto;
    popSettings?: POPSettingsDto;
}

export interface POPPaymentDto {
    paymentAmount?: string;
    paymentTypeId?: string;
    item: any;
}

export interface PreauthorizedChequingDto {
    bankAccountName?: string;
    transitNumber?: string;
    partAccountNumber?: string;
    tagNumber?: string;
    authNumber?: string;
    branchAddress1?: string;
    branchAddress2?: string;
    branchCity?: string;
    branchProvince?: string;
    branchPSTCode?: string;
}

export interface PreauthorizedCreditCardDto {
    accountTypeId?: string;
    partAccountNumber?: string;
    tagNumber?: string;
    authNumber?: string;
    creditCardName?: string;
    expiryMonth?: string;
    expiryYear?: string;
    cardType?: string;
    tagNumberLBLF?: string;
}

export interface POPSettingsDto {
    popRequiredIndicator?: string;
    spouseRequiredIndicator?: string;
    popInvoiceFrequencyTypeCode?: string;
    spousePopRefusedIndicator?: string;
}

export interface UserChoiceDto {
    employeeType?: string;
    currentPartner?: number;
    currentPartnerType?: PartnerTypeDto;
    contactTitle?: string;
    contactNameFirst?: string;
    contactNameMiddle?: string;
    contactNameLast?: string;
    contactRelation?: string;
    contactTelephone?: string;
    contactEmail?: string;
    contactEmailConfirm?: string;
    partnerCount?: number;
    confirmedPartnerCount?: number;
    isDataCollectionOK?: boolean;
    isNoneCUApplyFlag?: boolean;
    isMultipleCUApplyFlag?: boolean;
    isNoAllExceptionQuestions?: boolean;
    isUserRequestContactInClassificationFlag?: boolean;
    isCriticalDataSet?: boolean;
    isReviewVisited?: boolean;
    terminateReason?: string;
    classConfirmResponse?: ClassConfirmTypeDto;
    popPaymentTypeSelection?: string;
    providerResponse?: string;
    isClassSearch?: boolean;
    classKeywordSearchText?: string;
    isReviewWithoutCU?: boolean;
    isCLUExistsWithNocurrentBaseRate?: boolean;
    isPastPointOfNoReturn?: boolean;
    isDataEntered?: boolean;
    isPayrollVisited?: boolean;
    isPastClassIterationDecisionPoint?: boolean;
    isOKToCompareHomeownerEligAnswers?: boolean;
    isPartnerDeleted?: boolean;
    nextPageFromPointOfNoReturn?: string;
    isSetPointOfNoReturn?: boolean;
    isPOPForcedAnnual?: boolean;
    forceExitTrigger?: string;
    isForceExitDuplicateBN?: boolean;
    isForceExitDuplicateBLE?: boolean;
    isForceExitDuplicateSIN?: boolean;
    isForceExitDuplicatePLE?: boolean;
    stickyAnsweredYesToRevivalQuestionBl?: boolean;
    stickyAnsweredYesToRevivalQuestionTxt?: string;
    stickyAnsweredYesToAffiliationBl?: boolean;
    stickyAnsweredYesToAffiliationTxt?: string;
    stickyPreviousFirmTypeTxt?: string;
    stickyHiringWorkersIndTxt?: string;
    stickyWorkerStartDateDt?: string;
    stickyDuplicateBLENameTxt?: string;
    stickyDuplicatePLENameTxt?: string;
    stickyEffectiveDate?: string;
    stickyWorkerStatus?: string;
    stickyHoursOfEmployment?: string;
    ro?: ROChoicesDto;
}

export interface ROChoicesDto {
    isIRV?: boolean;
    isCUUpdated?: boolean;
    isAcctEffDateUpdated?: boolean;
    isCUVerified?: boolean;
    isReadOnly?: boolean;
    isInvalid?: boolean;
    revisedPaymentAmount?: string;
    uploadNoteAccount?: string;
    popPaymentDeletionDisposition?: string;
    popPaymentOriginalAmount?: string;
    popPaymentDeletionUserID?: string;
    popPaymentDeletionFormattedDate?: string;
    vscStartDate?: string;
}

export interface ErrorDto {
    message?: string;
    extendedMessage?: string;
}

export interface RegSharedDataPoolDto {
    sharedDataPool?: SharedDataPoolDto;
}

export interface SharedDataPoolDto {
    sdpHasWorkers?: string;
    sdpBN9?: string;
    sdpBusType?: string;
    sdpLegalName?: string;
    sdpTradeName?: string;
    sdpPropLastName?: string;
    sdpPropGivenName?: string;
    sdpPropMiddleName?: string;
    sdpContactFirstName?: string;
    sdpContactMiddleName?: string;
    sdpContactLastName?: string;
    sdpContactTelephoneNumber?: string;
    sdpBusinessDesc?: string;
    sdpAddressSameInd?: string;
    sdpIncorpNum?: string;
    sdpIncorpJuris?: string;
    sdpIncorpProv?: string;
    sdpIncorpDate?: string;
    sdpIncorpDateSpecified?: boolean;
    sdpBusPhoneAreaCode?: string;
    sdpBusPhoneNum?: string;
    sdpBusFaxAreaCode?: string;
    sdpBusFaxNum?: string;
    sdpEmailAddress?: string;
    sdpWebsite?: string;
    pLEStreet1?: string;
    pLEStreet2?: string;
    pLECity?: string;
    pLEProv?: string;
    pLEPostCode?: string;
    pLECountry?: string;
    sdpPhysicalAddressLine1?: string;
    sdpPhysicalAddressLine2?: string;
    sdpPhysicalCity?: string;
    sdpPhysicalProvince?: string;
    sdpPhysicalPostalCode?: string;
    sdpPhysicalCountry?: string;
    sdpMailingAddressLine1?: string;
    sdpMailingAddressLine2?: string;
    sdpMailingCity?: string;
    sdpMailingProvince?: string;
    sdpMailingPostalCode?: string;
    sdpMailingCountry?: string;
}

export interface RegistrationReviewReasonDto {
    internetRegistrationReviewReasonTypeCode?: string;
    acknowledgeIndicator?: string;
    overrideReasonDescriptionText?: string;
    originalSourceData?: string;
    effectiveDatetime?: string;
    effectiveDatetimeSpecified?: boolean;
    expiryDatetime?: string;
    expiryDatetimeSpecified?: boolean;
    reasonShortName?: string;
    reasonLongName?: string;
    reasonDescription?: string;
    reasonDisplayable?: string;
    reasonOverridable?: string;
    reasonPersistent?: string;
    reasonTargetEntity?: string;
    sectionCode?: string;
    sectionShortName?: string;
    sectionLongName?: string;
    sectionDescription?: string;
    categoryCode?: string;
    categoryShortName?: string;
    categoryLongName?: string;
    categoryDescription?: string;
}

export interface RegistrationReviewNoteDto {
    internetRegistrationId?: string;
    internetRegistrationReviewNoteTypeCode?: string;
    noteText?: string;
    effectiveDatetime?: string;
    effectiveDatetimeSpecified?: boolean;
    expiryDatetime?: string;
    expiryDatetimeSpecified?: boolean;
}

export interface RegSharedDataPoolTransactionDto {
    sharedDataPoolTransaction?: SharedDataPoolTransactionDto;
}

export interface SharedDataPoolTransactionDto {
    sdpTrsnId?: string;
    shrdDataGUID?: string;
}

export enum PartnerTypeDto {
    Person = 0,
    Business
}

export enum ClassConfirmTypeDto {
    NoSelection = 0,
    Agree,
    DisAgree,
    Contact
}

export enum AccountType {
    homeOwner = 0,
    retailer = 1,
    restaurant = 2,
    other = 3
}
