import { AbstractControl, UntypedFormArray, ValidationErrors } from '@angular/forms';

import { ErrorMessage, DateValidatorMessages } from './error.message';
import { CountryEnum, EASConstants, EnglishNumbers } from '../common/common-constants';
import { SessionStorageService } from 'src/app/services/session-storage.service';


// Tests that the field is required and outputs the string passed as the err msg
export function requiredValidator(errMsg: string, minLength = 1, validMsg = '') {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.value == null || control.value.length === 0) {
      return { errMsg };
    } else if(control.value.length < minLength)
      if(validMsg)
        return { errMsg:validMsg };
      else
      return { errMsg };
    return null;
  };
}

//Year validator should accept only 4 digit number
export function yearValidator(errMsg: string, minLength = 1,validYearMsg?:string) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const date = new Date();
    if (!control || control.value == null || control.value.length === 0) {
      return { errMsg };
    }
    if(isNaN(control.value) || control.value.length < minLength){
      return { errMsg:validYearMsg };
    }
    return null;
  };
}

// Tests that the field is required and outputs the string passed as the err msg
export function requiredAndMinCharValidator(errMsg: string, minLength = 1, minCharErrMsg: string) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.value == null || control.value.length === 0) {
      return { errMsg };
    }
    if(control.value.length < minLength){
      return { errMsg : minCharErrMsg };
    }
    return null;
  };
}

// Outputs the err msg if the field has input but is shorter minLength
export function optionalValidator(errMsg: string, minLength = 4) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.value == null || control.value.length === 0 ) {
      return null;
    }
    if ( control.value.length < minLength ) {
      return { errMsg };
    }
    return null;
  };
}

// Validation rules
// 1. Names must be longer than minLength with the exception of "middle" names that are valid if they are 1 letter
// 2. Names are valid if they contain a space
// 3. Names are valid if they contain letters and numbers
// 4. The special characters . (period) ' (apostrophe) - (dash) are valid. Other special characters are not
// 5. Names must be under maxLength
export function nameValidator(isRequired = false, propertyName: string = null, minLength = 2, maxLength = 45 ) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || ( control.value == null && !isRequired ) || ( control.value != null && control.value.length === 0 && !isRequired )) {
        return null;
    }

    if ( minLength < 0 ) {
      throw new Error('Minimum length must be greater than 0');
    }

    if ( (control.value == null || control.value.length === 0 ) && isRequired ) {
      if ( propertyName === null ) {
        return { errMsg: ErrorMessage.nameValidation.emptyMsgGeneric };
      }
      return { errMsg: ErrorMessage.nameValidation.emptyMsg(propertyName) };
    }

    const allowedMaxLengthForSameCharacterForName = maxLength;
    const value = control.value;

    // Rule 1
    if (control.value.length < minLength && propertyName !== 'middle') {
      if (propertyName === null) {
        if ( minLength < EnglishNumbers.length ) {
          return { errMsg: ErrorMessage.nameValidation.minLengthGeneric(EnglishNumbers[minLength]) };
        }
        return { errMsg: ErrorMessage.nameValidation.minLengthGeneric(minLength) };
      }
      return { errMsg: ErrorMessage.nameValidation.minLength(propertyName)};
    }

    let isValid = true;
    let message = null;

    // Rule 2
    const reg2 = /([a-zA-Z0-9#&()/:'-]{1,63})( )*([a-zA-Z0-9 #&()/:'-])/;
    if ( propertyName == null ) {      
      isValid = reg2.test(value);
      message = ( minLength < EnglishNumbers.length ) ? ErrorMessage.nameValidation.minLengthGeneric(EnglishNumbers[minLength])
        : ErrorMessage.nameValidation.minLengthGeneric(minLength);      
    } else if ( propertyName !== 'middle' && minLength > 1 ) {      
      const sArray = value.split(' ');
      let finalStr = '';
      if (sArray && sArray.length >0 ) {
        sArray.forEach( s => {    
            s = s ? s : '';                                     
            finalStr = finalStr + s;
            finalStr = finalStr.trim(); 
          });
      } 
                         
      if (isValid && finalStr.length > 0) {
        isValid = reg2.test(finalStr);
      } else {
        isValid = false;
      } 
      message = ErrorMessage.nameValidation.minLength(propertyName);
      
    }

    // Rule 3 and 4
    if ( isValid ) {
      if ( propertyName == null) {
        isValid = /^[a-zA-Z0-9 #&()/:.'-]*$/.test(value);
        message = ErrorMessage.charsNotAllowed;
      } else {
        isValid = /^[a-zA-Z0-9 .'-]*$/.test(value);
        message = ErrorMessage.nameValidation.lettersOnly(propertyName);
      }
    }

    // Rule 5
    if (isValid) {
      if (value.length > allowedMaxLengthForSameCharacterForName) {
        if ( propertyName === null ) {
          return { errMsg: ErrorMessage.nameValidation.maxLengthGeneric(maxLength) };
        }
        return { errMsg: ErrorMessage.nameValidation.maxLength(propertyName, maxLength) };
      }
    }

    return isValid ? null : { errMsg: message };
  };
}

// Validate email input fields
export function emailValidator(isRequired = false) {
  return (control: AbstractControl) => {
    if (!control || ( control.value == null && !isRequired ) || ( control.value != null && control.value.length === 0 && !isRequired )) {
      return null;
    }

    const usernameCharacterRange = /^[a-zA-Z0-9&(_.-]*$/;
    const domainNameCharacterFormat = /.\../;
    const value = control.value;

    if ( ( value == null || value.length === 0 ) && isRequired ) {
      return { errMsg: ErrorMessage.emailValidation.emptyMsg };
    }

    const splitAddress = value.split('@');
    if (splitAddress.length !== 2) {
      return { errMsg: ErrorMessage.emailValidation.invalidMsg };
    }
    const username = splitAddress[0];
    const domainName = splitAddress[1];

    if (!usernameCharacterRange.test(username)) {
      return { errMsg: ErrorMessage.emailValidation.badCharactersMsg };
    } else if (username.length < 2) {
      return { errMsg: ErrorMessage.emailValidation.minLength };
    } else if (!domainNameCharacterFormat.test(domainName)) {
      return { errMsg: ErrorMessage.emailValidation.invalidMsg };
    } else if (value.length > 45) {
      return { errMsg: ErrorMessage.emailValidation.maxLength };
    }
    return null;
  };
}

export function addressValidator(isRequired = false) {
  return (control: AbstractControl) => {
    if (!control || ( control.value == null && !isRequired ) || ( control.value != null && control.value.length === 0 && !isRequired )) {
      return null;
    }

    if (control.value == null || control.value.length === 0 ) {
      return { errMsg: ErrorMessage.addressValidation.emptyMsg };
    }

    if (control.value.length < 2) {
      return { errMsg: ErrorMessage.addressValidation.invalidMsg };
    }

    let isValid = true;
    let message = null;

    isValid = /^[a-zA-Z0-9 #&()/:'-]*$/.test(control.value);
    message = ErrorMessage.charsNotAllowed;

    if (isValid) {
      isValid = /^(?=.*[a-zA-Z0-9])/.test(control.value);
      message = ErrorMessage.addressValidation.invalidMsg;
    }
    return isValid ? null : { errMsg: message };
  };
}

export function cityValidator(isRequired = false) {
  return (control: AbstractControl) => {
    if (!control || ( control.value == null && !isRequired ) || ( control.value != null && control.value.length === 0 && !isRequired )) {
      return null;
    }

    if (control.value == null || control.value.length === 0 ) {
      return { errMsg: ErrorMessage.cityValidation.emptyMsg };
    }

    if (control.value.length < 2) {
      return { errMsg: ErrorMessage.cityValidation.invalidMsg };
    }

    let isValid = true;
    let message = null;

    isValid = /^[a-zA-Z0-9 #&()/:'-]*$/.test(control.value);
    message = ErrorMessage.charsNotAllowed;

    if (isValid) {
      isValid = /^(?=.*[a-zA-Z0-9])/.test(control.value);
      message = ErrorMessage.cityValidation.invalidMsg;
    }
    return isValid ? null : { errMsg: message };
  };
}

export function postalCodeValidator(country: string | CountryEnum) {
  return (control: AbstractControl) => {
    if (!control) { return null; }
    let message: string = null;
    let isValid = true;

    if (country === 'CA') {
      isValid = control.value && /^[A-Za-z][0-9][A-Za-z][ ]?[0-9][A-Za-z][0-9]$/.test(control.value.trim());
      message = isValid ? null : ErrorMessage.postalCodeValidation.postalCodeCanada;

    } else if (country === 'US') {
      isValid = control.value && /^([0-9]{5})(?:[-\s]{1}([0-9]{4}))?$/.test(control.value.trim());
      message = isValid ? null : ErrorMessage.postalCodeValidation.zipCodeUnitedStates;
    } else {
      if (control.value != null && control.value.trim().length > 0) {
        isValid = /^[A-Za-z 0-9-]{1,10}$/.test(control.value);
        message = ErrorMessage.postalCodeValidation.postalCodeInternational;
      }
    }
    const result = { errMsg: message };
    return isValid ? null : result;
  };
}

export function phoneNumberValidator(isRequired = false) {
  return (control: AbstractControl) => {
    if (!control || ( control.value == null && !isRequired ) || ( control.value != null && control.value.length === 0 && !isRequired )) {
      return null;
    }

    if ( ( control.value == null || control.value.length === 0 ) && isRequired ) {
      return { errMsg: ErrorMessage.phoneValidation.emptyMsg };
    }

    if (control.value.length < 10) {
      return { errMsg: ErrorMessage.phoneValidation.invalidMsg };
    }

    if (control.value && control.value.toString().trim().length > 0) {
      const isValid = (/\d{3}\.\d{3}\.\d{4}|\d{10}/.test(control.value));
      return isValid ? null : { errMsg: ErrorMessage.phoneValidation.invalidMsg };
    }
    return null;
  };
}

export function faxValidator(control: AbstractControl) {
  if (!control) { return null; }

  if (control.value && control.value.trim().length > 0) {
    const isValid = (/\d{3}\.\d{3}\.\d{4}|\d{10}/.test(control.value));
    return isValid ? null : { errMsg: ErrorMessage.faxInvalidMsg };
  }
  return null;
}

export function worksafeAccountNumValidator(control: AbstractControl) {
  if ( !control || control.value.length === 0  ) {
    return null;
  }

  if ( control.value && control.value.trim().length > 0 ) {
    if ( control.value.trim().length >= 2 && control.value.trim().length <= 9 )  {
      return null;
    }
  }

  return { errMsg: ErrorMessage.worksafeAccountInvalid };
}

export function nonZeroNumValidator(control: AbstractControl) {
  if ( !control || parseInt(control.value) !== 0  || parseInt(control.value) > 0 ) {
    return null;
  }
  
  return { errMsg: ErrorMessage.nonzeroInvalid }; 
}

export function craBusinessNumValidator(isRequired = false, sessionService: SessionStorageService) {
  return (control: AbstractControl) => {
    if (!control || ( control.value == null && !isRequired ) || ( control.value != null && control.value.length === 0 && !isRequired )) {
      return null;
    }

    if ( ( control.value == null || control.value.length === 0 ) && isRequired ) {
      return { errMsg: ErrorMessage.craBusinessNumInvalid };
    }

    const startDigits = sessionService.getEASconstants(EASConstants.BNFirstDigit).split(',');
    if ( validateBN(control.value, startDigits) ) { return null; }

    return { errMsg: ErrorMessage.craBusinessNumInvalid };
  };
}

export function nameOrAcctNumValidator(control: AbstractControl) {
  if ( !control || control.value.length === 0  ) {
    return null;
  }
  const nameError = (nameValidator())(control);
  if ( nameError != null ) {
    return { errMsg: ErrorMessage.nameOrAcctNumValidator.invalidMsg };
  }

  return null;
}
// regex accepts for example http://www.worksafebc.com, https://www.worksafebc.com, www.worksafebc.com
export function websiteAddressValidator(control: AbstractControl) {
  if (!control || control.value == null || control.value.length === 0) {
    return null;
  }

  if (control.value && control.value.trim().length > 0) {
    const isValid =
      (/^(http(s?):\/\/)?[a-zA-Z0-9\.\-\_]+(\.[a-zA-Z]{2,4})+(\/[a-zA-Z0-9\_\-\s\.\/\?\%\#\&\=]*)?$/.test(control.value));
    return isValid ? null : { errMsg: ErrorMessage.invalidWebsiteAddress };
  }
  return null;
}

// The common component datePicker (ngx-datepicker) returns a full DateTime object.
// To do correct comparison we set all dates to have no time
// tslint:disable-next-line: max-line-length
export function dateValidator(required: boolean, minDate?: Date, maxDate?: Date, errMsg: DateValidatorMessages = ErrorMessage.dateValidator) {
  return (control: AbstractControl) => {
    if ( !control ) { return null; }

    if(required && (control.value == "Invalid Date")){
      return { errMsg: errMsg.minDate };
    }

    if ( required && ( control.value == null || control.value.length === 0 )) {
      return { errMsg: errMsg.emptyMsg };
    } else if ( control.value == null || control.value.length === 0 ) {
      return null;
    }

    if ( minDate && new Date(control.value).setHours(0, 0, 0)  < minDate.setHours(0, 0, 0) ) {
      return { errMsg: errMsg.minDate };
    }

    const options : Intl.DateTimeFormatOptions = { weekday: "long", year: 'numeric', month: 'long', day: 'numeric' };

    if ( maxDate && new Date(control.value).setHours(0, 0, 0) > maxDate.setHours(0, 0, 0) ) {
      return { errMsg: errMsg.maxDate + maxDate.toLocaleDateString('en-US', options) + '.' };
    }
    return null;
  };
}

export function spouseCoverageDateValidator(required: boolean, minDate?: Date, maxDate?: Date) {
  return (control: AbstractControl) => {
    if ( !control ) { return null; }

    if ( (!control.value || control.value === '') && required ) {
      return { errMsg: ErrorMessage.spouseCoverageStartDtValidator.emptyMsg };
    }

    if ( minDate != null && new Date(control.value).setHours(0, 0, 0)  < minDate.setHours(0, 0, 0) ) {
      return { errMsg: ErrorMessage.spouseCoverageStartDtValidator.minDate };
    }

    if ( maxDate != null && new Date(control.value).setHours(0, 0, 0) > maxDate.setHours(0, 0, 0) ) {
      const year = maxDate.getFullYear() + 1;
      return { errMsg: ErrorMessage.spouseCoverageStartDtValidator.maxDate + year + '.' };
    }
    return null;
  };
}

export function workerCountValidator(control: AbstractControl) {
  if ( !control ) { return null; }

  if ( control.value.length === 0 ) {
    return { errMsg: ErrorMessage.workerCountValidation.emptyMsg };
  }
  const numValue = Number(control.value);
  if (isNaN(numValue)) {
    return { errMsg: ErrorMessage.workerCountValidation.emptyMsg };
  }

  if ( numValue === 0 ) {
    return { errMsg: ErrorMessage.workerCountValidation.invalidMsg };
  }

  return null;
}

export function activeShareholdersCountValidator(control: AbstractControl) {
  if ( !control ) { return null; }

  if ( control.value.length === 0 ) {
    return { errMsg: ErrorMessage.activeShareholdersCountValidator.emptyMsg };
  }
  const numValue = Number(control.value);
  if (isNaN(numValue)) {
    return { errMsg: ErrorMessage.activeShareholdersCountValidator.emptyMsg };
  }

  if ( numValue === 0 ) {
    return { errMsg: ErrorMessage.activeShareholdersCountValidator.invalidMsg };
  }

  return null;
}


export function corporationWorkerCountValidator(control: AbstractControl) {
  if ( !control ) { return null; }

  if ( control.value.length === 0 ) {
    return { errMsg: ErrorMessage.corporationWorkerCountValidation.emptyMsg };
  }
  const numValue = Number(control.value);
  if (isNaN(numValue)) {
    return { errMsg: ErrorMessage.corporationWorkerCountValidation.emptyMsg };
  }

  if ( numValue === 0 ) {
    return { errMsg: ErrorMessage.corporationWorkerCountValidation.invalidMsg };
  }

  return null;
}

export function corporationWorkerCountExcludingShareholdersValidator(control: AbstractControl) {
  if ( !control ) { return null; }

  if ( control.value.length === 0 ) {
    return { errMsg: ErrorMessage.corporationWorkerCountExcludingShareholdersValidation.emptyMsg };
  }
  const numValue = Number(control.value);
  if (isNaN(numValue)) {
    return { errMsg: ErrorMessage.corporationWorkerCountExcludingShareholdersValidation.emptyMsg };
  }

  if ( numValue === 0 ) {
    return { errMsg: ErrorMessage.corporationWorkerCountExcludingShareholdersValidation.invalidMsg };
  }

  return null;
}

export function shareholderCountValidator(control: AbstractControl) {
  if ( !control ) { return null; }

  if ( control.value.length === 0 ) {
    return { errMsg: ErrorMessage.shareholderCountValidation.emptyMsg };
  }
  const numValue = Number(control.value);
  if (isNaN(numValue)) {
    return { errMsg: ErrorMessage.shareholderCountValidation.emptyMsg };
  }

  if ( numValue === 0 ) {
    return { errMsg: ErrorMessage.shareholderCountValidation.invalidMsg };
  }

  return null;
}

export function partnerCountValidator(control: AbstractControl) {
  if ( !control ) { return null; }

  if ( control.value.length === 0 ) {
    return { errMsg: ErrorMessage.partnerCountValidation.emptyMsg };
  }
  const numValue = Number(control.value);
  if (isNaN(numValue)) {
    return { errMsg: ErrorMessage.partnerCountValidation.emptyMsg };
  }

  if ( numValue === 0 || numValue === 1 ) {
    return { errMsg: ErrorMessage.partnerCountValidation.invalidMsg };
  }

  return null;
}

export function validateTotalPercentages(arr: UntypedFormArray): ValidationErrors {
  let total = 0;
  arr.value.forEach( (val) => {
    total += Number(val.revenuePercentage);
   });

  if (total > 100) {
    return {
      exceeded: true
    };
  }
  return null;
}

// From old Ireg validation
// Replaced old logic (based on hard coded values) to use PC093 constant which stores the valid first digits of BN9.
function validateBN(bn9, startDigit: number[]) {

  if ( bn9.length !== 9 ) { return false; } // Check field length - must be 9

  const firstDigit = bn9.substr(0, 1);
  let flag = true;
  for (const i of startDigit) {
    if ( firstDigit === i ) {
      flag = false;
      break;
    }
  }
  if ( flag ) { return false; }

  return validateBN9(bn9);
}

function validateBN9(bn9) {
  const digits1 = new Array(5);
  const digits2 = new Array(4);

  // Do it by hand since there are only 9
  digits1[0] = parseInt(bn9.substr(0, 1));
  digits1[1] = parseInt(bn9.substr(2, 1));
  digits1[2] = parseInt(bn9.substr(4, 1));
  digits1[3] = parseInt(bn9.substr(6, 1));
  digits1[4] = parseInt(bn9.substr(8, 1));

  digits2[0] = sumDigits(parseInt(bn9.substr(1, 1)) * 2);
  digits2[1] = sumDigits(parseInt(bn9.substr(3, 1)) * 2);
  digits2[2] = sumDigits(parseInt(bn9.substr(5, 1)) * 2);
  digits2[3] = sumDigits(parseInt(bn9.substr(7, 1)) * 2);

  let sum = 0;
  for (let i = 0; i < digits1.length; i++) {
    sum += digits1[i];
  }

  for (let i = 0; i < digits2.length; i++){
    sum += digits2[i];
  }

  return (sum % 10 === 0);
}

function sumDigits(num) {
  let sum = 0;
  while (num >= 10) {
    sum += num % 10;
    num = Math.floor( num / 10 );
  }
  return sum + num;
}
