<div class="modal-header">
    <h2 class="modal-title p-0" id="modal-basic-title" *ngIf="startSafeQuestionnaireLeaveConfirm || startSafeActionPlanLeaveConfirm; else notStartSafeTitle">Are you sure you want to skip this step?</h2>
    <ng-template #notStartSafeTitle><h2 class="modal-title p-0" id="modal-basic-title">Are you sure you want to exit?</h2>  </ng-template>
    <button type="button" class="close" aria-label="Close" (click)="cancelClicked()">
        <i tabindex="0" class="fa fa-times"></i>
      </button>
</div>
<div class="modal-body pb-0">           
    <form>
        <div class="form-group m-0" *ngIf="!startSafeQuestionnaireLeaveConfirm && !startSafeActionPlanLeaveConfirm">
            <p>
                We’ve saved your information up to the previous page.
            </p>
            <p>
                If you exit now, any information you’ve entered on this page will not be saved, but you’ll be able to return later to continue your application. To have the information on this page saved as well, complete the page, click Continue to display the next page, and then click Exit.
            </p>
        </div>
        <div class="form-group m-0" *ngIf="startSafeQuestionnaireLeaveConfirm">
            <p>
                If you skip this step, you will be taken to your confirmation page where you will receive your Internet Application Reference Number.
            </p>
            <p>
                If you choose to answer the questions on this page, you will be provided with basic health and safety requirements that are specific to your workplace.
            </p>
            <p>
                Visit worksafebc.com any time to learn more about your health and safety responsibilities and view resources to help you create a healthy and safe workplace.
            </p>
        </div>
        <div class="form-group m-0" *ngIf="startSafeActionPlanLeaveConfirm">
            <p>
                We recommend you download the resource list before you go. You may also visit worksafebc.com any time to learn more about your health and safety responsibilities and view resources to help you create a healthy and safe workplace.
            </p>
            <p>
                The next step is your confirmation page where you will receive your Internet Application Reference Number.
            </p>
        </div>
    </form>
</div>
<div class="modal-footer">  
    <button id="confirmationOkButton" type="button" class="btn btn-sm btn-outline-blue return-button mr-1 mb-1"  (click)="okClicked()">{{this.confirmExitButtonText}}</button>
    <button id="confirmationCancelButton" type="button" class="btn btn-blue btn-sm return-button mr-1 mb-1"  (click)="cancelClicked()">{{this.cancelExitButtonText}}</button>
</div>
