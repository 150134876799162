import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable()
export class DatetimeFormatter {
    constructor() { }
    getMonth(date: Date): number {
        return date.getMonth() + 1; // January is 0!
    }

    payloadDateFormat(date: Date) {
        if (date) {
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
            const yyyy = date.getFullYear();
            const formatDate = yyyy + '-' + mm + '-' + dd;
            return formatDate;
        }
        return '';
    }

    dateObjectFormat(wdate: string) {
        if (wdate) {
            const dateElement = this.convertDateStringToStandardDate(wdate);
            if (dateElement) {
                return { month : dateElement.getMonth() + 1, date : dateElement.getDate(), year : dateElement.getFullYear() };
            }
        }
        return null;
    }

    standardDateFormat(year: number, month: number, day: number) {
        if (year && month && day && year > 0 && month > -1 && day > 0) {
            return new Date(year, month - 1, day);
        }
        return null;
    }

    standardDateTimeFormat(year: number, month: number, day: number, hour: number, minute: number) {
        if (year && month && day && hour && minute &&
            year > 0 && month > -1 && day > 0 && hour > 0 && minute > 0) {
            return new Date(year, month - 1, day, hour, minute);
        }
        return null;
    }

    currentDate() {
        return new Date();
    }

    getCurrentDateOnly() {
        const currentDate = this.currentDate();
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    }

    standardDateTimeStringFormat(myDate: string) {
        const dateElement = this.convertDateStringToStandardDate(myDate);
        return (new DatePipe('en-CA').transform(dateElement, 'mediumDate'));
    }

    convertDateStringToStandardDate(dateString: string): Date {
        // 2020-02-24T10:21:17.1
        let splitedDate = ['0', '0', '0'];
        if (dateString) {
            if (dateString.indexOf('-') > 0) {
                splitedDate =  dateString.split('-');
                const year = parseInt(splitedDate[0], 0);
                const month =  parseInt(splitedDate[1], 0);
                const day =  parseInt(splitedDate[2].slice(0, 2), 0);
                return this.standardDateFormat(year, month, day);
            } else if (dateString.indexOf('/') > 0) {
                splitedDate =  dateString.split('/');
                const month = parseInt(splitedDate[0], 0);
                const day =  parseInt(splitedDate[1], 0);
                const year =  parseInt(splitedDate[2], 0);
                return this.standardDateFormat(year, month, day);
            }
        }

        return null;
    }

    convertDateStringToStandardDateWitMinDateCheck(dateString: string, minDateStartYear: number): Date {
        // 2020-02-24T10:21:17.1
        // 02/24/2020
        if (!dateString || dateString === '') {
            return null;
        }

        let splitedDate = ['0', '0', '0'];
        if (dateString) {
            if (dateString.indexOf('-') > 0) {
                splitedDate =  dateString.split('-');
                const year = parseInt(splitedDate[0], 0);
                const month =  parseInt(splitedDate[1], 0);
                const day =  parseInt(splitedDate[2].slice(0, 2), 0);
                if (year >= minDateStartYear) {
                    return this.standardDateFormat(year, month, day);
                }
            } else if (dateString.indexOf('/') > 0) {
                splitedDate =  dateString.split('/');
                const month = parseInt(splitedDate[0], 0);
                const day =  parseInt(splitedDate[1], 0);
                const year =  parseInt(splitedDate[2], 0);
                if (year >= minDateStartYear) {
                    return this.standardDateFormat(year, month, day);
                }
            }
        }
        return null;
    }

    convertDateStringToStandardDateTime(dateString: string): Date {
        // 2020-02-24T10:21:17.1
        let hours =  0;
        let minutes =  0;
        if (dateString.indexOf('T') > 0) {
            const splitedByT = dateString.split('T');
            if (splitedByT !== null && splitedByT.length >= 2) {
                const splitedHourMinute =  splitedByT[1].split(':');
                if (splitedHourMinute && splitedHourMinute.length >= 2) {
                    hours =  parseInt(splitedHourMinute[0].slice(0, 2), 0);
                    minutes =  parseInt(splitedHourMinute[1].slice(0, 2), 0);
                }
            }
        }

        let splitedDate = ['0', '0', '0'];
        if (dateString) {
            if (dateString.indexOf('-') > 0) {
                splitedDate =  dateString.split('-');
                const year = parseInt(splitedDate[0], 0);
                const month =  parseInt(splitedDate[1], 0);
                const day =  parseInt(splitedDate[2].slice(0, 2), 0);
                return this.standardDateTimeFormat(year, month, day, hours, minutes);
            } else if (dateString.indexOf('/') > 0) {
                splitedDate =  dateString.split('/');
                const month = parseInt(splitedDate[0], 0);
                const day =  parseInt(splitedDate[1], 0);
                const year =  parseInt(splitedDate[2], 0);
                return this.standardDateTimeFormat(year, month, day, hours, minutes);
            }
        }
        return null;
      }

      isFutureDate(startDate: Date, numberOfDays: number) {
        const currentDate = new Date();
        // Worker start date in next year (2020) first quarter
        const timeDiff = startDate.getTime() - currentDate.getTime();
        if ( timeDiff < 0 ) { return false; } // If the work start date is before today's date
        const differenceInDays = Math.ceil(Math.abs(timeDiff) / (1000 * 3600 * 24));

        // Note from business:
        // If the start date is within 30 calendar dates from the system date,
        // then the system should let the user move ahead in the workflow.
        if ( differenceInDays >= numberOfDays ) {
            return true;
        }
        return false;
    }
}
