import { Injectable } from '@angular/core';
import { AppInsightsService } from 'src/app/core/appinsights.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { UserIdleService } from 'angular-user-idle';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { IdleUserModalComponent } from '../idle-user-modal/idle-user-modal.component';
import { AppSessionState } from 'src/app/services/app.session.state';
import { environment } from 'src/environments/environment';
import { AuthService } from '@wsbc/ux-lib-security';

@Injectable()
export class UserIdleSessionHandler {
  reloadSubscriptions: Subscription[] = [];
  modal: NgbModalRef;
  appSessionState: AppSessionState;

  constructor(
    private appInsightService: AppInsightsService,
    private sessionStorageService: SessionStorageService,
    private userIdle: UserIdleService,
    private modalService: NgbModal,
    private router: Router,
    private authservice: AuthService
  ) { }

  getResponse(timeoutDuration: number): Promise<boolean> {
    if (this.modal) {
      this.modal.dismiss();
    }
    const config: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      windowClass : 'registration-modal'
    };
    this.modal = this.modalService.open(IdleUserModalComponent, config);
    this.modal.componentInstance.timeoutDuration = timeoutDuration;
    return this.modal.result;
  }
  public processUserIdleTimeout() {
    this.setIsTimeoutToSessionStorage(false);
    // Start watching for user inactivity.
    this.userIdle.startWatching();
    this.reloadSubscriptions.push(
      this.userIdle.onTimerStart().subscribe((count) => {
        console.log('[User Idle Handler] Idle Timeout is up!: ' + count);
        this.readAppSessionStateFromSessionStorage();
        if (count === 1 && !this.appSessionState.isTimeout) {
          this.openIdleTimeoutModal(count);
        }
        if (count === environment.timeoutDuration) {
          this.setIsTimeoutToSessionStorage(true);
        }
      })
    );
    this.reloadSubscriptions.push(
      this.userIdle.onTimeout().subscribe((count) => {
        console.log('[User Idle Handler] Time is up!: ' + count);
      })
    );
    this.readAppSessionStateFromSessionStorage();
    if (this.appSessionState !== null && this.appSessionState.isTimeout) {
      this.userIdle.stopWatching();
      this.openIdleTimeoutModal(environment.timeoutDuration);
    }
  }

  public unsubscribe() {
    if (this.reloadSubscriptions && this.reloadSubscriptions.length > 0) {
      this.reloadSubscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }

  private openIdleTimeoutModal(count: number) {
    this.getResponse(environment.timeoutDuration - count).then(response => {
      console.log('[User Idle Handler] response: ' + response);
      if (response) { // When click Continue
        console.log('[User Idle Handler] Idle Timeout is up!: ' + count);
        this.readAppSessionStateFromSessionStorage();
        if (this.appSessionState.isTimeout) {
          this.exitPage();
        } else {
          this.userIdle.resetTimer();
        }
      } else { // When click exit
        this.setIsTimeoutToSessionStorage(true);
        this.exitPage();
      }
    }).catch(err => {
      this.userIdle.resetTimer();
    });
  }

  private exitPage() {
    this.setIsTimeoutToSessionStorage(true);
    this.sessionStorageService.setSkipRefreshPagePopupInSessionStorage();
    this.unsubscribe();
    this.authservice.signOut();
  }

  private cleanIsTimeoutSessionStorage() {
    this.sessionStorageService.cleanAppSessionStateFromSessionStorage();
  }

  private readAppSessionStateFromSessionStorage() {
    this.appSessionState = this.sessionStorageService.getAppSessionStateFromSessionStorage();
    if (this.appSessionState !== null ) {
      console.log('[User Idle Handler] Application has been timeout: ' + this.appSessionState.isTimeout);
    }
  }

  private setIsTimeoutToSessionStorage(isTimeout: boolean) {
    this.readAppSessionStateFromSessionStorage();
    console.log('[User Idle Handler] timeout set to ' + JSON.stringify(this.appSessionState));
    if (this.appSessionState === null || this.appSessionState !== null && !this.appSessionState.isTimeout) {
      console.log('[User Idle Handler] timeout set to ' + isTimeout);
      this.appInsightService.logEvent('[User Idle Handler]  AppSessionState.IsTimedout to: ' + isTimeout);
      this.sessionStorageService.storeOnSessionStorage(new AppSessionState(isTimeout));
    }
  }
}
