import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RouterStateSnapshot } from '@angular/router';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';

@Injectable()
export class LandingPageGuard implements CanActivate {

  constructor(
    private router: Router,
    private route: ActivatedRoute) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const previousUrl = this.router.getCurrentNavigation().initialUrl.toString();

    // [TODO FIX - Breaking change in Angular 14 ]
    // https://github.com/angular/angular/issues/46597

    return true;
    //partner=IBRS(don't know the acronym for ibrs) on the url indicate, URL is redirected from onestop to us.
    if (previousUrl.toLowerCase().includes('registration-dashboard') ||
      previousUrl.toLocaleLowerCase().includes('confirm') ||
      state.url.toLowerCase().includes('ibrs') ||
      route.queryParamMap.get('sessionID')) {
        return true;
    }

    this.router.navigate(['./registration-dashboard']);
    return false;
  }
}
