import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { tap, map, catchError, withLatestFrom, exhaustMap, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as ContactInfoActions from '../actions/contact-info.actions';
import * as fromApp from 'src/app/store';
import { AppService } from 'src/app/store/services/app.service';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { CommonFunctions } from 'src/app/shared/common/common-functions';
import { ProfileType, FirmType, LegalEntityCode } from 'src/app/shared/common/common-constants';
import { RegPOPCoverage } from 'src/app/store/feature-stores/coverage-question-store/models/coverage-question';
import { InternetRegistrationHelperService } from 'src/app/store/helpers/internet-registration-helper.service';
import { Router } from '@angular/router';

@Injectable()
export class ContactInfoEffects {
  private requestUrl: string = environment.registrationApiUrl + '/api/v1/AccountSetup/ContactInfo';
  private requestContactUrlE2E: string = environment.registrationApiUrl + '/api/v1/E2E/Contact';
  private requestShareholderUrlE2E: string = environment.registrationApiUrl + '/api/v1/E2E/Shareholder';

  private requestContactUrlE2ESoleProprietor: string = environment.registrationApiUrl + '/api/v1/E2ESoleProp/SolePropContact';


  private isAddressToSave: string;
  private currentPayload: any;
  private mergedPayload: any;
  private currentPayloadContactInfoE2E: any;
  private mergedPayloadContactInfoE2E: any;
  private currentPayloadShareholdertInfoE2E: any;
  private mergedPayloadShareholdertInfoE2E: any;

  private currentPayloadContactInfoE2ESoleProprietor: any;
  private mergedPayloadContactInfoE2ESoleProprietor: any;
  
  constructor(
    private appService: AppService,
    private actions$: Actions,
    private store$: Store<fromApp.State.State>,
    private iRegHelper: InternetRegistrationHelperService,
    private commonFunctions: CommonFunctions,
    private router: Router) { }

  postContactInfo$ = createEffect(() => this.actions$.pipe(
    ofType(ContactInfoActions.SendContactInfo),
    tap(action => this.getCurrentPayload(action.payload)),
    // dispatch action to add appstate.loadrers on current feature state
    tap(action =>
      this.store$.dispatch(fromApp.Actions.LoadApps({payload: 'postContactInfo$'}))),
    withLatestFrom(this.store$),
    tap(([action, state]) => {
      console.log('[ContactInfoEffect] current internetRegistration participation: ' +
       JSON.stringify(state.appstate.internetRegistration.participation) );
      this.mergePayload(state.appstate.internetRegistration);
    }),
    // map(async ([action, payload]) => payload = await this.getValue(payload)),
    tap(([action, state]) => console.log('[ContactInfoEffect] merged payload participation: ' +
     JSON.stringify(this.mergedPayload.participation))),
    exhaustMap(([action, state]) =>
      this.appService.postApp(this.requestUrl, this.mergedPayload)
        .pipe(
          tap(response => {
            console.log('[ContactInfoEffect] response participation: ' + JSON.stringify(response.participation));
            console.log('[ContactInfoEffect] response userChoice: ' + JSON.stringify(response.userChoice));
          }),
          // dispatch action to update appstate with new reponse
          tap(response => {
            this.store$.dispatch(
              fromApp.Actions.SaveAppState({
                payload: this.mergeApplicationType(state.appstate.internetRegistration, response)
              })
            );
            if (this.isBusinessPartner(this.mergedPayload, this.currentPayload)) {
              // reload IsWorkerPayrollRequired for vsc payroll, worker payroll and review and submit redirect
              this.store$.dispatch(fromApp.Actions.LoadIsWorkerPayrollRequired(this.mergedPayload));
            }
          }),
          // dispact action to update current feature state
          map(response => (
            ContactInfoActions.SendContactInfoSuccess({
              payload: this.currentPayload.isRedirectCheckPoint ?
               this.processSendContactInfoResponsePayload(response, this.currentPayload) : this.currentPayload
            })
          )),
          tap(response => {
            if (action && action.payload && action.payload.url) {
                this.router.navigateByUrl(action.payload.url);
            }
          }),
          catchError(e => throwError(new Error(JSON.stringify(e)))),
          finalize(() => {
            // dispatch action to remove loader from appstate.loaders
            this.store$.dispatch(
              fromApp.Actions.LoadAppsSuccess({payload: 'postContactInfo$'}));
          })
        )
    )
  ));

  
  postContactInfoE2E$ = createEffect(() => this.actions$.pipe(
    ofType(ContactInfoActions.SendContactInfoE2E),
    tap(action => this.getCurrentPayloadContactInfoE2E(action.payload)),
    // dispatch action to add appstate.loadrers on current feature state
    tap(action =>
      this.store$.dispatch(fromApp.Actions.LoadApps({payload: 'postContactInfoE2E$'}))),
    withLatestFrom(this.store$),
    tap(([action, state]) => {    
      this.mergePayloadContactInfoE2E(state.appstate.internetRegistration);
    }),
    // map(async ([action, payload]) => payload = await this.getValue(payload)),
    tap(([action, state]) => console.log('[ContactInfoEffect] postContactInfoE2E merged payload participation: ' +
     JSON.stringify(this.mergedPayloadContactInfoE2E.participation))),
    exhaustMap(([action, state]) =>
      this.appService.postApp(this.requestContactUrlE2E, this.mergedPayloadContactInfoE2E)
        .pipe(
          tap(response => {
            console.log('[ContactInfoEffect] postContactInfoE2E response participation: ' + JSON.stringify(response.participation));
            console.log('[ContactInfoEffect] postContactInfoE2E response userChoice: ' + JSON.stringify(response.userChoice));
          }),
          // dispatch action to update appstate with new reponse
          tap(response => {
            this.store$.dispatch(
              fromApp.Actions.SaveAppState({
                payload: this.mergeApplicationType(state.appstate.internetRegistration, response)
              })
            );           
          }),
          // dispact action to update current feature state
          map(response => (
            ContactInfoActions.SendContactInfoSuccess({
              payload: this.currentPayloadContactInfoE2E.isRedirectCheckPoint ?
               this.processSendContactInfoResponsePayload(response, this.currentPayloadContactInfoE2E) : this.currentPayloadContactInfoE2E
            })
          )),         
          catchError(e => throwError(() => new Error(JSON.stringify(e)))),
          finalize(() => {
            // dispatch action to remove loader from appstate.loaders
            this.store$.dispatch(
              fromApp.Actions.LoadAppsSuccess({payload: 'postContactInfoE2E$'}));
          })
        )
    )
  ));

  
  postShareholdertInfoE2E$ = createEffect(() => this.actions$.pipe(
    ofType(ContactInfoActions.SendShareholdertInfoE2E),
    tap(action => this.getCurrentPayloadShareholdertInfoE2E(action.payload)),
    // dispatch action to add appstate.loadrers on current feature state
    tap(action =>
      this.store$.dispatch(fromApp.Actions.LoadApps({payload: 'postShareholdertInfoE2E$'}))),
    withLatestFrom(this.store$),
    tap(([action, state]) => {    
      this.mergePayloadShareholdertInfoE2E(state.appstate.internetRegistration);
    }),
    // map(async ([action, payload]) => payload = await this.getValue(payload)),
    tap(([action, state]) => console.log('[ContactInfoEffect] postShareholdertInfoE2E merged payload participation: ' +
     JSON.stringify(this.mergedPayloadShareholdertInfoE2E.participation))),
    exhaustMap(([action, state]) =>
      this.appService.postApp(this.requestShareholderUrlE2E, this.mergedPayloadShareholdertInfoE2E)
        .pipe(
          tap(response => {
            console.log('[ContactInfoEffect] postShareholdertInfoE2E response participation: ' + JSON.stringify(response.participation));
            console.log('[ContactInfoEffect] postShareholdertInfoE2E response userChoice: ' + JSON.stringify(response.userChoice));
          }),
          // dispatch action to update appstate with new reponse
          tap(response => {
            this.store$.dispatch(
              fromApp.Actions.SaveAppState({
                payload: this.mergeApplicationType(state.appstate.internetRegistration, response)
              })
            );            
          }),
          // dispact action to update current feature state
          map(response => (
            ContactInfoActions.SendContactInfoSuccess({
              payload: this.currentPayloadShareholdertInfoE2E.isRedirectCheckPoint ?
               this.processSendContactInfoResponsePayload(response, this.currentPayloadShareholdertInfoE2E) : this.currentPayloadShareholdertInfoE2E
            })
          )),         
          catchError(e => throwError(() => new Error(JSON.stringify(e)))
          ),
          finalize(() => {
            // dispatch action to remove loader from appstate.loaders
            this.store$.dispatch(
              fromApp.Actions.LoadAppsSuccess({payload: 'postShareholdertInfoE2E$'}));
          })
        )
    )
  ));
  
  
  postContactInfoE2ESoleProprietor$ = createEffect(() => this.actions$.pipe(
    ofType(ContactInfoActions.SendContactInfoE2ESoleProprietor),
    tap(action => this.getCurrentPayloadContactInfoE2ESoleProprietor(action.payload)),
    // dispatch action to add appstate.loadrers on current feature state
    tap(action =>
      this.store$.dispatch(fromApp.Actions.LoadApps({payload: 'postContactInfoE2ESoleProprietor$'}))),
    withLatestFrom(this.store$),
    tap(([action, state]) => {    
      this.mergePayloadContactInfoE2ESoleProprietor(state.appstate.internetRegistration);
    }),
    // map(async ([action, payload]) => payload = await this.getValue(payload)),
    tap(([action, state]) => console.log('[ContactInfoEffect] postContactInfoE2ESoleProprietor merged payload participation: ' +
     JSON.stringify(this.mergedPayloadContactInfoE2ESoleProprietor.participation))),
    exhaustMap(([action, state]) =>
      this.appService.postApp(this.requestContactUrlE2ESoleProprietor, this.mergedPayloadContactInfoE2ESoleProprietor)
        .pipe(
          tap(response => {
            console.log('[ContactInfoEffect] postContactInfoE2ESoleProprietor response participation: ' + JSON.stringify(response.participation));
            console.log('[ContactInfoEffect] postContactInfoE2ESoleProprietor response userChoice: ' + JSON.stringify(response.userChoice));
          }),
          // dispatch action to update appstate with new reponse
          tap(response => {
            this.store$.dispatch(
              fromApp.Actions.SaveAppState({
                payload: this.mergeApplicationType(state.appstate.internetRegistration, response)
              })
            );           
          }),
          // dispact action to update current feature state
          map(response => (
            ContactInfoActions.SendContactInfoSuccess({
              payload: this.currentPayloadContactInfoE2ESoleProprietor.isRedirectCheckPoint ?
               this.processSendContactInfoResponsePayload(response, this.currentPayloadContactInfoE2ESoleProprietor) : this.currentPayloadContactInfoE2ESoleProprietor
            })
          )),         
          catchError(e => throwError(() => new Error(JSON.stringify(e)))),
          finalize(() => {
            // dispatch action to remove loader from appstate.loaders
            this.store$.dispatch(
              fromApp.Actions.LoadAppsSuccess({payload: 'postContactInfoE2ESoleProprietor$'}));
          })
        )
    )
  ));

  private mergeApplicationType(internetRegistration: any, response: any) {
    return {
      ...response,
      accountType: internetRegistration.accountType
    };
  }

  private getCurrentPayload(payload: any) {
    console.log('[ContactInfoEffect] input payload: ' + JSON.stringify(payload));
    if (payload.item) {
      payload.item.contactName = this.commonFunctions.trimLeadingAndTrailingSpace(payload.item.contactName);
    }
    if (payload.userChoice) {
      if (payload.userChoice.contactNameLast) {
        payload.userChoice.contactNameLast = this.commonFunctions.trimLeadingAndTrailingSpace(payload.userChoice.contactNameLast);
        payload.userChoice.contactNameFirst = this.commonFunctions.trimLeadingAndTrailingSpace(payload.userChoice.contactNameFirst);
      }
    }
    this.currentPayload = {
      firmType: payload.firmType,
      participation: payload.participation,
      userChoice: payload.userChoice,
      isRedirectCheckPoint: payload.isRedirectCheckPoint ? payload.isRedirectCheckPoint : null
    };
    console.log('[ContactInfoEffect] current payload: ' + JSON.stringify(this.currentPayload));
  }
  
  private mergePayload(internetRegistration: any): any {
    console.log('[ContactInfoEffect] mergePayload merging current participation');
    let firmType;    
    switch (internetRegistration.accountType) {
      case ProfileType.homeOwner:
        this.isAddressToSave = 'false';
        if ( this.isRecordExistsHomeowner(internetRegistration) ) {
          this.homeOwnerMergePayload(internetRegistration);
        } else {
          this.addParticipant(internetRegistration);
        }
        break;
      case ProfileType.retailer:
      case ProfileType.restaurant:
        this.isAddressToSave = 'true';
        firmType = (this.currentPayload.firmType ) ? this.currentPayload.firmType :
        internetRegistration.participation[0].regLegalEntity.item.firmTypeCode;
        if ( this.isRecordExists(internetRegistration) ) {
          this.retailerRestaurantMergePayload(internetRegistration, firmType);
        } else {
          this.addParticipant(internetRegistration);
        }
        break;
      case ProfileType.other:
        this.isAddressToSave = 'true';
        firmType = (this.currentPayload.firmType ) ? this.currentPayload.firmType :
          internetRegistration.participation[0].regLegalEntity.item.firmTypeCode;
        switch ( firmType ) {
          case FirmType.proprietor:
            if ( this.isRecordExists(internetRegistration) ) {
              this.proprietorMergePayload(internetRegistration);
            } else {
              this.addParticipant(internetRegistration);
            }
            break;
          case FirmType.partner:
            const oldParticipationNames = this.getParticipationNames(internetRegistration);
            if ( this.isRecordExists(internetRegistration) ) {
              this.partnerMergePayload(internetRegistration);
            } else {
              this.addParticipant(internetRegistration);
            }
            this.updatePopCoverageForPartner(oldParticipationNames, this.currentPayload);
            this.userChoiceCountPartners();
            break;
          case FirmType.limitedCompany:
          case FirmType.association:
          case FirmType.band:
          case FirmType.church:
          case FirmType.cooperative:
          case FirmType.society:
          case FirmType.union:
              if ( this.isRecordExists(internetRegistration) ) {
                this.incorporatedMergePayload(internetRegistration);
              } else {
                this.addParticipant(internetRegistration);
              }
              this.userChoiceCountPartners();
              break;
        }
        break;
    }
    return this.mergedPayload;
  }

  private partnerMergePayload(internetRegistration: any) {
    this.mergedPayload = {
      ...internetRegistration,
      userChoice: {
        ...internetRegistration.userChoice,
        ...this.currentPayload.userChoice
      },
      isMailingAddressValidationEnabled: this.isAddressToSave
    };

    const index = this.currentPayload.participation[0].participationId;
    if ( index === '0' ) {
      const participant = this.currentPayload.participation[0];
      this.mergedPayload.participation[0] = {
        participationId: participant.participationId,
        regParticipation: participant.regParticipation,
        regLegalEntity: {
          internetLegalEntity: {
            ...internetRegistration.participation[0].regLegalEntity.internetLegalEntity,
            ...participant.regLegalEntity.internetLegalEntity
          },
          legalEntityAddress: internetRegistration.participation[0].regLegalEntity.legalEntityAddress,
          item: {
            ...internetRegistration.participation[0].regLegalEntity.item,
            ...participant.regLegalEntity.item
          }
        }
      };
      this.mergedPayload.participation[0].regLegalEntity.item.contactDescription1 =
        internetRegistration.participation[0].regLegalEntity.item.contactDescription1;
      this.mergedPayload.participation[0].regLegalEntity.legalEntityAddress[0].email =
        participant.regLegalEntity.legalEntityAddress[0].email;
      this.mergedPayload.participation[0].regLegalEntity.internetLegalEntity.legalEntityTypeCode =
        participant.regLegalEntity.internetLegalEntity.legalEntityTypeCode;
      this.mergedPayload.participation[0].regLegalEntity.item.firmTypeCode = FirmType.partner;
    } else {
      this.mergedPayload.participation[index].regLegalEntity = this.currentPayload.participation[0].regLegalEntity;
    }
  }

  private userChoiceCountPartners() {
    // get personalPartnerData after merge with new added participation
    const personalPartnerData =  this.iRegHelper.getPersonalParticipants(this.mergedPayload);
    // currentPartner and confirmedPartnerCount are for all partners (business and personal partners)
    this.mergedPayload.userChoice.partnerCount = 0;
    this.mergedPayload.userChoice.currentPartner = 0;
    this.mergedPayload.userChoice.confirmedPartnerCount = 0;
    if (this.mergedPayload.participation && this.mergedPayload.participation.length > 1 ) {
       // remove p[0] which is contact info
       this.mergedPayload.userChoice.currentPartner = this.mergedPayload.participation.length - 1;
       this.mergedPayload.userChoice.confirmedPartnerCount = this.mergedPayload.userChoice.currentPartner;
       // partnerCount is only for personal partners
       this.mergedPayload.userChoice.partnerCount = personalPartnerData && personalPartnerData.length > 0 ? personalPartnerData.length : 0;
    }
 }

  private getParticipationNames(internetRegistration: any) {
    const names = [];
    // get personalPartnerData before merge
    const personalPartnerData =  this.iRegHelper.getPersonalParticipants(internetRegistration);
    if (personalPartnerData && personalPartnerData.length > 0) {
      personalPartnerData.forEach(participant => {
        if (participant && participant.regLegalEntity && participant.regLegalEntity.item) {
          names.push({
            participationId: participant.participationId,
            firstName: this.commonFunctions.trimLeadingAndTrailingSpace(participant.regLegalEntity.item.firstName),
            lastName: this.commonFunctions.trimLeadingAndTrailingSpace(participant.regLegalEntity.item.lastName)
          });
        }
      });
    }
    return names;
  }

  private updatePopCoverageForPartner( names: any, currentPayload: any) {
    // set mustRedoIndIndicator = 'Y' means regPOPCoverage page is incomplete.
    // only for personal partner
    // currentPayload should only contain one participation
    const currentParticipation = currentPayload.participation[0];
    if ( currentParticipation && currentParticipation.regLegalEntity && currentParticipation.regLegalEntity.internetLegalEntity &&
        currentParticipation.regLegalEntity.internetLegalEntity.legalEntityTypeCode !== LegalEntityCode.personal) {
        return;
    }

    if (currentParticipation) {
        // for edit
        let participationData = names.find(p => p.participationId === currentParticipation.participationId);
        if (participationData && participationData.participationId > 0) {
          const oldFirstName = this.commonFunctions.trimLeadingAndTrailingSpace(participationData.firstName);
          const oldLastName = this.commonFunctions.trimLeadingAndTrailingSpace(participationData.lastName);
          const newFirstName = this.commonFunctions.trimLeadingAndTrailingSpace(currentParticipation.regLegalEntity.item.firstName);
          const newlastName = this.commonFunctions.trimLeadingAndTrailingSpace(currentParticipation.regLegalEntity.item.lastName);
          if (oldFirstName !== newFirstName || oldLastName !== newlastName ) {
            if (this.mergedPayload.participation[currentParticipation.participationId] &&
              this.mergedPayload.participation[currentParticipation.participationId].regPOPCoverage) {
              this.mergedPayload.participation[currentParticipation.participationId].regPOPCoverage.mustRedoIndIndicator = 'Y';
            } else {
              this.mergedPayload.participation[currentParticipation.participationId].regPOPCoverage = new RegPOPCoverage();
              this.mergedPayload.participation[currentParticipation.participationId].regPOPCoverage.mustRedoIndIndicator = 'Y';
            }
          }
          return;
        }

        // add new
        participationData = names.find(p => p.participationId === currentParticipation.participationId);
        if (!participationData) {
            this.mergedPayload.participation[currentParticipation.participationId].regPOPCoverage = new RegPOPCoverage();
            this.mergedPayload.participation[currentParticipation.participationId].regPOPCoverage.mustRedoIndIndicator = 'Y';
            return;
        }
    }
 }

  private proprietorMergePayload(internetRegistration: any ) {
    const updatePopCoverageForProprietor = this.needUpdatePopCoverageForProprietor(internetRegistration, this.currentPayload);
    this.mergedPayload = {
      ...internetRegistration,
      userChoice: {
        ...internetRegistration.userChoice,
        ...this.currentPayload.userChoice
      },
      isMailingAddressValidationEnabled: this.isAddressToSave
    };
    const description = internetRegistration.participation[0].regLegalEntity.item.contactDescription1;
    this.currentPayload.participation.forEach(participant => {
      if (participant.participationId === '0') {
        // only update what you need in case accidently clean out the defaultdata when come back from next page.
        // for example: CoverageQuestionEffects oroginal set the default data for participation[0].regPOPCoverage = new RegPOPCoverage();
        // R&S: Review and Submit page
        // However, in this steps, you will find why we cannot overwrite this.mergedPayload.participation[0]
        // step1: select no worker, has POP, no vsc go to CoverageQuestion page, POP and then go to R&S.
        // step2: from R&S, edit Business contact information and direct to R&S.
        // step3: from R&S, edit POP which direct go to POP coverage page.
        //        Since Business contact information is not related to POP coverage, there is no incomplete check for POP.
        //        (there is no edit on R&S can back to CoverageQuestion again.
        //        That means we need to keep the data which we initial set up by CoverageQuestion.)
        // step4: when complete edit POP, it will go to payment schedule.
        //        (if the POP default setting is clean out from this.mergedPayload.participation[0],
        //        it will break GetPOPInvoiceSchedule API)

        this.mergedPayload.participation[0].participationId = participant.participationId;
        this.mergedPayload.participation[0].regParticipation = participant.regParticipation;
        this.mergedPayload.participation[0].regLegalEntity = {
          internetLegalEntity: {
            ...internetRegistration.participation[0].regLegalEntity.internetLegalEntity,
            ...participant.regLegalEntity.internetLegalEntity
          },
          legalEntityAddress: internetRegistration.participation[0].regLegalEntity.legalEntityAddress,
          item: {
            ...internetRegistration.participation[0].regLegalEntity.item,
            ...participant.regLegalEntity.item
          }
        };

        this.mergedPayload.participation[0].regLegalEntity.item.contactDescription1 = description;
        this.mergedPayload.participation[0].regLegalEntity.legalEntityAddress[0].email =
          participant.regLegalEntity.legalEntityAddress[0].email;
        this.mergedPayload.participation[0].regLegalEntity.internetLegalEntity.legalEntityTypeCode =
          participant.regLegalEntity.internetLegalEntity.legalEntityTypeCode;
        this.mergedPayload.participation[0].regLegalEntity.item.firmTypeCode = FirmType.proprietor;
      } else if (participant.participationId === '1') {
        this.mergedPayload.participation[1].participationId =  participant.participationId;
        this.mergedPayload.participation[1].regParticipation = participant.regParticipation;
        this.mergedPayload.participation[1].regLegalEntity = {
          internetLegalEntity: {
            ...internetRegistration.participation[1].regLegalEntity.internetLegalEntity,
            ...participant.regLegalEntity.internetLegalEntity
          },
          legalEntityAddress: participant.regLegalEntity.legalEntityAddress,
          item: participant.regLegalEntity.item
        };
        // this.mergedPayload.participation[1].regLegalEntity.legalEntityAddress.email =
        // participant.regLegalEntity.legalEntityAddress.email;
        this.mergedPayload.participation[1].regLegalEntity.internetLegalEntity.legalEntityTypeCode =
          participant.regLegalEntity.internetLegalEntity.legalEntityTypeCode;

        if (updatePopCoverageForProprietor) {
          this.mergedPayload.participation[1].regPOPCoverage.mustRedoIndIndicator = 'Y';
        }
      }
    });
  }

  private homeOwnerMergePayload(internetRegistration: any) {
    this.mergedPayload = {
      ...internetRegistration,
      participation: this.currentPayload.participation,
      userChoice: {
        ...internetRegistration.userChoice,
        ...this.currentPayload.userChoice
      },
      isMailingAddressValidationEnabled: this.isAddressToSave
    };
    if (internetRegistration.participation.length > this.mergedPayload.participation.length) {

      internetRegistration.participation.pop();
    }
    if (internetRegistration.participation.length < this.mergedPayload.participation.length) {
      internetRegistration.participation.push(this.mergedPayload.participation[this.mergedPayload.participation.length - 1]);
    }
    for (let i = 0; i < internetRegistration.participation.length; i++) {
      if (i === 0 && internetRegistration.participation[i].regLegalEntity.item != null &&
        internetRegistration.participation[i].regLegalEntity.item.contactDescription1 != null) {
          this.mergedPayload.participation[i].regLegalEntity.item.contactDescription1 =
          internetRegistration.participation[i].regLegalEntity.item.contactDescription1;
      }
      for (let j = 0; j < internetRegistration.participation[i].regLegalEntity.legalEntityAddress.length; j++) {
        if (this.mergedPayload.participation[i].regLegalEntity.legalEntityAddress[j]) {
          this.commonFunctions.copyMailingAddress(
            internetRegistration.participation[i].regLegalEntity.legalEntityAddress[j],
            this.mergedPayload.participation[i].regLegalEntity.legalEntityAddress[j]);
          // first participation and second address(work address)
          if (i === 0 && j === 1) {
            this.mergedPayload.participation[i].regLegalEntity.legalEntityAddress[j].email =
              internetRegistration.participation[i].regLegalEntity.legalEntityAddress[j].email;
            this.mergedPayload.participation[i].regLegalEntity.legalEntityAddress[j].phoneAreaCode =
              internetRegistration.participation[i].regLegalEntity.legalEntityAddress[j].phoneAreaCode;
            this.mergedPayload.participation[i].regLegalEntity.legalEntityAddress[j].phoneNumber =
              internetRegistration.participation[i].regLegalEntity.legalEntityAddress[j].phoneNumber;
          }
        } else {
          this.mergedPayload.participation[i].regLegalEntity.legalEntityAddress
          .push(internetRegistration.participation[i].regLegalEntity.legalEntityAddress[j]);
        }
      }
    }
  }

  private isRecordExistsHomeowner(internetRegistration): boolean {
    return (internetRegistration.participation != null &&
      internetRegistration.participation.length > 0 &&
      internetRegistration.participation[0].regLegalEntity.legalEntityAddress[0].email &&
      internetRegistration.participation[0].regLegalEntity.legalEntityAddress[0].phoneAreaCode &&
      internetRegistration.participation[0].regLegalEntity.legalEntityAddress[0].phoneNumber);
  }

  private addParticipant(internetRegistration: any) {
    console.log('[ContactInfoEffect] Generating new participation');
    this.mergedPayload = {
      ...internetRegistration,
      participation: [
        ...internetRegistration.participation,
        ...this.currentPayload.participation],
      userChoice: {
        ...internetRegistration.userChoice,
        ...this.currentPayload.userChoice
      },
      isMailingAddressValidationEnabled: this.isAddressToSave
    };
  }

  private needUpdatePopCoverageForProprietor(internetRegistration: any, currentPayload: any): boolean {
    // set mustRedoIndIndicator = 'Y' means regPOPCoverage page is incomplete.
    // only for personal contact
    let needUpdatePopCoverage = false;
    const firmType = internetRegistration.participation && internetRegistration.participation[0] ?
    internetRegistration.participation[0].regLegalEntity.item.firmTypeCode : null;

    if (( firmType === FirmType.proprietor) &&
      internetRegistration.participation &&
      internetRegistration.participation.length > 1 &&
      internetRegistration.participation[1] &&
      internetRegistration.participation[1].regPOPCoverage &&
      internetRegistration.participation[1].regLegalEntity &&
      internetRegistration.participation[1].regLegalEntity.item ) {

      currentPayload.participation.forEach(participant => {
        if (participant.participationId === '1') {
          const oldFirstName = this.commonFunctions.trimLeadingAndTrailingSpace(internetRegistration.participation[1].regLegalEntity.item.firstName);
          const oldLastName = this.commonFunctions.trimLeadingAndTrailingSpace(internetRegistration.participation[1].regLegalEntity.item.lastName);
          const newFirstName = this.commonFunctions.trimLeadingAndTrailingSpace(participant.regLegalEntity.item.firstName);
          const newlastName = this.commonFunctions.trimLeadingAndTrailingSpace(participant.regLegalEntity.item.lastName);
          if (oldFirstName !== newFirstName || oldLastName !== newlastName ) {
            needUpdatePopCoverage = true;
          }
        }
      });
    }
    return needUpdatePopCoverage;
  }

  private processSendContactInfoResponsePayload(response: any, currentPayload: any) {
    // need to return  userChoice to support duplicate check
    currentPayload.responseUserChoice = {};
    if (response && response.userChoice && currentPayload) {
      currentPayload.responseUserChoice = {
        isForceExitDuplicateBN : response.userChoice.isForceExitDuplicateBN,
        isForceExitDuplicateBLE : response.userChoice.isForceExitDuplicateBLE,
        isForceExitDuplicateSIN : response.userChoice.isForceExitDuplicateSIN,
        isForceExitDuplicatePLE : response.userChoice.isForceExitDuplicatePLE
      };
    }
    console.log('[ContactInfoEffect] processSendContactInfoResponsePayload: ' + JSON.stringify(currentPayload));
    return currentPayload;
  }

  private isBusinessPartner(mergedPayload: any, currentPayload: any) {
    if (mergedPayload && mergedPayload.accountType ===  ProfileType.other &&
      currentPayload && currentPayload.firmType === FirmType.partner) {
      const currentParticipation = currentPayload.participation[0];
      if ( currentParticipation && currentParticipation.participationId !== '0' &&
           currentParticipation.regLegalEntity && currentParticipation.regLegalEntity.internetLegalEntity &&
           currentParticipation.regLegalEntity.internetLegalEntity.legalEntityTypeCode === LegalEntityCode.business) {
          return true;
      }
    }
    return false;
  }

  private isRecordExists(internetRegistration): boolean {
    if ( internetRegistration.participation != null ) {
      const n = Number(this.currentPayload.participation[0].participationId);
      return !(typeof(internetRegistration.participation[n]) === 'undefined');
    }
  }

  private incorporatedMergePayload(internetRegistration: any) {    
    this.mergedPayload = {
      ...internetRegistration,
      userChoice: {
        ...internetRegistration.userChoice,
        ...this.currentPayload.userChoice
      },
      isMailingAddressValidationEnabled: this.isAddressToSave
    };

    const index = this.currentPayload.participation[0].participationId;
    if ( index === '0' ) {
      const participant = this.currentPayload.participation[0];
      this.mergedPayload.participation[0] = {
        participationId: participant.participationId,
        regParticipation: participant.regParticipation,
        regLegalEntity: {
          internetLegalEntity: {
            ...internetRegistration.participation[0].regLegalEntity.internetLegalEntity,
            ...participant.regLegalEntity.internetLegalEntity
          },
          legalEntityAddress: internetRegistration.participation[0].regLegalEntity.legalEntityAddress,
          item: {
            ...internetRegistration.participation[0].regLegalEntity.item,
            ...participant.regLegalEntity.item
          }
        }
      };
      this.mergedPayload.participation[0].regLegalEntity.item.contactDescription1 =
        internetRegistration.participation[0].regLegalEntity.item.contactDescription1;
      this.mergedPayload.participation[0].regLegalEntity.legalEntityAddress[0].email =
        participant.regLegalEntity.legalEntityAddress[0].email;
      this.mergedPayload.participation[0].regLegalEntity.internetLegalEntity.legalEntityTypeCode =
        participant.regLegalEntity.internetLegalEntity.legalEntityTypeCode;
    } else {
      this.mergedPayload.participation[index].regLegalEntity = this.currentPayload.participation[0].regLegalEntity;
    }
  }

  private retailerRestaurantMergePayload(internetRegistration: any, firmType: FirmType) {
    this.mergedPayload = {
      ...internetRegistration,
      userChoice: {
        ...internetRegistration.userChoice,
        ...this.currentPayload.userChoice
      },
      isMailingAddressValidationEnabled: this.isAddressToSave
    };

    const index = this.currentPayload.participation[0].participationId;
    if ( index === '0' ) {
      const participant = this.currentPayload.participation[0];
      this.mergedPayload.participation[0] = {
        participationId: participant.participationId,
        regParticipation: participant.regParticipation,
        regLegalEntity: {
          internetLegalEntity: {
            ...internetRegistration.participation[0].regLegalEntity.internetLegalEntity,
            ...participant.regLegalEntity.internetLegalEntity
          },
          legalEntityAddress: internetRegistration.participation[0].regLegalEntity.legalEntityAddress,
          item: {
            ...internetRegistration.participation[0].regLegalEntity.item,
            ...participant.regLegalEntity.item
          }
        }
      };
      this.mergedPayload.participation[0].regLegalEntity.item.contactDescription1 =
        internetRegistration.participation[0].regLegalEntity.item.contactDescription1;
      this.mergedPayload.participation[0].regLegalEntity.legalEntityAddress[0].email =
        participant.regLegalEntity.legalEntityAddress[0].email;
      this.mergedPayload.participation[0].regLegalEntity.internetLegalEntity.legalEntityTypeCode =
        participant.regLegalEntity.internetLegalEntity.legalEntityTypeCode;
      this.mergedPayload.participation[0].regLegalEntity.item.firmTypeCode = firmType;
    } else {
      this.mergedPayload.participation[index].regLegalEntity = this.currentPayload.participation[0].regLegalEntity;
    }
  }

  //E2E  
  private getCurrentPayloadContactInfoE2E(payload: any) {    
    this.currentPayloadContactInfoE2E = {    
      contactInfo: payload.contactInfo,
      accountType: payload.accountType,
      firmType: payload.firmType
    };
    console.log('[ContactInfoEffect] getCurrentPayloadE2E current payload: ' + JSON.stringify(this.currentPayloadContactInfoE2E));
  }

  private mergePayloadContactInfoE2E(internetRegistration: any): any {
    console.log('[ContactInfoEffect] mergePayloadContactInfoE2E merging current participation');     
    internetRegistration.accountType = this.currentPayloadContactInfoE2E.accountType;
    internetRegistration.firmType = this.currentPayloadContactInfoE2E.firmType;
    this.mergedPayloadContactInfoE2E = { 
      email: internetRegistration?.email, 
      iRegReferenceNumber : internetRegistration?.registrationSession?.iRegReferenceNumber,
      accountType : this.currentPayloadContactInfoE2E.accountType,
      firmType : this.currentPayloadContactInfoE2E.firmType,       
      contactInfoPage: this.currentPayloadContactInfoE2E.contactInfo,
      isReviewVisited: internetRegistration?.userChoice?.isReviewVisited
    };
    return this.mergedPayloadContactInfoE2E;
  }  

  private getCurrentPayloadShareholdertInfoE2E(payload: any) {    
    this.currentPayloadShareholdertInfoE2E = {    
      shareholderinfo: payload.shareholderinfo,
      accountType: payload.accountType,
      firmType: payload.firmType
    };
    console.log('[ContactInfoEffect] getCurrentPayloadShareholdertInfoE2E current payload: ' + JSON.stringify(this.currentPayloadShareholdertInfoE2E));
  }

  private mergePayloadShareholdertInfoE2E(internetRegistration: any): any {
    console.log('[ContactInfoEffect] mergePayloadShareholdertInfoE2E merging current participation');     
    internetRegistration.accountType = this.currentPayloadShareholdertInfoE2E.accountType;
    internetRegistration.firmType = this.currentPayloadShareholdertInfoE2E.firmType;
    this.mergedPayloadShareholdertInfoE2E = {    
      iRegReferenceNumber : internetRegistration?.registrationSession?.iRegReferenceNumber,
      accountType : this.currentPayloadShareholdertInfoE2E.accountType,
      firmType : this.currentPayloadShareholdertInfoE2E.firmType,       
      shareholderinfoPage: this.currentPayloadShareholdertInfoE2E.shareholderinfo,
      isReviewVisited: internetRegistration?.userChoice?.isReviewVisited
    };
    return this.mergedPayloadShareholdertInfoE2E;
  }  

  //E2ESoleProprietor  
  private getCurrentPayloadContactInfoE2ESoleProprietor(payload: any) {    
    this.currentPayloadContactInfoE2ESoleProprietor = {    
      contactInfo: payload.contactInfo,
      accountType: payload.accountType,
      firmType: payload.firmType
    };
    console.log('[ContactInfoEffect] getCurrentPayloadE2ESoleProprietor current payload: ' + JSON.stringify(this.currentPayloadContactInfoE2E));
  }

  private mergePayloadContactInfoE2ESoleProprietor(internetRegistration: any): any {
    console.log('[ContactInfoEffect] mergePayloadContactInfoE2E merging current participation');     
    internetRegistration.accountType = this.currentPayloadContactInfoE2ESoleProprietor.accountType;
    internetRegistration.firmType = this.currentPayloadContactInfoE2ESoleProprietor.firmType;
    this.mergedPayloadContactInfoE2ESoleProprietor = { 
      email: internetRegistration?.email, 
      iRegReferenceNumber : internetRegistration?.registrationSession?.iRegReferenceNumber,
      accountType : this.currentPayloadContactInfoE2ESoleProprietor.accountType,
      firmType : this.currentPayloadContactInfoE2ESoleProprietor.firmType,       
      ContactInfoPageSoleProp: this.currentPayloadContactInfoE2ESoleProprietor.contactInfo,
      isReviewVisited: internetRegistration?.userChoice?.isReviewVisited
    };
    return this.mergedPayloadContactInfoE2ESoleProprietor;
  }  
}