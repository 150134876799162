import { Inject, Injectable, Injector, Renderer2, RendererFactory2 } from '@angular/core';
import { UserManager } from 'oidc-client';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { AuthService, SECURITY_SETTINGS, SecurityRoutesEnum, SecuritySettings } from '@wsbc/ux-lib-security';

@Injectable()
export class OidcAuthService implements AuthService {
  readonly REDIRECT_PATH_KEY = 'redirectPath';

  private _securitySettings: SecuritySettings;

  private _userManager: UserManager;

  private _user = new BehaviorSubject<any | null>(null);
  public user = this._user.asObservable();

  private _idToken = new BehaviorSubject<string | null>(null);
  public idToken = this._idToken.asObservable();

  private _isAuthenticated = new BehaviorSubject<boolean>(false);
  public isAuthenticated = this._isAuthenticated.asObservable();

  private _exitUrl = new ReplaySubject<string>();
  public exitUrl = this._exitUrl.asObservable();

  private _renderer: Renderer2;

  private storageEventListener: any;

  constructor(@Inject(SECURITY_SETTINGS) securitySettings: SecuritySettings,
              private injector: Injector) {
    const rendererFactory = this.injector.get(RendererFactory2);
    this._renderer = rendererFactory.createRenderer(null, null);
    this._securitySettings = securitySettings;
    const scopes = securitySettings.scopes && securitySettings.scopes.length ? securitySettings.scopes : ['wsbcprofile'];

    let metaDataSetup = {
      issuer: `${securitySettings.stsAuthority}`,
      authorization_endpoint: `${securitySettings.stsAuthority}/authorize`,
      jwks_uri: `${securitySettings.stsAuthority}/jwks`,
      token_endpoint: `${securitySettings.stsAuthority}/token`,
      userinfo_endpoint: `${securitySettings.stsAuthority}/userinfo`
    }

    this._userManager = new UserManager({
      authority: securitySettings.stsAuthority,
      client_id: securitySettings.clientId,
      redirect_uri: `${securitySettings.clientRoot}/${SecurityRoutesEnum.SignInCallback}`,
      scope: `openid ${scopes.join(' ')}`,
      response_type: 'code',
      metadata: metaDataSetup
    });

    this.user.subscribe(user => {
      const isAuthenticated = !!user && user.expired !== null && user.expired !== undefined && !user.expired;
      this._isAuthenticated.next(isAuthenticated);
      const idToken = !!user && !user.expired && user.id_token ? user.id_token : null;
      this._idToken.next(idToken);
      const exitUrl = user?.profile?.exiturl ?? "";
      this._exitUrl.next(exitUrl);
    });

    this._userManager.events.addUserLoaded(user => {
      this._user.next(user);
    });

    this._userManager.events.removeUserLoaded(user => {
      this._user.next(user);
    });

    this._userManager.startSilentRenew();
  }

  roles: string[] = [];

  initialize() {
    return new Promise<void>((resolve, reject) => {
      if (window.location.pathname === '/' + SecurityRoutesEnum.SignInCallback) {
        if (this.inIframe()) {
          this.completeSilentSignIn();
        } else {
          this.completeSignIn().then(() => resolve()).catch(() => reject());
        }
      } else {
        this.subscribeToQueryParams().then(() => resolve()).catch(() => reject());
      }
    });
  }

  redirectToSignInPage(redirectPath?: string) {
    if (redirectPath) {
      // Before redirecting to the sign in page, store the path so the application can return to this path after signing in
      sessionStorage.setItem(this.REDIRECT_PATH_KEY, redirectPath);
    }
    this._userManager.signinRedirect();
  }

  signOut() {
    const token = this._idToken.getValue();
    if (token) {
      const decoded_token: any = jwt_decode(token);
      if (decoded_token.exiturl) {
        setTimeout(() => {
          window.open(decoded_token.exiturl, '_self');
        });
      }
    }
  }

  private completeSignIn() {
    return new Promise<void>((resolve, reject) => {
      this._userManager.signinRedirectCallback().then(user => {
        this._user.next(user);
        this.handleSigninRedirectCallback();
        resolve();
      }).catch((error) => {
        if (error.message === 'Internal Server Error (500)') {
          window.open(this._securitySettings.errorUrl, '_self');
        } else {
          // When there is an error, redirect to the signin page will either fix it or request the login credentials again
          this.redirectToSignInPage();
        }
        reject();
      });
    });
  }

  private handleSigninRedirectCallback() {
    // Once login is successful, we want to start listening for beforeunload to clear the user
    // Also listen for session storage changes - for when user manually clears the session storage
    this.startListeningToWindowEvents();
    this.navigateToRedirectPathFromSignInCallback();
  }

  private navigateToRedirectPathFromSignInCallback() {
    const redirectPath = sessionStorage.getItem(this.REDIRECT_PATH_KEY) ? sessionStorage.getItem(this.REDIRECT_PATH_KEY) : '/';
    // After retrieving the redirectUrl, clear it so that it can't be used again.
    sessionStorage.removeItem(this.REDIRECT_PATH_KEY);

    // Replace the signin-callback so when the user clicks back it will not go back to signin-callback (FF)
    history.replaceState(null, '', '/');

    this.injector.get(Router).navigateByUrl(redirectPath);
  }

  private completeSilentSignIn() {
    return this._userManager.signinSilentCallback();
  }

  private subscribeToQueryParams() {
    return new Promise<void>((resolve, reject) => {
      const activatedRoute = this.injector.get(ActivatedRoute);
      const sub = activatedRoute.queryParams.pipe(debounceTime(200)).subscribe(async (params) => {
        // If no code in url check for authenticated user
        // if no user found, redirect to sign in page
        // else if user found, complete promise
        if (!params['code']) {
          let user = this._user.getValue();
          if (!user || (user && user.expired)) {
            user = await this._userManager.getUser();
            this._user.next(user);
          }
          if (!user || (user && user.expired)) {
            sub.unsubscribe();
            if (!this._securitySettings.allowAnonymous) {
              this.redirectToSignInPage(window.location.pathname + window.location.search);
              reject();
            }
            resolve();
          } else {
            sub.unsubscribe();

            // Once login is successful, we want to start listening for pagehide to clear the user
            // Also listen for session storage changes - for when user manually clears the session storage
            this.startListeningToWindowEvents();
            resolve();
          }
        }
      });
    });
  }

  private startListeningToWindowEvents() {
    this.listenToUnloadAndClearSession();
    this.listenToSessionStorage();
  }

  private listenToUnloadAndClearSession() {
    // On Chrome console logs, breakpoints, etc. don't show up, but this event does fire
    this._renderer.listen(window, 'pagehide', () => {
      // Only clear session if not in iframe.  Iframe is doing silent token renewals.
      if (!this.inIframe()) {
        this.clearSession();
      }
    });
  }

  private listenToSessionStorage() {
    this.storageEventListener = this._renderer.listen(window, 'storage', () => {
      // Attempt to update the user when there is storage change - This is important for when a user manually clears storage through browser
      this._userManager.getUser()
        .then(user => {
          this._user.next(user);
        })
        .catch(() => {
          this._user.next(null);
        });
    });
  }

  private clearSession() {
    // Unlisten to storage event listener before clearing to avoid infinite loop
    if (this.storageEventListener) {
      this.storageEventListener();
    }
    // Session clearing
    this._user.next(null);
    this._userManager.removeUser();
  }

  private inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
}
