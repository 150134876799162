import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmLeaveComponent } from './confirm-leave-modal/confirm-leave.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { HelpTooltipComponent } from './help-tooltip/help-tooltip.component';
import { StepperComponent } from './stepper/stepper.component';
import { NgxMaskModule } from 'ngx-mask';
import { RedirectModalComponent } from './redirect-modal/redirect-modal.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { SurveyPopupComponent } from './survey-popup/survey-popup.component';
import { IdleUserModalComponent } from './idle-user-modal/idle-user-modal.component';
import { BrowserNavigationAwayGuardComponent } from './browser-navigation-away-guard/browser-navigation-away-guard.component';
import { ValidatorMessageComponent } from './reactive-validators/validator-message.component';
import { PayrollRedirectModalComponent } from './payroll-redirect-modal/payroll-redirect-modal.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { CanadapostValidatorModalComponent } from 'src/app/shared/canadapost-validator-modal/canadapost-validator-modal.component';
import { AutoSaveComponent } from './auto-save/auto-save.component';
import { SoftDeleteModalComponent } from 'src/app/shared/soft-delete-modal/soft-delete-modal.component';
import { SimpleModalComponent } from './simple-modal/simple-modal.component';
import { ButtonContinueComponent } from './button-continue/button-continue.component';
import { TabbingClickDirective } from 'src/app/shared/tabbing-click.directive';
import { HelpFooterComponent } from './help-footer/help-footer.component';

@NgModule({
    declarations: [
        ConfirmLeaveComponent,
        HelpTooltipComponent,
        HelpFooterComponent,
        StepperComponent,
        RedirectModalComponent,
        DeleteModalComponent,
        PayrollRedirectModalComponent,
        SimpleModalComponent,
        LoadingSpinnerComponent,
        SurveyPopupComponent,
        IdleUserModalComponent,
        BrowserNavigationAwayGuardComponent,
        ValidatorMessageComponent,
        CanadapostValidatorModalComponent,
        AutoSaveComponent,
        SoftDeleteModalComponent,
        ButtonContinueComponent,
        TabbingClickDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModalModule,
        NgxMaskModule.forChild(),
        TooltipModule.forRoot(),
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        ConfirmLeaveComponent,
        RedirectModalComponent,
        DeleteModalComponent,
        PayrollRedirectModalComponent,
        SimpleModalComponent,
        IdleUserModalComponent,
        HelpTooltipComponent,
        HelpFooterComponent,        
        StepperComponent,
        LoadingSpinnerComponent,
        SurveyPopupComponent,
        BrowserNavigationAwayGuardComponent,
        ValidatorMessageComponent,
        CanadapostValidatorModalComponent,
        AutoSaveComponent,
        SoftDeleteModalComponent,
        ButtonContinueComponent,
        TabbingClickDirective
    ]
})
export class SharedModule { }
