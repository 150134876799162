import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { TextFieldComponent } from './text-fields/text-field/text-field.component';
import { BaseFormComponent } from './base-form.component';
import { PhoneFieldComponent } from './number-fields/phone-field/phone-field.component';
import { TitleFieldComponent } from './dropdown-fields/title-field/title-field.component';
import { BooleanFieldComponent } from './radio-fields/boolean-field/boolean-field.component';
import { HorizontalBooleanFieldComponent } from './radio-fields/horizontal-boolean-field/horizontal-boolean-field.component';
import { NumberFieldComponent } from './number-fields/number-field/number-field.component';
import { CountryDropdownComponent } from './dropdown-fields/country-dropdown/country-dropdown.component';
import { ProvinceDropdownComponent } from './dropdown-fields/province-dropdown/province-dropdown.component';
import { PostalFieldComponent } from './text-fields/postal-field/postal-field.component';
import { VerticalRadioComponent } from './radio-fields/verical-radio/vertical-radio.component';
import { DateControlComponent } from './date-fields/date-control/date-control.component';
import { TextBoxComponent } from './text-box/text-box.component';
import { CommonFunctions } from '../common/common-functions';
import { DropdownFieldComponent } from './dropdown-fields/dropdown-field/dropdown-field.component';
import { StateDropdownComponent } from './dropdown-fields/state-dropdown/state-dropdown.component';
import { DatePickerComponent } from './date-fields/date-picker/date-picker.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PayrollFieldComponent } from './number-fields/payroll-fields/payroll-field.component';
import { CurrencyFormatter } from '../common/formatters/currency.formatter';
import { DatetimeFormatter } from '../common/formatters/datetime.formatter';
import { ProvinceStateDropdownComponent } from './dropdown-fields/province-state-dropdown/province-state-dropdown.component';
import { ClassificationSearchViewComponent } from './classification-view/classification-search-view/classification-search-view.component';
import { ClassificationSearchViewCoreComponent } from './classification-view/classification-search-view-core/classification-search-view-core.component';
import { ContactInfoCommonComponent } from './contact-info-common/contact-info-common.component';
import { PersonalOptionalProtectionInfoViewComponent } from './personal-optional-protection-info-view/personal-optional-protection-info-view.component';
import { BusinessNumberFieldComponent } from './number-fields/business-number-field/business-number-field.component';
import { ContactInfoCommonCoreComponent } from './contact-info-common-core/contact-info-common-core.component';
import { BusinessNumberFieldCoreComponent } from './number-fields/business-number-field-core/business-number-field-core.component';
import { ContactInfoCommonSoleProprietorComponent } from './contact-info-common-soleproprietor/contact-info-common-soleproprietor.component';


@NgModule({
  declarations: [
    BaseFormComponent,
    TextFieldComponent,
    PhoneFieldComponent,
    TitleFieldComponent,
    BooleanFieldComponent,
    HorizontalBooleanFieldComponent,
    NumberFieldComponent,
    CountryDropdownComponent,
    ProvinceDropdownComponent,
    StateDropdownComponent,
    ProvinceStateDropdownComponent,
    PostalFieldComponent,
    VerticalRadioComponent,
    DateControlComponent,
    DatePickerComponent,
    TextBoxComponent,
    DropdownFieldComponent,
    PayrollFieldComponent,
    ClassificationSearchViewComponent,
    ClassificationSearchViewCoreComponent,
    ContactInfoCommonComponent,
    ContactInfoCommonCoreComponent,
    ContactInfoCommonSoleProprietorComponent,
    PersonalOptionalProtectionInfoViewComponent,
    BusinessNumberFieldComponent,
    BusinessNumberFieldCoreComponent
  ],
  imports: [
    SharedModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    TextFieldComponent,
    PhoneFieldComponent,
    TitleFieldComponent,
    BooleanFieldComponent,
    HorizontalBooleanFieldComponent,
    NumberFieldComponent,
    CountryDropdownComponent,
    ProvinceDropdownComponent,
    StateDropdownComponent,
    ProvinceStateDropdownComponent,
    PostalFieldComponent,
    VerticalRadioComponent,
    DateControlComponent,
    DatePickerComponent,
    TextBoxComponent,
    DateControlComponent,
    DropdownFieldComponent,
    PayrollFieldComponent,
    ClassificationSearchViewComponent,
    ClassificationSearchViewCoreComponent,
    ContactInfoCommonComponent,
    ContactInfoCommonCoreComponent,
    ContactInfoCommonSoleProprietorComponent,
    PersonalOptionalProtectionInfoViewComponent,
    BusinessNumberFieldComponent,
    BusinessNumberFieldCoreComponent
  ],
  providers: [
    CommonFunctions,
    DatetimeFormatter,
    CurrencyFormatter
  ]
})

export class FormComponentsModule { }
