export class PayrollInfo {
    previousYear: PayrollInfoPeriod[];
    currentYear: PayrollInfoPeriod[];
    nextYear: PayrollInfoPeriod[];
}

export class PayrollInfoPeriod {
    id: string;
    year: number;
    quarterId: string;
    quarterName: string;
    startDate: Date;
    endDate: Date;
    payrollAmount: string;
    disable: boolean;
    required?: boolean;
    label1?: string;
    label2?: string;
}