import { Component, OnInit, Input, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ExitUrlService } from 'src/app/services/exit-url.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  subscriptions: Subscription[] = [];
  exitUrl: string;
  cssClass: string;
  constructor(
    private cdr: ChangeDetectorRef,
    private exitUrlService: ExitUrlService,
    private router: Router) {
      
  }

  ngOnInit() {
    this.subscriptions.push(this.exitUrlService.getExitUrl().subscribe(url => this.exitUrl = url));
  }
  onExit() {
    this.router.navigate(['/externalRedirect', { externalUrl: this.exitUrl }]);
  }
  ngAfterViewInit(): void {
    if(this.router.url.includes('corporation') || this.router.url.includes('landingpage')){
      this.cssClass = "container"
    } else {
      this.cssClass = "container-fluid"
    } 
    this.cdr.detectChanges();
  }
  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }
}
