import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExitUrlService {
  private exitUrl = new BehaviorSubject<string>('');
  constructor() { }
  getExitUrl() {
    return this.exitUrl.asObservable();
  }
  setExitUrl(url: string = '') {
    this.exitUrl.next(url);
  }
}
