export class Participant {
    constructor() {    }
    participationId?: string;
    regParticipation?: {
        participationTypeCode?: string;
        primaryRegistrantIndicator?: string
    };
    regLegalEntity?: {
        internetLegalEntity?: {
            legalEntityTypeCode?: string;
        };
        legalEntityAddress?: LegalEntityAddress[];
        item?: any;
    };
    item?: any;
}

export class LegalEntityAddress {
    constructor( ) { }
    sequenceNumber?: string;
    addressTypeCode?: string;
    streetLine1?: string;
    streetLine2?: string;
    city?: string;
    provinceCode?: string;
    country?: string;
    postalCode?: string;
    phoneAreaCode?: string;
    phoneNumber?: string;
    phoneExtension?: string;
    faxAreaCode?: string;
    faxNumber?: string;
    email?: string;
    locationTypeCode?: string;
    webPage?: string;
    canadaPostValidateCode?: string;
    isSamePhysicalAddress?: boolean;
}

export class BLEItem {
    constructor( ) {    }
    tradeName?: string;
    legalName?: string;
    incorporationDate?: string;
    contactRelationshipTypeCode?: string;
    contactName?: string;
    contactPhoneNumber?: string;
    contactDescription1?: string;
}

export class PLEItem {
    constructor( ) {    }
    personalTitleTypeCode?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    birthDate?: string;
    phoneNumber?: string;
    extension?: string;
    email?: string;
}
