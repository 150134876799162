<div>
    <div class="card p-0 border-0 mb-0">
        <div class="card-header p-0 border-0">
            <app-header></app-header>
        </div>
        <div class="card-body p-0 border-0">
           <div class="container-fluid">
            <router-outlet></router-outlet>
           </div>
        </div>
    </div>
</div>
<div>
    <app-footer></app-footer>
</div>

