import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { CommonFunctions } from '../common-functions';

@Injectable()
export class CurrencyFormatter {
    constructor(private commonFunctions: CommonFunctions) { }

    currencyFormat(value) {
        const temp = `${value}`.replace(/\,/g, '');
        return (new CurrencyPipe('en-CA')).transform(temp);
    }

    formatNumberToCurrency(currencyNumber: string) {
        let value = '';
        try {    
        value = currencyNumber;
        if (value && value.startsWith('$'))  {
            value = value.replace('$', ''); 
            value= value.trim();
        }      
        } catch {
        console.log('formatNumberToCurrency ex: currencyNumber=' + currencyNumber);
        value = '';
        }
        return value;
    }     
}
