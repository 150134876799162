import { UntypedFormGroup, AbstractControl, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class FormFunctions {
  constructor() { }
  addFormFields(fieldObj: any, formGroup: UntypedFormGroup) {
    for ( const fieldName in fieldObj ) {
      if ( fieldObj.hasOwnProperty(fieldName) ) {
        formGroup.addControl(fieldName, fieldObj[fieldName]);
      }
    }
  }

  removeFormFields(fieldObj: any, formGroup: UntypedFormGroup) {
    for ( const field of Object.keys(fieldObj)) {
      formGroup.removeControl(field);
    }
  }

  removeCleanFormFields(fieldObj: any, formGroup: UntypedFormGroup) {
    for ( const field of Object.keys(fieldObj)) {
      if ( !formGroup.controls[field] ) {
        return ;
      }
      formGroup.controls[field].setValue('');
      formGroup.controls[field].markAsPristine();
      formGroup.controls[field].markAsUntouched();
      formGroup.controls[field].updateValueAndValidity();
      formGroup.removeControl(field);
    }
  }

  addRemoveFormFields(addFieldObj: any[], removefieldObj: any[], formGroup: UntypedFormGroup) {
    addFieldObj.forEach( field => this.addFormFields(field, formGroup));
    removefieldObj.forEach(field => this.removeFormFields(field, formGroup));
  }

  addRemoveFormRequiredFormValidator(control: AbstractControl, value: any, key: any) {
    if (value && value === key) {
      control.setValidators(Validators.compose([Validators.required]));
    } else {
      control.setValidators(Validators.compose([]));
    }
    control.updateValueAndValidity();
  }

  // Used for checkbox control
  addRemoveFormRequiredTrueFormValidator(control: AbstractControl, value: any, key: any) {
    if (value && value === key) {
      control.setValidators(Validators.compose([Validators.requiredTrue]));
    } else {
      control.setValidators(Validators.compose([]));
    }
    control.updateValueAndValidity();
  }

  addRemoveFormValidators(control: AbstractControl, value: any, key: any, validators: ValidatorFn[] ) {
    if (value && value === key) {
      control.setValidators(Validators.compose(validators));
    } else {
      control.clearValidators();
    }
    control.updateValueAndValidity();
  }

  findInvalidControls(fg: UntypedFormGroup) {
    const invalid = [];
    const controls = fg.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid;
  }
}
