export class UserChoice {
    constructor() {    }
    employeeType: string;
    currentPartner: string;
    currentPartnerType: string;
    contactTitle: string;
    contactNameFirst: string;
    contactNameMiddle: string;
    contactNameLast: string;
    contactRelation: string;
    contactTelephone: string;
    contactEmail: string;
    contactEmailConfirm: string;    
}
