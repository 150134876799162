import { Injectable,  Renderer2,  RendererFactory2} from '@angular/core';
import { environment } from 'src/environments/environment';

export enum CanadaPostAddressSources {  direct,  apim,}

@Injectable({
  providedIn: 'root',
})

export class CanadaPostApiService {
  private renderer: Renderer2;
  private cssUrl = environment.canadaPostCssUrl;
  private scriptSrc =environment.canadaPostScriptSrc;

  constructor( rendererFactory: RendererFactory2 ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  async loadScript(): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = this.scriptSrc;

      // Attach an onload event listener to the script
      script.onload = () => {
        console.log('Script loaded successfully');
        // Resolve the promise when the script is loaded
        resolve();
      };

      // Attach an onerror event listener to handle script loading errors
      script.onerror = () => {
        console.error('Error loading script');
        // Reject the promise in case of an error
        reject();
      };

      // Append the script to the DOM
      this.renderer.appendChild(document.body, script);
    });
  }

  async loadCss(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const link = this.renderer.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = this.cssUrl;
        link.onload = () => resolve();
        link.onerror = (error: Error) => reject(error);
        this.renderer.appendChild(document.head, link);
    });
  }

  async load(id: string): Promise<void> {
    return Promise.all([this.loadScript(), this.loadCss()]).then(() => Promise.resolve());
  }
}
