import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppInsightsService } from 'src/app/core/appinsights.service';
import { Router } from '@angular/router';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { AppState } from 'src/app/store/reducers/app.state';
import * as rootSelector from 'src/app/store/selectors/app.selectors';
import { CommonFunctions } from 'src/app/shared/common/common-functions';
import { Store, select } from '@ngrx/store';


@Component({
  selector: 'app-confirm-leave-modal',
  templateUrl: './confirm-leave.component.html',
  styleUrls: ['./confirm-leave.component.scss']
})
export class ConfirmLeaveComponent {
  constructor(public activeModal: NgbActiveModal,
    private appInsightService: AppInsightsService,    
    private commonFunctions: CommonFunctions,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private rootStore$: Store<AppState>) { }

  idamEmail: string;
  startSafeQuestionnaireLeaveConfirm: boolean = false;
  startSafeActionPlanLeaveConfirm: boolean = false;
  confirmExitButtonText: string = 'Exit application';
  cancelExitButtonText: string = 'Return to application';

  ngOnInit() {
    //extract out the IDAM email from root store
    this.rootStore$.pipe(select(rootSelector.getAppState)).subscribe(
      rootStore => {
        if (rootStore && rootStore.idam && rootStore.idam.email) {
          this.idamEmail = rootStore.idam.email as string;
        }
      });

    if (this.router && this.router.url) {
      if (this.router.url.toUpperCase().includes('/QUESTIONNAIRE'))
        this.startSafeQuestionnaireLeaveConfirm = true;
      else if (this.router.url.toUpperCase().includes('/ACTIONPLAN'))
        this.startSafeActionPlanLeaveConfirm = true;
    }
    if (this.startSafeActionPlanLeaveConfirm || this.startSafeQuestionnaireLeaveConfirm) {
      this.confirmExitButtonText = 'Yes, take me to my confirmation page';
      this.cancelExitButtonText = 'No, cancel';
    }
  }
  //Exit application btn clicked              
  okClicked(): void {
    this.appInsightService.logEvent('IDAM email ' + this.idamEmail + ' at ' + this.router.url + ' clicked exit application button from exit modal');
    this.sessionStorageService.setSkipRefreshPagePopupInSessionStorage();
    this.activeModal.close(true);

    if ((this.startSafeActionPlanLeaveConfirm)) {
      this.postClick();
      this.logURLClick();
    }
    if (this.startSafeActionPlanLeaveConfirm || this.startSafeQuestionnaireLeaveConfirm) {
      this.navigateToConfirmationPage();
    }
    else if (this.router && this.router.url && !this.router.url.includes('registration-dashboard')) {
      this.router.navigateByUrl('registration-dashboard');
    }
  }
  //Return to application btn clicked
  cancelClicked(): void {
    this.appInsightService.logEvent('IDAM email ' + this.idamEmail + ' at ' + this.router.url + ' clicked return to application button from exit modal');

    this.activeModal.dismiss();
  }

  navigateToConfirmationPage(): void {
    var rootURL: string = '';

    rootURL = this.commonFunctions.getRootUrlByRouterUrl(this.router.url);
    if (rootURL) {
      this.router.navigateByUrl(rootURL + '/applicationconfirm');
    } else {
      this.router.navigateByUrl('homeowner/application-confirm');
    }
  }

  postClick() {    
  }

  logURLClick() {
   
  }

}

