import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import * as fromContactInfo from 'src/app/store/feature-stores/contact-info-store';
import { CommonFunctions } from 'src/app/shared/common/common-functions';
import { FirmType, LegalEntityCode } from 'src/app/shared/common/common-constants';
import * as wsValidators from 'src/app/shared/reactive-validators/fields.validator';
import { ContactInfoForm } from 'src/app/store/feature-stores/contact-info-store/models';

@Injectable()
export class ContactInfoCommonFunctions {
    constructor(private commonFunctions: CommonFunctions) { }

  public createContactForm(fb: UntypedFormBuilder, requiredRelatioMsg: string) {
    const contactForm = fb.group({
      firmTypeRelationship: ['', wsValidators.requiredValidator(requiredRelatioMsg)],
      title: [''],
      legalFirstName: ['', [wsValidators.nameValidator(true, 'legal first')]],
      legalMiddleName: ['', [wsValidators.nameValidator(false, 'middle')]],
      legalLastName: ['', [wsValidators.nameValidator(true, 'legal last')]],
      email: ['', [wsValidators.emailValidator(true)]],
      phoneNumber: ['', [wsValidators.phoneNumberValidator(true)]],
    });
    return contactForm;
  }

  public generateFormFromPayload(iReg: any, contactForm: UntypedFormGroup) {
    if (iReg.participation != null) {
      contactForm.get('firmTypeRelationship').setValue(
        (iReg.participation.length === 0 || iReg.participation[0].regLegalEntity.item.contactRelationshipTypeCode == null) ?
        '' : iReg.participation[0].regLegalEntity.item.contactRelationshipTypeCode);
    }
    contactForm.get('title').setValue(iReg.userChoice.contactTitle || '');
    contactForm.get('legalFirstName').setValue(this.commonFunctions.trimLeadingAndTrailingSpace(iReg.userChoice.contactNameFirst));
    contactForm.get('legalMiddleName').setValue(iReg.userChoice.contactNameMiddle);
    contactForm.get('legalLastName').setValue(this.commonFunctions.trimLeadingAndTrailingSpace(iReg.userChoice.contactNameLast));
    contactForm.get('email').setValue(iReg.userChoice.contactEmail);
    contactForm.get('phoneNumber').setValue(iReg.userChoice.contactTelephone);
  }

  public contactInfoPayload(contactForm: UntypedFormGroup, firmType: FirmType, url?: string) {
    const BLEParticipant = this.buildBLE(contactForm.value, firmType);
    const userChoice = this.buildUserChoice(contactForm.value);
    const payload = {
      firmType,
      participation: [BLEParticipant],
      userChoice,
      url
    };
    return payload;
  }

  private buildBLE(contactInfoForm: ContactInfoForm, firmType: FirmType): fromContactInfo.Model.Participant {
    const participant = new fromContactInfo.Model.Participant();
    const name = this.commonFunctions.concatContactName(contactInfoForm.legalFirstName, contactInfoForm.legalMiddleName,
      contactInfoForm.legalLastName);

    // '0' refers to BLE, it is a constant
    participant.participationId = '0';
    participant.regParticipation = {
      participationTypeCode: 'insrd',
      primaryRegistrantIndicator: 'Y'
    };

    // Instantiate regLegalEntity
    participant.regLegalEntity = {};
    participant.regLegalEntity.internetLegalEntity = {};
    participant.regLegalEntity.internetLegalEntity.legalEntityTypeCode = LegalEntityCode.business;
    participant.regLegalEntity.legalEntityAddress = [];

    const legalEntityAddress = new fromContactInfo.Model.LegalEntityAddress();
    legalEntityAddress.email = contactInfoForm.email;
    participant.regLegalEntity.legalEntityAddress.push(legalEntityAddress);

    const item = new fromContactInfo.Model.BLEItem();
    item.contactName = this.commonFunctions.trimLeadingAndTrailingSpace(name);
    item.contactPhoneNumber = `${contactInfoForm.phoneNumber}`;
    item.contactRelationshipTypeCode = contactInfoForm.firmTypeRelationship;
    participant.regLegalEntity.item = item;
    return participant;
  }

  private buildUserChoice(contactInfoForm: ContactInfoForm): fromContactInfo.Model.UserChoice {
    const userChoice = new fromContactInfo.Model.UserChoice();
    userChoice.contactTitle = contactInfoForm.title ? contactInfoForm.title : '';
    userChoice.contactNameFirst = contactInfoForm.legalFirstName;
    userChoice.contactNameMiddle = contactInfoForm.legalMiddleName ? contactInfoForm.legalMiddleName : '';
    userChoice.contactNameLast = contactInfoForm.legalLastName;
    userChoice.contactRelation = this.commonFunctions.formatContactRelation(contactInfoForm.firmTypeRelationship);
    userChoice.contactTelephone = `${contactInfoForm.phoneNumber}`;
    userChoice.contactEmail = contactInfoForm.email;
    userChoice.contactEmailConfirm = contactInfoForm.email;
    return userChoice;
  }

  public getRelationshipData(firmType: FirmType) {
    let relationshipList = [];
    switch (firmType) {
      case FirmType.proprietor:
        relationshipList =   [
          { code: '', display: ' -- Select -- ', value: '' },
          { code: 'a', display: 'Accountant', value: 'a' },
          { code: 'ce', display: 'Company employee', value: 'ce' },
          { code: 'fm', display: 'Family member', value: 'fm' },
          { code: 'prop', display: 'I am the proprietor', value: 'prop' },
          { code: 'l', display: 'Lawyer', value: 'l' },
          { code: 's', display: 'Spouse', value: 's' },
          { code: 'o', display: 'Other', value: 'o' }
        ];
        break;
      case FirmType.partner:
        relationshipList =   [
          { code: '', display: ' -- Select -- ', value: '' },
          { code: 'a', display: 'Accountant', value: 'a' },
          { code: 'ce', display: 'Company employee', value: 'ce' },
          { code: 'fm', display: 'Family member', value: 'fm' },
          { code: 'p', display: 'I am a partner', value: 'p'},
          { code: 'l', display: 'Lawyer', value: 'l' },
          { code: 's', display: 'Spouse', value: 's' },
          { code: 'o', display: 'Other', value: 'o' },
        ];
        break;
      case FirmType.limitedCompany:
      case FirmType.association:
      case FirmType.band:
      case FirmType.church:
      case FirmType.cooperative:
      case FirmType.society:
      case FirmType.union:
        relationshipList =   [
          { code: '', display: ' -- Select -- ', value: '' },
          { code: 'a', display: 'Accountant', value: 'a' },
          { code: 'ce', display: 'Company employee', value: 'ce' },
          { code: 'fm', display: 'Family member', value: 'fm' },
          { code: 'l', display: 'Lawyer', value: 'l' },
          { code: 'sh', display: 'Shareholder', value: 'sh' },
          { code: 'o', display: 'Other', value: 'o' }
        ];
        break;
    }
    return relationshipList;
  }

}
