import { Component } from '@angular/core';

@Component({
  selector: 'app-external-redirect',
  templateUrl: './external-redirect.component.html'
})
export class ExternalRedirectComponent {

  constructor() { 
    // need empty Component 
  }
}
