import * as AppActions from '../actions/app.actions';
import { AppState, initialState } from './app.state';
import { createReducer, on, Action } from '@ngrx/store';

export const appReducer = createReducer(
    initialState,
    on(AppActions.InitApps, state => ({
        ...state,
        internetRegistration: {
            email: (state.idam.email) ?? "test@test.com",
            userChoice: state.idam.userChoice
        },
        exceptionReviewQuestion: {},
        selectedClassificationUnitInfo: {},
        classificationPredictionInfo: {},
        isWorkerPayrollRequired: {},
        popEstimatedMonthlyPremium: {},
        userSearchKeyWord: '',
        currentDateTime: '',
        lastSavedDateTimeLocal: null,
        loaders: []
    })),
    on(AppActions.LoadApps, (state, {payload}) => ({
        ...state,
        loaders: [...state.loaders, payload]
    })),
    on(AppActions.LoadAppsSuccess, (state, {payload}) => ({
        ...state,
        loaders: [...removeLoader(state.loaders, payload)]
    })),
    on(AppActions.SaveAppState, (state, {payload}) => ({
        ...state,
        internetRegistration: {
            ...state.internetRegistration,
            ...payload,
            email: state.internetRegistration.email && state.internetRegistration.email != '' ? 
                   state.internetRegistration.email : state.idam.email
        },
        lastSavedDateTimeLocal: new Date()
    })),
    on(AppActions.SaveAllAppState, (state, {payload}) => ({
        ...state,
        ...payload
    })),
    on(AppActions.SaveClassificationAppState, (state, {payload}) => ({
        ...state,
        internetRegistration: {
            ...state.internetRegistration,
            ...payload.internetRegistration},
        exceptionReviewQuestion: payload.exceptionReviewQuestion,
        selectedClassificationUnitInfo: payload.selectedClassificationUnitInfo,
        lastSavedDateTimeLocal: new Date()
    })),
    on(AppActions.LoadClassificationPredictionInfoSuccess, (state, {payload}) => ({
        ...state,
        classificationPredictionInfo: {
            searchResults: payload?.searchResults,
            searchPayload: payload?.searchPayload
        },
        userSearchKeyWord: payload?.userSearchKeyWord,
        lastSavedDateTimeLocal: new Date()
    })),
    on(AppActions.LoadClassificationBySearchCriteriaSuccess, (state, {payload}) => ({
        ...state,
        classificationPredictionInfo: {
            searchResults: payload?.searchResults,
            searchPayload: payload?.searchPayload
        },       
        lastSavedDateTimeLocal: new Date()
    })),
    on(AppActions.SavePOPCoverageAppState, (state, {payload}) => ({
        ...state,
        internetRegistration: {
            ...state.internetRegistration,
            ...payload.internetRegistration},
        popEstimatedMonthlyPremium: payload.popEstimatedMonthlyPremium,
        lastSavedDateTimeLocal: new Date()
    })),
    on(AppActions.SaveUserChoiceAppState, (state, {payload}) => ({
        ...state,
        internetRegistration: {
            ...state.internetRegistration,
            userChoice: {
                ...state.internetRegistration.userChoice,
                ...payload
            }
        },
        lastSavedDateTimeLocal: new Date()
    })),
    on(AppActions.LoadIsWorkerPayrollRequiredSuccess, (state, {payload}) => ({
        ...state,
        isWorkerPayrollRequired: payload
    })),
    on(AppActions.SaveAppStateWithIsWorkerPayrollRequired, (state, {payload}) => ({
        ...state,
        internetRegistration: {
            ...state.internetRegistration,
            ...payload.internetRegistration},       
        isWorkerPayrollRequired: payload.isWorkerPayrollRequired,
        lastSavedDateTimeLocal: new Date()             
    })),    
    on(AppActions.SaveUserSearchKeyWordAppState, (state, {payload}) => ({
        ...state,
        internetRegistration: {
            ...state.internetRegistration,
            ...payload.internetRegistration},
        userSearchKeyWord: payload.userSearchKeyWord,
        lastSavedDateTimeLocal: new Date()
    })),
    on(AppActions.LoadCurrentDateTimeSuccess, (state, {payload}) => ({
        ...state,
        currentDateTime: payload
    })),
    on(AppActions.InitAppsSuccess, (state, {payload}) => ({
        ...initialState
    })),
    on(AppActions.LoadInternetRegistrationByIRNumberSuccess, (state, {payload}) => ({
        ...state,
        internetRegistration: payload
    })),
);

export function reducer(state: AppState | undefined, action: Action) {
    return appReducer(state, action);
}

function removeLoader(loaders: any[], loader: any): any[] {
    const newLoaders = loaders.slice();
    for ( let i = 0; i < newLoaders.length; i++) {
        if ( newLoaders[i] === loader) {
            newLoaders.splice(i, 1);
        }
    }
    return newLoaders;
}
