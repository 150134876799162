import { Action, createAction, props } from '@ngrx/store';

export const LoadApps = createAction('[App] Load Apps', props<{payload: any}>());
export const InitApps = createAction('[App] Init Apps');
export const SaveAllAppState = createAction('[App] Save All Apps', props<{payload: any}>());
export const SaveAppState = createAction('[App] Save Apps', props<{payload: any}>());
export const SaveClassificationAppState = createAction('[App] Save Apps with Classification', props<{payload: any}>());
export const SavePOPCoverageAppState = createAction('[App] Save Apps with POPCoverage', props<{payload: any}>());
export const SaveUserChoiceAppState = createAction('[App] Save Apps with UserChoice', props<{payload: any}>());
export const LoadAppsSuccess = createAction('[App] Load Apps Success', props<{payload: any}>());
export const SaveAppsSuccess = createAction('[App] Save Apps Success', props<{payload: any}>());
export const InitAppsSuccess = createAction('[App] Init Apps Success', props<{payload: any}>());
export const LoadIsWorkerPayrollRequired = createAction('[App] Load IsWorkerPayrollRequired', props<{payload: any}>());
export const LoadIsWorkerPayrollRequiredSuccess =
 createAction('[App] Load IsWorkerPayrollRequired Success', props<{payload: any}>());
export const SaveUserSearchKeyWordAppState = createAction('[App] Save Apps with User Search KeyWord', props<{payload: any}>());

export const LoadCurrentDateTime = createAction('[App] Load CurrentDateTime');
export const LoadCurrentDateTimeSuccess = createAction('[App] Load CurrentDateTime Success', props<{payload: any}>());
export const LoadCurrentDateTimeE2E = createAction('[App] Load CurrentDateTime E2E');
 
 export const LoadInternetRegistrationByIRNumberE2E =
 createAction('[App] Load InternetRegistration By IRNumber E2E', props<{payload: any}>());

export const LoadInternetRegistrationByIRNumberSuccess =
    createAction('[App] Load InternetRegistration By IRNumber Success', props<{payload: any}>());

export const LoadClassificationPredictionInfo = createAction('[App] Load ClassificationPredictionInfo', props<{payload: any}>());
export const LoadClassificationPredictionInfoSuccess =
 createAction('[App] Load ClassificationPredictionInfo Success', props<{payload: any}>());

 export const LoadClassificationBySearchCriteria = createAction('[App] Load LoadClassificationBySearchCriteria', props<{payload: any}>());

 export const LoadClassificationBySearchCriteriaSuccess =
 createAction('[App] Load LoadClassificationBySearchCriteria Success', props<{payload: any}>());

 export const SaveAppStateWithIsWorkerPayrollRequired = createAction('[App] Save App State With IsWorkerPayrollRequired', props<{payload: any}>());
 