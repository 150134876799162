import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import * as Actions from './actions/app.actions';
import * as Selectors from './selectors/app.selectors';
import * as State from './reducers/app.state';
import * as Reducer from './reducers/app.reducer';
import * as Services from './services/app.service';
import { InitApps } from './actions/app.actions';


export const reducers: ActionReducerMap<State.State> = {
  appstate: Reducer.reducer
};

// This is middleware for reducers
export const metaReducers: MetaReducer<State.State>[] = [clearAllStateReducer];

export function clearAllStateReducer(reducer: ActionReducer<State.State>): ActionReducer<State.State> {
  return (state, action) => {
    if (action.type === InitApps.type) {
      state = { appstate: state.appstate };
    }

    return reducer(state, action);
  };
}

export {
  Actions,
  Selectors,
  State,
  Reducer,
  Services
};
