import { Injectable } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { FormFunctions } from 'src/app/shared/form-components/form-functions';
import { ShareholderInfoPayload } from 'src/app/store/feature-stores/contact-info-store/models';
import { CommonFunctions } from 'src/app/shared/common/common-functions';
import { DatetimeFormatter } from 'src/app/shared/common/formatters/datetime.formatter';
import { FirmType } from 'src/app/shared/common/common-constants';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import * as RegValidators from 'src/app/shared/reactive-validators/fields.validator';
import { ErrorMessage } from 'src/app/shared/reactive-validators/error.message';
import * as fieldValidators from 'src/app/shared/reactive-validators/fields.validator';
import { environment } from 'src/environments/environment';

declare namespace pca {
  export enum fieldMode {
    SEARCH,
    POPULATE,
    COUNTRY
  }
  class Address {
    constructor(fields:any, options: any);
    listen(eventType: string, callback: (control: any) => void): void;
  }
}

@Injectable()
export class ShareholdersInfoCommonFunctions {    
    accountType: string = "other";
    firmType: string = FirmType.limitedCompany;
    plePrefix = 'ple_';
    blePrefix = 'ble_';  
    personalShareholderType = 'ple';
    businessShareholderType = 'ble';
    shareholdersInfoFormFormGroup: UntypedFormGroup;
    personalShareholderFormFormGroup: UntypedFormGroup;
    businessShareholderFormFormGroup: UntypedFormGroup;    

    bleFields = {      
      ble_businessNumberParticipant: new UntypedFormControl('', [fieldValidators.craBusinessNumValidator(true, this.sessionStorage)]),
      ble_participationId: new UntypedFormControl(''),
      ble_businessLegalName: new UntypedFormControl('', [fieldValidators.requiredValidator(ErrorMessage.legalNameEmptyMsg)]),
      ble_businessTradeName: new UntypedFormControl('', []),
      ble_phoneNumber: new UntypedFormControl('', [fieldValidators.phoneNumberValidator(true)]),   
      ble_email: new UntypedFormControl('', [fieldValidators.emailValidator(true)]),
      ble_workSafeBCReferenceNumber: new UntypedFormControl(''),
      ble_isMailingAddressSame: new UntypedFormControl('', [Validators.required])
    };

    ble_addressFields = {
      ble_addressLine1: new UntypedFormControl('', [fieldValidators.addressValidator(true) ]),
      ble_addressLine2: new UntypedFormControl('', [fieldValidators.addressValidator()]),
      ble_country: new UntypedFormControl('CA', []),
      ble_province: new UntypedFormControl('BC', []),
      ble_state: new UntypedFormControl(''),
      ble_city: new UntypedFormControl('', [fieldValidators.cityValidator(true)]),
      ble_postalCode: new UntypedFormControl('', []),                      
      ble_addressType: new UntypedFormControl('', [fieldValidators.requiredValidator(ErrorMessage.addressTypeValidation.emptyMsg)])
    };

    ple_addressFields = {
      ple_addressLine1: new UntypedFormControl('', [fieldValidators.addressValidator(true) ]),
      ple_addressLine2: new UntypedFormControl('', [fieldValidators.addressValidator()]),
      ple_country: new UntypedFormControl('CA', []),
      ple_province: new UntypedFormControl('BC', []),
      ple_state: new UntypedFormControl(''),
      ple_city: new UntypedFormControl('', [fieldValidators.cityValidator(true)]),
      ple_postalCode: new UntypedFormControl('', []),               
      ple_addressType: new UntypedFormControl('', [fieldValidators.requiredValidator(ErrorMessage.addressTypeValidation.emptyMsg)]) 
    };
    
    pleFields = {
      ple_participationId: new UntypedFormControl(''),
      ple_title: new UntypedFormControl(''),
      ple_legalFirstName: new UntypedFormControl('', [fieldValidators.nameValidator(true, 'legal first')]),
      ple_legalMiddleName: new UntypedFormControl('', [fieldValidators.nameValidator(false, 'middle')]),
      ple_legalLastName: new UntypedFormControl('', [fieldValidators.nameValidator(true, 'legal last')]),
      ple_phoneNumber: new UntypedFormControl('', [fieldValidators.phoneNumberValidator(true)]),   
      ple_email: new UntypedFormControl('', [fieldValidators.emailValidator(true)]), 
      ple_dateOfBirth: new UntypedFormControl({ month: 0, date: 0, year: 0 }, [Validators.required]),
      ple_isMailingAddressSame: new UntypedFormControl('', [Validators.required])
    };

    businessDetailsFields = {
      numberOfShareholders: new UntypedFormControl('', []),   
      addDetailsOfAnotherShareholder: new UntypedFormControl(''),
      typeOfShareholder: new UntypedFormControl(''),
      hasOperateOtherBusiness: new UntypedFormControl(''),
      workSafeBCAccountDesc: new UntypedFormControl('', [RegValidators.requiredValidator(ErrorMessage.shareholderWorkSafeBCAccountDescInvalidMsg, 4)]),      
      hasWorkingRelationshipWithOtherBusiness: new UntypedFormControl(''),
      workingRelationshipDesc: new UntypedFormControl('', [RegValidators.requiredValidator(ErrorMessage.shareholderWorkingRelationshipDescInvalidMsg, 4)]),
      search: new UntypedFormControl('')
    };
    
    constructor( 
        private commonFunctions: CommonFunctions,
        private formFunctions: FormFunctions,
        private datetimeFormatter: DatetimeFormatter,
        private sessionStorage: SessionStorageService) {
    
    }

    public createShareholdersInfoForm(shareholdersInfoForm: UntypedFormGroup, personalShareholderForm: UntypedFormGroup, businessShareholderForm: UntypedFormGroup) {  
      try {       
        this.shareholdersInfoFormFormGroup = shareholdersInfoForm;     
        this.personalShareholderFormFormGroup = personalShareholderForm;  
        this.businessShareholderFormFormGroup = businessShareholderForm;  
        this.formFunctions.addFormFields(this.businessDetailsFields, shareholdersInfoForm);
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] createShareholdersInfoForm ex' + ex.message);
      } 
    }

    public addPleInfoForm(formGroup: UntypedFormGroup) {  
      try { 
        this.formFunctions.addFormFields(this.pleFields, formGroup); 
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] addPleInfoForm ex' + ex.message);
      } 
    }

    public removePleInfoForm(formGroup: UntypedFormGroup) {  
      try {         
        this.formFunctions.removeFormFields(this.pleFields, formGroup); 
        this.formFunctions.removeFormFields(this.ple_addressFields, formGroup);
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] removePleInfoForm ex' + ex.message);
      } 
    }

    public addPleAddress(formGroup: UntypedFormGroup) {      
      try {       
        this.formFunctions.addFormFields(this.ple_addressFields, formGroup); 
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] addPleAddress ex' + ex.message);
      }              
    }

    public removePleAddress(formGroup: UntypedFormGroup) {      
      try {       
        this.formFunctions.removeFormFields(this.ple_addressFields, formGroup);  
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] removePleAddress ex' + ex.message);
      }              
    }   


    public addBleInfoForm(formGroup: UntypedFormGroup) {  
      try {               
        this.formFunctions.addFormFields(this.bleFields, formGroup); 
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] addBleInfoForm ex' + ex.message);
      } 
    }      

    public removeBleInfoForm(formGroup: UntypedFormGroup) {  
      try {               
        this.formFunctions.removeFormFields(this.bleFields, formGroup); 
        this.formFunctions.removeFormFields(this.ble_addressFields, formGroup);
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] removeBleInfoForm ex' + ex.message);
      } 
    }

   
    public addBleAddress(formGroup: UntypedFormGroup) {      
      try {        
        this.formFunctions.addFormFields(this.ble_addressFields, formGroup);       
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] addBleAddress ex' + ex.message);
      }              
    }
    
    public removeBleAddress(formGroup: UntypedFormGroup) {      
      try {        
        this.formFunctions.removeFormFields(this.ble_addressFields, formGroup);  
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] removeBleAddress ex' + ex.message);
      }              
    }
    
    public initFromPayload_ple(formGroup: UntypedFormGroup, participant: any) {    
      try {
        const prefix = this.plePrefix;   
        if (participant && participant.regLegalEntity)     {
          const legalEntity = participant.regLegalEntity;
          const item = legalEntity.item; 
          this.setFormValue(formGroup, prefix + 'participationId', participant.participationId);
          this.setFormValue(formGroup, prefix + 'title', item.personalTitleTypeCode);
          this.setFormValue(formGroup, prefix + 'legalFirstName', item.firstName);
          this.setFormValue(formGroup, prefix + 'legalMiddleName', item.middleName);
          this.setFormValue(formGroup, prefix + 'legalLastName', item.lastName);
          this.setFormValue(formGroup, prefix + 'dateOfBirth', this.datetimeFormatter.dateObjectFormat(item.birthDate));
          
          if (legalEntity.legalEntityAddress && legalEntity.legalEntityAddress[0]) {
            const addr = legalEntity.legalEntityAddress[0];
            const isSamePhysicalAddress = legalEntity.legalEntityAddress[0].isSamePhysicalAddress ? 'Y' : 'N';          
            this.setFormValue(formGroup, prefix + 'phoneNumber', this.commonFunctions.combineAreaCodeAndPhoneNumber(addr.phoneAreaCode, addr.phoneNumber));                    
            this.setFormValue(formGroup, prefix + 'email', addr.email);
            this.setFormValue(formGroup, prefix + 'isMailingAddressSame', isSamePhysicalAddress);           

            if ( isSamePhysicalAddress =='N' ) {   
              this.addPleAddress(formGroup);        
              this.initFromPayload_address(prefix, formGroup, addr);
            } else {
              this.removePleAddress(formGroup);        
            }
          }          
        } 
        
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] initFromPayload_ple ex' + ex.message);
      }   
    }

    public initFromPayload_ble(formGroup: UntypedFormGroup, participant: any, eligibility: any) {
      try {
        const prefix = this.blePrefix; 
        if (participant && participant.regLegalEntity) {
          const legalEntity = participant.regLegalEntity;
          const item = legalEntity.item;
          
          this.setFormValue(formGroup, prefix + 'participationId', participant.participationId);          
          this.setFormValue(formGroup, prefix + 'businessNumberParticipant', item.businessNumberParticipant); 
          this.setFormValue(formGroup, prefix + 'businessLegalName', item.legalName);
          this.setFormValue(formGroup, prefix + 'businessTradeName', item.tradeName);          
          this.setFormValue(formGroup, prefix + 'workSafeBCReferenceNumber', item.wcbReferenceNumberParticipant);
          
          if (legalEntity.legalEntityAddress && legalEntity.legalEntityAddress[0]) {
            const addr = legalEntity.legalEntityAddress[0];
            this.setFormValue(formGroup, prefix + 'phoneNumber', this.commonFunctions.combineAreaCodeAndPhoneNumber(addr.phoneAreaCode, addr.phoneNumber));                    
            this.setFormValue(formGroup, prefix + 'email', addr.email);          
            const isSamePhysicalAddress = legalEntity.legalEntityAddress[0].isSamePhysicalAddress ? 'Y' : 'N'; 
            this.setFormValue(formGroup, prefix + 'isMailingAddressSame', isSamePhysicalAddress);
    
            if ( isSamePhysicalAddress =='N' ) {   
              this.addBleAddress(formGroup);        
              this.initFromPayload_address(prefix, formGroup, addr);           
            } else {
              this.removeBleAddress(formGroup);        
            }
          }          
        } 
                
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] initFromPayload_ble ex' + ex.message);
      }      
    }

    public initFromPayload_address(prefix: string, formGroup: UntypedFormGroup, addr: any) {    
      try {       
        this.setFormValue(formGroup, prefix + 'addressLine1', addr.streetLine1);
        this.setFormValue(formGroup, prefix + 'addressLine2', addr.streetLine2);
        this.setFormValue(formGroup, prefix + 'country', addr.country);
        this.setFormValue(formGroup, prefix + 'city', addr.city);
        this.setFormValue(formGroup, prefix + 'province', addr.provinceCode);
        this.setFormValue(formGroup, prefix + 'state', addr.provinceCode);
        this.setFormValue(formGroup, prefix + 'postalCode', addr.postalCode);            
        this.setFormValue(formGroup, prefix + 'addressType', addr.locationTypeCode);        
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] initFromPayload_address ex' + ex.message);
      }   
    }
    
    public generatePayload_ple(formGroup: UntypedFormGroup): any {   
      let payload;       
      try {
        const prefix = this.plePrefix;  
        const participationId = this.getFormValue(formGroup, prefix + 'participationId');
        const birthdate = this.getBirthday(this.getFormValue(formGroup, prefix + 'dateOfBirth'));
        const isSamePhysicalAddress = this.getFormValue(formGroup, prefix + 'isMailingAddressSame') === 'Y' ? true : false;
        const firstName = this.commonFunctions.trimLeadingAndTrailingSpace(this.getFormValue(formGroup, prefix + 'legalFirstName'));
        const middleName = this.commonFunctions.trimLeadingAndTrailingSpace(this.getFormValue(formGroup, prefix + 'legalMiddleName'));
        const lastName = this.commonFunctions.trimLeadingAndTrailingSpace(this.getFormValue(formGroup, prefix + 'legalLastName'));
        const tempPhone =  this.getFormValue(formGroup, prefix + 'phoneNumber');
        const phoneAreaCode = this.getPhoneAreaCode(tempPhone);
        const phoneNumber = this.getPhoneNumber(tempPhone);
        const mailingAddress = this.generatePayload_getAddress(prefix, formGroup, isSamePhysicalAddress);       
        const email = this.getFormValue(formGroup, prefix + 'email');

        const shareholderPayload: ShareholderInfoPayload = {
            activeShareHolderCount: this.getNumberOfShareholders(),
            personalShareholders: [{
              participationId: participationId,
              firstName: firstName,
              middleName: middleName,
              lastName: lastName,
              birthDate: birthdate,
              phoneAreaCode: phoneAreaCode,
              phoneNumber: phoneNumber,
              phoneExtension: '',
              email: email,
              isOwnerOrShareholder: false,
              mailingAddress: mailingAddress
            }]        
          };    

        payload = {
          shareholderinfo: shareholderPayload,
          accountType: this.accountType,
          firmType: this.firmType
        };    
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] generatePayload_ple ex' + ex.message);
      }
      return payload;
    }

    public generatePayload_ble(formGroup: UntypedFormGroup): any {   
      let payload;       
      try {
        const prefix = this.blePrefix;  
        const participationId = this.getFormValue(formGroup, prefix + 'participationId');      
        const businessNumberParticipant = this.commonFunctions.trimLeadingAndTrailingSpace(this.getFormValue(formGroup, prefix + 'businessNumberParticipant'));  
        const legalName = this.commonFunctions.trimLeadingAndTrailingSpace(this.getFormValue(formGroup, prefix + 'businessLegalName'));
        const tradeName = this.commonFunctions.trimLeadingAndTrailingSpace(this.getFormValue(formGroup, prefix + 'businessTradeName'));
        const tempPhone =  this.getFormValue(formGroup, prefix + 'phoneNumber');
        const phoneAreaCode = this.getPhoneAreaCode(tempPhone);
        const phoneNumber = this.getPhoneNumber(tempPhone);
        const email = this.getFormValue(formGroup, prefix + 'email');
        const workSafeBCReferenceNumber = this.getFormValue(formGroup, prefix + 'workSafeBCReferenceNumber');

        const isSamePhysicalAddress = this.getFormValue(formGroup, prefix + 'isMailingAddressSame') === 'Y' ? true : false;
        const mailingAddress = this.generatePayload_getAddress(prefix, formGroup, isSamePhysicalAddress);

        const confirmLegalNameDate = this.getFormValue(formGroup, 'confirmLegalNameDate');
        let legalNameConfirm= '';
        if (confirmLegalNameDate ==='Y') {          
          legalNameConfirm = legalName; 
        }

        const shareholderPayload: ShareholderInfoPayload = {
            activeShareHolderCount: this.getNumberOfShareholders(),
            businessShareholders: [{
              participationId: participationId,
              businessNumberParticipant : businessNumberParticipant,
              legalName: legalName,
              legalNameConfirm: legalNameConfirm,
              tradeName: tradeName,
              phoneAreaCode: phoneAreaCode,
              phoneNumber: phoneNumber,
              phoneExtension: '',
              email: email,
              workSafeBCReferenceNumber: workSafeBCReferenceNumber,
              isOwnerOrShareholder: false,
              mailingAddress: mailingAddress
            }]    
          };    

        payload = {
          shareholderinfo: shareholderPayload,
          accountType: this.accountType,
          firmType: this.firmType
        };    
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] generatePayload_ble ex' + ex.message);
      }
      return payload;
    }

    public generatePayload_delete(formGroup: UntypedFormGroup, participationId: any) {     
      const shareholderPayload: ShareholderInfoPayload = {
        activeShareHolderCount: this.getNumberOfShareholders(),
        deleteShareholders: [participationId]
      };
  
      let payload = {
        shareholderinfo: shareholderPayload,
        accountType: this.accountType,
        firmType: this.firmType
      };   
      return payload;    
    }

    
    public isEditShareholder(formGroup: UntypedFormGroup): boolean {    
      return this.shareholder_isEdit(this.plePrefix, formGroup);    
    } 

    public businessShareholder_isEdit(formGroup: UntypedFormGroup): boolean {    
      return this.shareholder_isEdit(this.blePrefix, formGroup);    
    } 


    public generatePayload_businessDetails(formGroup: UntypedFormGroup): any {   
      let payload;       
      try {       
        const hasOperateOtherBusiness = this.getFormValue(formGroup, 'hasOperateOtherBusiness');
        const workSafeBCAccountDesc = hasOperateOtherBusiness==='Y' ? this.getFormValue(formGroup, 'workSafeBCAccountDesc') : '';
        const hasWorkingRelationshipWithOtherBusiness= this.getFormValue(formGroup, 'hasWorkingRelationshipWithOtherBusiness');
        const workingRelationshipDesc= hasWorkingRelationshipWithOtherBusiness ==='Y' ? this.getFormValue(formGroup, 'workingRelationshipDesc'): '';
      
        const shareholderBusinessDetails = {       
          hasOperateOtherBusiness: hasOperateOtherBusiness,
          workSafeBCAccountDesc: workSafeBCAccountDesc,
          hasWorkingRelationshipWithOtherBusiness: hasWorkingRelationshipWithOtherBusiness,
          workingRelationshipDesc: workingRelationshipDesc
        };
        
        const shareholderPayload: ShareholderInfoPayload = {
          activeShareHolderCount: this.getNumberOfShareholders(),
          shareholderBusinessDetails: shareholderBusinessDetails
        };

        payload = {
          shareholderinfo: shareholderPayload,
          accountType: this.accountType,
          firmType: this.firmType
        }; 
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] ple_generateShareholderPayload ex' + ex.message);
      }
      return payload;
    }

    public resetFrom_ple(formGroup: UntypedFormGroup) {    
      try {
        const prefix = this.plePrefix;        
        this.setFormValue(formGroup, prefix + 'participationId', '');
        this.setFormValue(formGroup, prefix + 'title', null);
        this.setFormValue(formGroup, prefix + 'legalFirstName', null);
        this.setFormValue(formGroup, prefix + 'legalMiddleName', null);
        this.setFormValue(formGroup, prefix + 'legalLastName', null);
        this.setFormValue(formGroup, prefix + 'dateOfBirth', null);
        this.setFormValue(formGroup, prefix + 'phoneNumber', null);
        this.setFormValue(formGroup, prefix + 'email', null);
        this.setFormValue(formGroup, prefix + 'isMailingAddressSame', null);
        this.resetAddressFrom (prefix, formGroup);
        formGroup.reset();

      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] resetFrom_ple ex' + ex.message);
      }   
    }

    public resetFrom_ble(formGroup: UntypedFormGroup) {    
      try {
        const prefix = this.blePrefix;        
        this.setFormValue(formGroup, prefix + 'participationId', '');
        this.setFormValue(formGroup, prefix + 'businessNumberParticipant', null);
        this.setFormValue(formGroup, prefix + 'businessLegalName', null);
        this.setFormValue(formGroup, prefix + 'businessTradeName', null);
        this.setFormValue(formGroup, prefix + 'phoneNumber', null);
        this.setFormValue(formGroup, prefix + 'email', null);
        this.setFormValue(formGroup, prefix + 'isMailingAddressSame', null);
        this.resetAddressFrom (prefix, formGroup);
        formGroup.reset();

      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] resetFrom_ble ex' + ex.message);
      }   
    }

    private resetAddressFrom(prefix: string, formGroup: UntypedFormGroup) {    
      try {         
        this.setFormValue(formGroup, prefix + 'addressLine1', null);
        this.setFormValue(formGroup, prefix + 'addressLine2', null);
        this.setFormValue(formGroup, prefix + 'country', 'CA');
        this.setFormValue(formGroup, prefix + 'city', null);
        this.setFormValue(formGroup, prefix + 'province', null);
        this.setFormValue(formGroup, prefix + 'state', null);
        this.setFormValue(formGroup, prefix + 'postalCode', null);            
        this.setFormValue(formGroup, prefix + 'addressType', null);
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] resetAddressFrom ex' + ex.message);
      }   
    }
    
    private getNumberOfShareholders() {
      return this.getFormValue(this.shareholdersInfoFormFormGroup, 'numberOfShareholders');
    }

    private shareholder_isEdit(prefix: string, formGroup: UntypedFormGroup): boolean {    
      let isEdit = false;
      try {
        if (Number(this.getFormValue(formGroup, prefix + 'participationId')) >0 ){
          isEdit = true;
        }
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] shareholder_isEdit ex' + ex.message);
      }
      return isEdit;
    } 

    private generatePayload_getAddress(prefix: string, formGroup: UntypedFormGroup, isSamePhysicalAddress: boolean) {
      let provinceCode = null;
      let streetLine1 = null;
      let streetLine2 = null;
      let city = null;

      let postalCode = null;
      let country = null;
      let locationTypeCode = 'r';

      if (!isSamePhysicalAddress) {
        provinceCode = this.getFormValue(formGroup, prefix + 'country') == "CA"? 
        this.getFormValue(formGroup, prefix + 'province') : this.getFormValue(formGroup, prefix + 'state');

        streetLine1 = this.getFormValue(formGroup, prefix + 'addressLine1');
        streetLine2 = this.getFormValue(formGroup, prefix + 'addressLine2');
        city = this.getFormValue(formGroup, prefix + 'city');
        postalCode = this.getFormValue(formGroup, prefix + 'postalCode');
        country = this.getFormValue(formGroup, prefix + 'country');
        locationTypeCode = this.getFormValue(formGroup, prefix + 'addressType');
      }   

      const mailingAddress = {
        streetLine1: streetLine1,
        streetLine2: streetLine2,
        city: city,
        provinceCode:  provinceCode,
        postalCode: postalCode,
        country: country,         
        locationTypeCode: locationTypeCode, 
        addressTypeCode: 'mail',
        isSamePhysicalAddress: isSamePhysicalAddress
      }
      return mailingAddress;
    }

    private getBirthday(birthDateObj: any) {     
      try {
        if (birthDateObj != null) {
          const birthDate = this.datetimeFormatter.payloadDateFormat(new Date(
            birthDateObj.year,
            birthDateObj.month - 1,
            birthDateObj.date
          ));
          return birthDate;
        }
        else {
          return null;
        }
      } catch(ex) {
        console.log('[ShareholdersInfoCommonFunctions] getBirthday ex' + ex.message);
      }
    }

    private getPhoneAreaCode(value: number) {   
      let areaCode = '';
      if (value) {
        areaCode = value.toString();
        if(areaCode.length > 3) {
          areaCode = areaCode.slice(0, 3);
        }      
      }    
      return areaCode;
    }

    private getPhoneNumber(value: number) {   
      let phoneNumber = '';    
      if (value) {
        phoneNumber = value.toString();   
        if (phoneNumber.length ==10) {
          phoneNumber = phoneNumber.slice(3, 10);  
        }      
      };
      return phoneNumber;
    }
 
    private getFormValue(formGroup: UntypedFormGroup, name: string): any {
      let value = null;   
      try
      {
        let control = formGroup.get(name);
        if (control) {
          value = control.value;
        }      
      } catch(ex) { 
        console.log('getFormValue name=' + name + ' ex=' + ex.message);
      }     
      return value;
    }
    
    private setFormValue(formGroup: UntypedFormGroup, name: string, value: any) {    
      try
      {
        let control = formGroup.get(name);
        if (control) {
          control.setValue(value); 
        }      
      } catch(ex) { 
        console.log('setFormValue name=' + name + ' ex=' + ex.message);
      }        
    }  
    
    public initializeAddressAutocomplete(): void {
      try {
        const options = { key: environment.canadaPostAddressCompleteId};
        const businessFields = [{ element: 'search', field: "", mode: pca.fieldMode.SEARCH }];
        const businessControl = new pca.Address(businessFields, options);
        businessControl.listen('populate', (address: any) => {
          this.updateCanadaPostAddress(address);  
        });
      } catch (ex) {
        console.log('ShareholdersInfoCommonFunctions-initializeAddressAutocomplete ex=' + ex.message);
      }      
    }  
    
    private updateCanadaPostAddress(address: any): void {      
        let prefix = this.plePrefix;
        let formGroup = this.personalShareholderFormFormGroup;
        if (this.shareholdersInfoFormFormGroup.value.typeOfShareholder != this.personalShareholderType) {
          prefix = this.blePrefix;
          formGroup = this.businessShareholderFormFormGroup;
        }

        this.setFormValue(formGroup, prefix + 'addressLine1', this.commonFunctions.languageFilter(address.Line1));  
        this.setFormValue(formGroup, prefix + 'addressLine2', this.commonFunctions.languageFilter(address.Line2));
        this.setFormValue(formGroup, prefix + 'city', this.commonFunctions.languageFilter(address.City)); 
        this.setFormValue(formGroup, prefix + 'postalCode', this.commonFunctions.languageFilter(address.PostalCode));
        this.setFormValue(formGroup, prefix + 'province', this.commonFunctions.languageFilter(address.Province));
        this.setFormValue(formGroup, prefix + 'state', this.commonFunctions.languageFilter(address.ProvinceCode));//for US
        this.setFormValue(formGroup, prefix + 'country', this.commonFunctions.languageFilter(address.CountryIso2)); 
      
        let control = formGroup.get(prefix + 'addressLine1');
        if (control) {
          control.updateValueAndValidity();
        } 
    }  

    
  showSeacrhAddress() {
    let prefix = this.plePrefix;
    let formGroup = this.personalShareholderFormFormGroup;
    if (this.shareholdersInfoFormFormGroup.value.typeOfShareholder != this.personalShareholderType) {   
      prefix = this.blePrefix;     
      formGroup = this.businessShareholderFormFormGroup;
    }   

    if (this.getFormValue(formGroup, prefix + 'isMailingAddressSame') == 'N' ) {
      return true;
    } else {
      return false;
    }
    
  }
}


