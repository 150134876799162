import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/store/services/app.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  contactUsURL: string;
  currentYear = new Date().getFullYear();
  cssClass: string;

  constructor(private appService: AppService,
    private router: Router
              ) {if(this.router.url.includes('corporation') || this.router.url.includes('landingpage')){
                this.cssClass = "container"
              } else {
                this.cssClass = "container-fluid"
              } }

  ngOnInit() {
    // this.appService.getContactUsURL().subscribe(url => this.contactUsURL = url)
  }

}
