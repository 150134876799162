
export interface AppState {
    internetRegistration: any;
    exceptionReviewQuestion: any;
    selectedClassificationUnitInfo: any;
    classificationPredictionInfo: any,
    isWorkerPayrollRequired: any;
    popEstimatedMonthlyPremium: any;
    userSearchKeyWord: string;
    currentDateTime: string;
    lastSavedDateTimeLocal: Date;
    loaders: any[];
    idam: any
}

export interface State {
    appstate: AppState;
}

export const initialState: AppState = {
    internetRegistration: {},
    exceptionReviewQuestion: {},
    selectedClassificationUnitInfo: {},
    classificationPredictionInfo: {},
    isWorkerPayrollRequired: {},
    popEstimatedMonthlyPremium: {},
    userSearchKeyWord: '',
    currentDateTime: '',
    lastSavedDateTimeLocal: null,
    loaders: [],
    idam: {}
};
