import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../services/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageInit implements CanActivate  {

  constructor(private sessionStorageService: SessionStorageService) {

  }
  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        console.log(state.url);
        if (state.url.includes('/auth')) {
          console.log('Clearing session storage');
          this.sessionStorageService.cleanAppSessionStateFromSessionStorage();
          this.sessionStorageService.cleanRefreshPagePopupStateFromSessionStorage();
        }
        return true;
  }
}
