import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageInit implements CanActivate  {

  constructor(private localStorageService: LocalStorageService) {

  }
  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        console.log('[LocalStorageInit]' + state.url);
        if (state.url.includes('/auth')) {
          console.log('Clearing local storage');
          this.localStorageService.cleanAppStateFromLocalStorage();
        }
        return true;
  }
}
