export class ClassificationUnit {
  classificationUnitCode: number;
  classId: number;
  name: string;
  description: string;
  rate?: number;
  score?: number;
  mlSearch?: string;
}
export enum ProfileType {
  homeOwner = 'homeOwner',
  other = 'other',
  retail = 'retail',
  restaurant = 'restaurant'
}

export class OtherCoreBusinessActivities {
  contractOutCoreActivities?: string;
  isVehicleCoreOperation?: string;
  driveToOtherProvince?: string;
  vehicleOwnedOrLeased?: string;
  vehicleLeasingFirmName?: string;
  businessOwnVehicleAtEndOfLease?: string;  
  model1?: string;
  year1?: string;
  model2?: string;
  year2?: string;
  model3?: string;
  year3?: string;
}

export class ClassificationSerachInfo { 
  classificationId?: string;
  classificationName?: string;
  classificationUnits?: any;
  businessOperationsDesc?: string;
  description1?: string;
	percentage1?: string;
	description2?: string;
	percentage2?: string;
	description3?: string;
	percentage3?: string;
}


export class ClassificationInfoPayload {  	
  classificationSerachInfo?: ClassificationSerachInfo;  
  otherCoreBusinessActivities?: OtherCoreBusinessActivities;
}

