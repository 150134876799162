<div class="modal-header">
    <h2 class="modal-title p-0" id="modal-basic-title"></h2>   
</div>
<div class="modal-body pb-0">
    <form>
        <div class="form-group m-0">
            <p>
                {{this.message}}
            </p>
        </div>
    </form>
</div>
<div class="modal-footer">  
    <div class="ml-10 mr-10">
    <button id="confirmationOkButton" type="button" class="btn btn-sm btn-blue return-button mr-1 mb-1"  (click)="okClicked()">Ok</button>
    </div>
</div>
