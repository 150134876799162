import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppInsightsService } from 'src/app/core/appinsights.service';
import { AutoSavePopupState } from 'src/app/services/app.session.state';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import * as fromApp from 'src/app/store';
import { SimpleModalComponent } from '../simple-modal/simple-modal.component';

@Component({
  selector: 'app-auto-save',
  templateUrl: './auto-save.component.html',
  styleUrls: ['./auto-save.component.scss']
})
export class AutoSaveComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  autoSaveText = 'Autosave enabled';
  lastSavedTimeText = '';
  autoSaveTooltip = 'Your data will be saved each time you click Continue to go to the next page.';
  displayAutoSave = false;
  displayLastSavedTime = false;
  lastSavedDateTimeLocal: Date;
  updateLastSavedTimeInterval;
  private autoSaveSkippedPages = [
    '/registration-dashboard',
    '/landingpage',    
    '/retailer/limitedcompany/confirm',
    '/retailer/limitedcompany/applicationconfirm',
    '/retailer/limitedcompany/questionnaire',
    '/retailer/limitedcompany/actionplan',
    '/restaurant/limitedcompany/confirm',
    '/restaurant/limitedcompany/applicationconfirm',
    '/restaurant/limitedcompany/questionnaire',
    '/restaurant/limitedcompany/actionplan',
    '/selfemployed/partnership/confirm',
    '/selfemployed/partnership/applicationconfirm',
    '/selfemployed/partnership/questionnaire',
    '/selfemployed/partnership/actionplan',
    '/retailer/partnership/confirm',
    '/retailer/partnership/applicationconfirm',
    '/retailer/partnership/questionnaire',
    '/retailer/partnership/actionplan',
    '/restaurant/partnership/confirm',
    '/restaurant/partnership/applicationconfirm',
    '/restaurant/partnership/questionnaire',
    '/restaurant/partnership/actionplan',
    '/selfemployed/soleproprietorship/confirm',
    '/selfemployed/soleproprietorship/applicationconfirm',
    '/selfemployed/soleproprietorship/questionnaire',
    '/selfemployed/soleproprietorship/actionplan',
    '/retailer/soleproprietorship/confirm',
    '/retailer/soleproprietorship/applicationconfirm',
    '/retailer/soleproprietorship/questionnaire',
    '/retailer/soleproprietorship/actionplan',
    '/restaurant/soleproprietorship/confirm',
    '/restaurant/soleproprietorship/applicationconfirm',
    '/restaurant/soleproprietorship/questionnaire',
    '/restaurant/soleproprietorship/actionplan',
    '/homeowner/confirm',
    '/homeowner/application-confirm',
    '/homeowner/questionnaire',
    '/homeowner/actionplan',
    '/selfemployed',
    '/coverage-notrequired',
    '/environmentinfo',
    '/error-page',
    '/corporation/applicationconfirm'
  ];

  firstTimeVistMessage = 'Your data will be saved each time you click Continue to go to the next page.';

  constructor(
    public appInsightService: AppInsightsService,
    private router: Router,
    private store$: Store<fromApp.State.State>,
    private modalService: NgbModal,
    private sessionStorageService: SessionStorageService) {
    this.subscriptions.push(
      router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.displayAutoSave = !this.autoSaveSkippedPages.some(page => event.urlAfterRedirects.split('?')[0] === page);
          this.displayLastSavedTime = false;
          setTimeout(() => this.displayLastSavedTime = true, 5000);
        }
      })
    );
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store$.pipe(select(fromApp.Selectors.getLastSavedDateTimeLocal)).subscribe(
        lastSavedDateTimeLocal => this.lastSavedDateTimeLocal = lastSavedDateTimeLocal
      )
    );

    this.updateLastSavedTimeInterval = setInterval(() => {
      this.updateLastSavedTimeText();
    }, 1000);

    this.subscriptions.push(
      this.store$.pipe(select(fromApp.Selectors.getLoaders)).subscribe(
        loaders => {
          setTimeout(() => {
            let loading = loaders ? loaders.length > 0 : false;
            if (!loading) {
              this.showAutoSavePopup();
            }
          });
        }
      )
    );
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    clearInterval(this.updateLastSavedTimeInterval);
  }

  private updateLastSavedTimeText() {
    if (!this.lastSavedDateTimeLocal) {
      return;
    }

    try {
      const lastSavedTimeInMinutes = Math.floor((new Date().getTime() - this.lastSavedDateTimeLocal.getTime()) / 1000 / 60);
      switch (lastSavedTimeInMinutes) {
        case 0:
          this.lastSavedTimeText = 'Saved less than a minute ago';
          break;
        case 1:
          this.lastSavedTimeText = 'Saved 1 minute ago';
          break;
        default:
          this.lastSavedTimeText = `Saved ${lastSavedTimeInMinutes} minutes ago`;
          break;
      }
    } catch (ex) {
      // catch here to avoid break the whole app when this.lastSavedDateTimeLocal.getTime is not a function
      console.log('[AutoSaveComponent] - updateLastSavedTimeText ex= ' + ex.message);
      this.appInsightService.logException(ex, '[AutoSaveComponent]-updateLastSavedTimeText');
    }
  }


  private getConfirmation(message: string): Promise<boolean> {
    const config: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'registration-modal medium-modal'
    };
    const modal = this.modalService.open(SimpleModalComponent, config);
    modal.componentInstance.message = message;
    return modal.result;
  }

  private showAutoSavePopup() {
    let autoSavePopupState = this.sessionStorageService.getAutoSavePopupStateFromSessionStorage();
    let isDisplayed = true;
    if (autoSavePopupState == null || (autoSavePopupState != null && !autoSavePopupState.isDisplayed)) {
      isDisplayed = false;
    }

    if (!isDisplayed && this.displayAutoSave && this.router && this.router.url &&
      (this.router.url.endsWith('/selfemployed/soleproprietorship/businesscontactinfo') ||
        this.router.url.endsWith('/contactinfo') && !this.router.url.includes('/selfemployed/soleproprietorship'))) {
      this.sessionStorageService.storeOnAutoSavePopupStateSessionStorage(new AutoSavePopupState(true));
      console.log('[AutoSaveComponent] - displayAutoSave=' + this.displayAutoSave);
      this.getConfirmation(this.firstTimeVistMessage);
    }
  }
}
