export class HomeOwnerConstants {    
    static PayrollType = 'e';   
    static PayrollSubType = 'e'; 
    static payrollTypeCD = 'W';     
}

export class QuarterConstants {       
    static Quarters =[
        {quarterId: 'Q1', quarterName: 'January 1 - March 31', startMonth: 1,   startDay: 1, endMonth: 3, endDay: 31},
        {quarterId: 'Q2', quarterName: 'April 1 - June 30', startMonth: 4,   startDay: 1, endMonth: 6, endDay: 30},
        {quarterId: 'Q3', quarterName: 'July 1 - September 30', startMonth: 7,   startDay: 1, endMonth: 9, endDay: 30},
        {quarterId: 'Q4', quarterName: 'October 1 - December 31', startMonth: 10,  startDay: 1, endMonth: 12, endDay: 31}      
     ];
}

export class QuarterConstant {   
    quarterId: string;
    quarterName: string;
    startMonth: number;
    startDay: number;
    endMonth: number;   
    endDay: number;
}

export enum PayrollTypeConstants {
    workerPayrollType = 'W',
    spousePayrollType = 'S'
}

export class PayrollConstants {
    static payrollTypeCD = PayrollTypeConstants.spousePayrollType;
    static payrollTypeCode = 'e';
    static PayrollType = 'e';
    static PayrollSubType = 'e';
}

export class WorkerPayrollConstants {
    static payrollTypeCD = PayrollTypeConstants.workerPayrollType;
    static payrollTypeCode = 'e';
    static PayrollType = 'e';
    static PayrollSubType = 'e';
}


export class VSCPayrollConstants {
    static payrollTypeCD = PayrollTypeConstants.spousePayrollType;
    static payrollTypeCode = 'e';
    static PayrollType = 'e';
    static PayrollSubType = 'e';
}