import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { OidcAuthService } from './oidc-auth.service';

@Injectable()
export class OidcAuthenticationGuard implements CanActivate {
  constructor(private authService: OidcAuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.authService.isAuthenticated && this.authService.isAuthenticated.pipe(
      tap(isAuthenticated => {
        if (!isAuthenticated) {
          console.log('OidcAuthenticationGuard- this.authService.redirectToSignInPage-state.url=' +state?.url);
          this.authService.redirectToSignInPage(state.url);
        }
      }),
      filter(isAuthenticated => isAuthenticated),
    );
  }
}
