import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DatetimeFormatter } from 'src/app/shared/common/formatters/datetime.formatter';
import { CurrencyFormatter } from 'src/app/shared/common/formatters/currency.formatter';
import { PayrollInfo, QuarterConstants } from 'src/app/store/feature-stores/payroll-store/models';
import * as fromPayroll from 'src/app/store/feature-stores/payroll-store';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { EASConstants } from 'src/app/shared/common/common-constants';

@Injectable()
export class PayrollFieldFunctions {
  constructor(
    private datetimeFormatter: DatetimeFormatter,
    private currencyFormatter: CurrencyFormatter,
    private sessionStorage: SessionStorageService) { }

  // PayrollFieldFunctions can be shared when operating on payroll field
  // put public methods on top
  // init payroll date after getting from store
  initPayrollInfo(payrollTypeCD: string, currentDate: Date, workerStartDate: Date, workerEndDate: Date,
    preloadPayrolls: fromPayroll.Model.Payroll[],
    currentYearIdPrefix: string, previousYearIdPrefix: string, nextYearIdPrefix: string,
    validatorRequired: boolean,
    payrollForm: UntypedFormGroup,
    futurePayPeriod: number) {
    let payrollByTypeCD = [];
    if (preloadPayrolls) {
      payrollByTypeCD = preloadPayrolls.filter(p => p.payrollTypeCD === payrollTypeCD);
    }

    const currentYear = currentDate.getFullYear();
    const payrollInfo = new PayrollInfo();
    payrollInfo.previousYear = [];
    payrollInfo.currentYear = [];
    payrollInfo.nextYear = [];

    let payrollQuarterId = '';
    let disableInputBox = false;
    let controlId = '';
    let amount = '';
    if (previousYearIdPrefix !== '' && workerStartDate && workerStartDate.getFullYear() < currentYear) {
      for (let i = 3; i > 0; i--) {
        const previousYear = currentYear - i;
        payrollQuarterId = '';
        disableInputBox = false;
        if (previousYear >= workerStartDate.getFullYear()) {
          if (workerEndDate == null || (workerEndDate != null && previousYear <= workerEndDate.getFullYear())) {
            controlId = previousYearIdPrefix + i;
            amount = this.getPayrollValue(payrollByTypeCD, previousYear, payrollQuarterId);
            payrollInfo.previousYear.push({
              id: controlId,
              year: previousYear,
              quarterId: payrollQuarterId,
              quarterName: 'January 1 - December 31',
              startDate: this.datetimeFormatter.standardDateFormat(previousYear, 1, 1),
              endDate: this.datetimeFormatter.standardDateFormat(previousYear, 12, 31),
              payrollAmount: amount,
              disable: disableInputBox,
              required: validatorRequired ? !disableInputBox : false,
              label1: previousYear.toString(),
              label2: 'Total payroll for'
            });
            this.setControlValue(controlId, amount, payrollForm);
          }
        }
      }
    }

    if (workerStartDate && workerStartDate.getFullYear() <= currentYear &&
      (workerEndDate == null || (workerEndDate != null && currentYear <= workerEndDate.getFullYear()))) {
      for (let i = 0; i <= QuarterConstants.Quarters.length - 1; i++) {
        payrollQuarterId = QuarterConstants.Quarters[i].quarterId;
        disableInputBox = this.disableCurrentYearQuarter(
          QuarterConstants.Quarters[i].quarterId,
          currentDate,
          workerStartDate,
          workerEndDate
        );
        controlId = currentYearIdPrefix + QuarterConstants.Quarters[i].quarterId;
        amount = disableInputBox ? '' : this.getPayrollValue(payrollByTypeCD, currentYear, payrollQuarterId);
        payrollInfo.currentYear.push({
          id: controlId,
          year: currentYear,
          quarterId: payrollQuarterId,
          quarterName: QuarterConstants.Quarters[i].quarterName,
          startDate: this.datetimeFormatter.standardDateFormat(
            currentYear,
            QuarterConstants.Quarters[i].startMonth,
            QuarterConstants.Quarters[i].startDay),
          endDate: this.datetimeFormatter.standardDateFormat(
            currentYear,
            QuarterConstants.Quarters[i].endMonth,
            QuarterConstants.Quarters[i].endDay
          ),
          payrollAmount: amount,
          disable: disableInputBox,
          required: validatorRequired ? !disableInputBox : false,
          label1: payrollQuarterId,
          label2: QuarterConstants.Quarters[i].quarterName
        });
        this.setControlValue(controlId, amount, payrollForm);
      }
    }

    // prepare for nextYear accorrding to business           
    // When workerEndDate > the EAS constant ireg_futpyrlalw (futurePayPeriod), we use ireg_futpyrlalw year
    // When workerEndDate <= the EAS constant ireg_futpyrlalw(futurePayPeriod), we use worker end date
    const nextYear = currentYear + 1;
    const checkWorkerEndDate = (workerEndDate != null && workerEndDate.getFullYear() > currentDate.getFullYear() && nextYear <= workerEndDate.getFullYear()) ? true : false;
    if (futurePayPeriod === nextYear && nextYearIdPrefix !== '' &&
      (workerStartDate && workerStartDate.getFullYear() === nextYear || workerEndDate == null || checkWorkerEndDate)) {
      for (let i = 0; i <= QuarterConstants.Quarters.length - 1; i++) {
        payrollQuarterId = QuarterConstants.Quarters[i].quarterId;
        disableInputBox = this.disableNextYearQuarter(
          QuarterConstants.Quarters[i].quarterId,
          currentDate, workerStartDate, workerEndDate, nextYear);

        controlId = nextYearIdPrefix + QuarterConstants.Quarters[i].quarterId,
          amount = disableInputBox ? '' : this.getPayrollValue(payrollByTypeCD, nextYear, payrollQuarterId),

          payrollInfo.nextYear.push({
            id: controlId,
            year: nextYear,
            quarterId: payrollQuarterId,
            quarterName: QuarterConstants.Quarters[i].quarterName,
            startDate: this.datetimeFormatter.standardDateFormat(
              nextYear,
              QuarterConstants.Quarters[i].startMonth,
              QuarterConstants.Quarters[i].startDay),
            endDate: this.datetimeFormatter.standardDateFormat(
              nextYear,
              QuarterConstants.Quarters[i].endMonth,
              QuarterConstants.Quarters[i].endDay),
            payrollAmount: amount,
            disable: disableInputBox,
            required: validatorRequired ? !disableInputBox : false,
            label1: payrollQuarterId,
            label2: QuarterConstants.Quarters[i].quarterName
          });
        this.setControlValue(controlId, amount, payrollForm);
      }
    }

    return payrollInfo;
  }

  getPayrollFormValue(formControlName: string, payrollForm: UntypedFormGroup) {
    let value = '';
    if (payrollForm && payrollForm.get(formControlName)) {
      value = payrollForm.get(formControlName).value;
    }
    return value;
  }

  clearValidatorsForQuarter(payrollForm: UntypedFormGroup, payrollCurrentYearIdPrefix: string) {
    if (payrollForm) {
      for (let i = 0; i <= QuarterConstants.Quarters.length - 1; i++) {
        const control = payrollForm.get(payrollCurrentYearIdPrefix + QuarterConstants.Quarters[i].quarterId);
        if (control) {
          control.clearValidators();
          control.updateValueAndValidity();
        }
      }
    }
  }

  getQuarterPayrollTotal(payrollYearIdPrefix: string, payrollForm: UntypedFormGroup) {
    let total = 0;
    if (payrollForm) {
      for (let i = 0; i <= QuarterConstants.Quarters.length - 1; i++) {
        const quarterValue = this.getPayrollFormValue(
          payrollYearIdPrefix + QuarterConstants.Quarters[i].quarterId, payrollForm);
        total += (quarterValue !== '' ? parseInt(quarterValue, 10) : 0);
      }
    }
    return total;
  }

  // prepare payroll data to submit
  updatePayrollInfo(isPayrollRequired: boolean, payrollInfo: PayrollInfo, payrollForm: UntypedFormGroup) {
    if (!isPayrollRequired || !payrollInfo) {
      return payrollInfo;
    }
    if (payrollInfo.previousYear) {
      payrollInfo.previousYear.forEach(element => {
        const payrollAmount = payrollForm.get(element.id);
        if (payrollAmount && payrollAmount.touched) {
          element.payrollAmount = payrollAmount.value;
        }
      });
    }
    if (payrollInfo.currentYear) {
      payrollInfo.currentYear.forEach(element => {
        const payrollAmount = payrollForm.get(element.id);
        if (payrollAmount && payrollAmount.touched) {
          element.payrollAmount = payrollAmount.value; 
        }
      });
    }
    if (payrollInfo.nextYear) {
      payrollInfo.nextYear.forEach(element => {
        const payrollAmount = payrollForm.get(element.id);
        if (payrollAmount && payrollAmount.touched) {
          element.payrollAmount = payrollAmount.value;
        }
      });
    }
    return payrollInfo;
  }

  // prepare payroll data to submit
  convertPayrollInfoToPayload(isPayrollRequired: boolean, payrollInfo: PayrollInfo, startDate: Date, endDate: Date,
    typeCode: string, payrollTypeCD: string) {
    const payrolls = [];
    if (!isPayrollRequired || !payrollInfo) {
      return payrolls;
    }
    if (payrollInfo.previousYear) {
      payrollInfo.previousYear.forEach(element => {
        if (!element.disable && element.payrollAmount !== '') {
          payrolls.push({
            payrollEffectiveDate: this.datetimeFormatter.payloadDateFormat(this.getPayrollStartDate(startDate, element.startDate)),
            payrollEndDate: this.datetimeFormatter.payloadDateFormat(this.getPayrollEndDate(endDate, element.endDate)),
            estimatedPayrollAmount: element.payrollAmount.toString(),
            payrollTypeCode: typeCode,
            payrollSubTypeCode: typeCode,
            quarter: element.year.toString(),
            payrollSourceIndicator: '',
            payrollTypeCD
          });
        }
      });
    }
    if (payrollInfo.currentYear) {
      payrollInfo.currentYear.forEach(element => {
        if (!element.disable && element.payrollAmount !== '') {
          payrolls.push({
            payrollEffectiveDate: this.datetimeFormatter.payloadDateFormat(this.getPayrollStartDate(startDate, element.startDate)),
            payrollEndDate: this.datetimeFormatter.payloadDateFormat(this.getPayrollEndDate(endDate, element.endDate)),
            estimatedPayrollAmount: element.payrollAmount.toString(),
            payrollTypeCode: typeCode,
            payrollSubTypeCode: typeCode,
            quarter: element.year.toString() + element.quarterId,
            payrollSourceIndicator: '',
            payrollTypeCD
          });
        }
      });
    }
    if (payrollInfo.nextYear) {
      payrollInfo.nextYear.forEach(element => {
        if (!element.disable && element.payrollAmount !== '') {
          payrolls.push({
            payrollEffectiveDate: this.datetimeFormatter.payloadDateFormat(this.getPayrollStartDate(startDate, element.startDate)),
            payrollEndDate: this.datetimeFormatter.payloadDateFormat(this.getPayrollEndDate(endDate, element.endDate)),
            estimatedPayrollAmount: element.payrollAmount.toString(),
            payrollTypeCode: typeCode,
            payrollSubTypeCode: typeCode,
            quarter: element.year.toString() + element.quarterId,
            payrollSourceIndicator: '',
            payrollTypeCD
          });
        }
      });
    }
    return payrolls;
  }

  getFuturePayrollPeriod(): number {
    let futurePayPeriod = (this.datetimeFormatter.currentDate()).getFullYear();
    try {
      const futurePayPeriodString = this.sessionStorage.getEASconstants(EASConstants.FuturePayPeriod);
      futurePayPeriod = parseInt(futurePayPeriodString, 10);
    } catch (ex) {
      futurePayPeriod = (this.datetimeFormatter.currentDate()).getFullYear();
    }
    return futurePayPeriod;
  }

  private getPayrollStartDate(startDate: Date, payrollStartDate: Date) {
    if (startDate != null && payrollStartDate != null && startDate > payrollStartDate) {
      return startDate;
    } else {
      return payrollStartDate;
    }
  }

  private getPayrollEndDate(endDate: Date, payrollEndDate: Date) {
    if (endDate != null && payrollEndDate != null && endDate <= payrollEndDate) {
      return endDate;
    } else {
      return payrollEndDate;
    }
  }

  private setControlValue(controlId: string, amount: string, payrollForm: UntypedFormGroup) {
    const control = payrollForm.get(controlId);
    if (control) {  
      const formatAmount = this.currencyFormatter.formatNumberToCurrency(amount);
      control.setValue(formatAmount);
    }
  }

  private getPayrollValue(preloadPayrolls: fromPayroll.Model.Payroll[], year: number, quarterId: string) {
    let value = '';
    if (preloadPayrolls != null) {
      const id = year.toString() + quarterId;
      try {
        const found = preloadPayrolls.find(element => element.quarter === id);
        if (found) {
          value = found.estimatedPayrollAmount;
        }
      } catch {
        value = '';
      }
    }
    return value + '';
  }

  private disableCurrentYearQuarter(q: string, currentDate: Date, workerStartDate: Date, workerEndDate: Date) {
    if (workerStartDate.getFullYear() === currentDate.getFullYear()) {
      if (q === QuarterConstants.Quarters[0].quarterId && this.datetimeFormatter.getMonth(workerStartDate) >= 4) {
        return true;
      } else if (q === QuarterConstants.Quarters[1].quarterId && this.datetimeFormatter.getMonth(workerStartDate) >= 7) {
        return true;
      } else if (q === QuarterConstants.Quarters[2].quarterId && this.datetimeFormatter.getMonth(workerStartDate) >= 10) {
        return true;
      }
    }
    if (workerEndDate && workerEndDate.getFullYear() === currentDate.getFullYear()) {
      if (q === QuarterConstants.Quarters[1].quarterId && this.datetimeFormatter.getMonth(workerEndDate) < 4) {
        return true;
      } else if (q === QuarterConstants.Quarters[2].quarterId && this.datetimeFormatter.getMonth(workerEndDate) < 7) {
        return true;
      } else if (q === QuarterConstants.Quarters[3].quarterId && this.datetimeFormatter.getMonth(workerEndDate) < 10) {
        return true;
      }
    }
    return false;
  }

  private disableNextYearQuarter(q: string, currentDate: Date, workerStartDate: Date, workerEndDate: Date, nextYear: number) {
    if (workerEndDate
      && (workerEndDate.getFullYear() > currentDate.getFullYear())
      && (workerEndDate.getFullYear() === nextYear)) {
      if (q === QuarterConstants.Quarters[1].quarterId && this.datetimeFormatter.getMonth(workerEndDate) < 4) {
        return true;
      } else if (q === QuarterConstants.Quarters[2].quarterId && this.datetimeFormatter.getMonth(workerEndDate) < 7) {
        return true;
      } else if (q === QuarterConstants.Quarters[3].quarterId && this.datetimeFormatter.getMonth(workerEndDate) < 10) {
        return true;
      }
    }

    if (workerStartDate.getFullYear() === nextYear) {
      if (q === QuarterConstants.Quarters[0].quarterId && this.datetimeFormatter.getMonth(workerStartDate) >= 4) {
        return true;
      } else if (q === QuarterConstants.Quarters[1].quarterId && this.datetimeFormatter.getMonth(workerStartDate) >= 7) {
        return true;
      } else if (q === QuarterConstants.Quarters[2].quarterId && this.datetimeFormatter.getMonth(workerStartDate) >= 10) {
        return true;
      }
    }

    return false;
  }
}
