import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { CommonFunctions } from 'src/app/shared/common/common-functions';
import { FirmType, AddressTypesDropDownLst, OwnerOrShareholderTypeCode } from 'src/app/shared/common/common-constants';
import { AddressDetailsPayload, ContactInfoSolePropFormE2E, ContactInfoSolePropPayload } from 'src/app/store/feature-stores/contact-info-store/models';
import * as fieldValidators from '../../reactive-validators/fields.validator';
import { ErrorMessage } from '../../reactive-validators/error.message';
import { FormFunctions } from '../form-functions';
import * as wsValidators from 'src/app/shared/reactive-validators/fields.validator';
import { DatetimeFormatter } from '../../common/formatters/datetime.formatter';
import { InternetRegistrationHelperService } from 'src/app/store/helpers/internet-registration-helper.service';

@Injectable()
export class ContactInfoCommonSoleProprietorFunctions { 
  otherFields = {
    otherDesc: new UntypedFormControl('', [wsValidators.requiredValidator(ErrorMessage.requiredRelationshipMsg('business'))]),
  };

  ownerOrShareholderFields = {
    addressLine1: new UntypedFormControl('', [
      fieldValidators.requiredValidator(ErrorMessage.addressValidation.emptyMsg), 
      fieldValidators.addressValidator(true),
      Validators.maxLength(50)
    ]),
    addressLine2: new UntypedFormControl('', [fieldValidators.addressValidator(), Validators.maxLength(50) ]),
    province: new UntypedFormControl('',[
      fieldValidators.cityValidator(false),
      Validators.maxLength(30)
    ]),
    city: new UntypedFormControl('',[fieldValidators.requiredValidator(ErrorMessage.cityValidation.emptyMsg),
      Validators.maxLength(30),fieldValidators.cityValidator(true)]),
    postalCode: new UntypedFormControl('', [fieldValidators.requiredValidator(ErrorMessage.postalCodeValidation.emptyMsg), Validators.maxLength(7)]),
    country: new UntypedFormControl('', [
      fieldValidators.requiredValidator(ErrorMessage.cityValidation.emptyMsg),
      fieldValidators.cityValidator(true),
      Validators.maxLength(30)
    ]),
    state: new UntypedFormControl('', [
      fieldValidators.cityValidator(false),
      Validators.maxLength(30)
    ]),
    addressType: new UntypedFormControl('', [fieldValidators.requiredValidator(ErrorMessage.addressTypeValidation.emptyMsg)]),
    birthDate: new UntypedFormControl({ month: 0, date: 0, year: 0 }, [Validators.required])
  }

  otherTypeCode: string = 'o';
  ownerOrShareholderTypeCode: string = OwnerOrShareholderTypeCode;
  addressTypesData = AddressTypesDropDownLst;
  constructor(private commonFunctions: CommonFunctions, 
    private formFunctions: FormFunctions, 
    private datetimeFormatter: DatetimeFormatter,
    private iRegHelper: InternetRegistrationHelperService) {

   }
  public createContactForm(fb: UntypedFormBuilder, requiredRelatioMsg: string) {
    const contactForm = fb.group({
      firmTypeRelationship: ['', fieldValidators.requiredValidator(requiredRelatioMsg)],
      title: [''],
      legalFirstName: ['', [fieldValidators.nameValidator(true, 'legal first')]],
      legalMiddleName: ['', [fieldValidators.nameValidator(false, 'middle')]],
      legalLastName: ['', [fieldValidators.nameValidator(true, 'legal last')]],
      email: ['', [fieldValidators.emailValidator(true)]],
      phoneNumber: ['', [fieldValidators.phoneNumberValidator(true)]]    
    });
    return contactForm;
  }

  public generateFormFromPayload(iReg: any, contactForm: UntypedFormGroup) {
    let typeValue = '';
    if (iReg.participation != null) {     
      if (iReg.participation.length > 0 && iReg.participation[0].regLegalEntity?.item?.contactRelationshipTypeCode != null) {
        typeValue = iReg.participation[0].regLegalEntity.item.contactRelationshipTypeCode;  
      }       
      contactForm.get('firmTypeRelationship').setValue(typeValue);         
    } 

    this.addNewFields(typeValue, contactForm, iReg);    
    // init contact info for add new and edit
    contactForm.get('title').setValue(iReg.userChoice.contactTitle || '');
    contactForm.get('legalFirstName').setValue(this.commonFunctions.trimLeadingAndTrailingSpace(iReg.userChoice.contactNameFirst));
    contactForm.get('legalMiddleName').setValue(iReg.userChoice.contactNameMiddle);
    contactForm.get('legalLastName').setValue(this.commonFunctions.trimLeadingAndTrailingSpace(iReg.userChoice.contactNameLast));
    contactForm.get('email').setValue(iReg.userChoice.contactEmail);
    contactForm.get('phoneNumber').setValue(iReg.userChoice.contactTelephone);         
  }

  public contactInfoPayload(contactForm: UntypedFormGroup, isDataCollectionOK: boolean, firmType: FirmType): ContactInfoSolePropPayload{
    let contactInfoForm = contactForm.value as ContactInfoSolePropFormE2E;

    const relationshipDesc = contactInfoForm.firmTypeRelationship == this.otherTypeCode ? 
                              contactInfoForm.otherDesc : 
                              this.getContactRelationDesc(contactInfoForm.firmTypeRelationship, firmType);  

    const isOwnerOrShareholder = contactInfoForm.firmTypeRelationship == this.ownerOrShareholderTypeCode? true: false;
    const mailingAddress: AddressDetailsPayload = {
      streetLine1: contactInfoForm.addressLine1,
      streetLine2: contactInfoForm.addressLine2,
      city: contactInfoForm.city,
      provinceCode:  contactInfoForm.country == "CA"? contactInfoForm.province : contactInfoForm.state,
      postalCode: contactInfoForm.postalCode,
      country: contactInfoForm.country,         
      locationTypeCode: contactInfoForm.addressType, 
      addressTypeCode: 'mail',
      isSamePhysicalAddress: false
    };
    
    let payload = {        
      contactRelationshipTypeCode: contactInfoForm.firmTypeRelationship,
      contactRelation: relationshipDesc,
      title: contactInfoForm.title ? contactInfoForm.title : '',
      firstName: contactInfoForm.legalFirstName,
      middleName: '',
      lastName: contactInfoForm.legalLastName,
      email: contactInfoForm.email,     
      phoneAreaCode: this.getPhoneAreaCode(contactInfoForm.phoneNumber),
      phoneNumber: this.getPhoneNumber(contactInfoForm.phoneNumber),
      phoneExtension: '',      
      mailingAddress: mailingAddress,    
      birthDate: this.getBirthday(contactInfoForm.birthDate),      
      isOwnerOrShareholder: isOwnerOrShareholder,
      isDataCollectionOK: isDataCollectionOK
    };
    return payload;
  }  

  public addNewFields(event: any, formGroup: any, iReg: any){
    if(event == this.otherTypeCode){
      if(formGroup.value.addressLine1!=undefined) {
        this.formFunctions.addRemoveFormFields([],[this.ownerOrShareholderFields], formGroup)
      }
      this.formFunctions.addRemoveFormFields([this.otherFields],[],formGroup);
    } else if (event == this.ownerOrShareholderTypeCode) {
      if(formGroup.value.other!=undefined) {
        this.formFunctions.addRemoveFormFields([],[this.otherFields], formGroup)
      }
      this.formFunctions.addRemoveFormFields([this.ownerOrShareholderFields],[],formGroup);
      formGroup.get('country').setValue('CA');
    } else {
      this.formFunctions.addRemoveFormFields([],[this.ownerOrShareholderFields, this.otherFields], formGroup);
    }    

    if (formGroup.get('otherDesc')) {
      let typeValue = '';
      if (iReg.participation.length > 0 && iReg.participation[0].regLegalEntity?.item?.contactRelationshipTypeCode != null) {
        typeValue = iReg.participation[0].regLegalEntity.item.contactRelationshipTypeCode;  
      } 
      if (event == typeValue) {     
        formGroup.get('otherDesc').setValue(iReg.userChoice?.contactRelation);
      } else {
        formGroup.get('otherDesc').setValue('');
      }
    }    

    this.generateRelationshipForPersonalShareholder(iReg, formGroup, event);   
  }  

  public getAddressTypesData() {   
    return this.addressTypesData;
  }
  
  public getRelationshipData(firmType: FirmType, isUx20: boolean = false) {
    let relationshipList = this.commonFunctions.getRelationshipData(firmType, isUx20);
    return relationshipList;
  }

  private getContactRelationDescForLimitedCompany(value: string) {   
    switch (value) {
        case 'sh': {
            return 'Owner or shareholder';
        }
        case 'a': {
          return 'Accountant or bookkeeper';
        }
        case 'fm': {
            return 'Family member';
        }  
        case 'ce': {
          return 'Company employee';
        }    
        case 'o': {
            return 'Other';
        }       
        default: {
            return 'unknown';
        }
    }
  }
  private getPhoneAreaCode(value: number) {   
    let areaCode = '';
    if (value) {
      areaCode = value.toString();
      if(areaCode.length > 3) {
        areaCode = areaCode.slice(0, 3);
      }      
    }    
    return areaCode;
  }

  private getPhoneNumber(value: number) {   
    let phoneNumber = '';    
    if (value) {
      phoneNumber = value.toString();   
      if (phoneNumber.length ==10) {
        phoneNumber = phoneNumber.slice(3, 10);  
      }      
    };
    return phoneNumber;
  }
  
  private getContactRelationDesc(value: string, firmType: FirmType) {
    let result = '';
    switch (firmType) {
      case FirmType.proprietor:        
        break;
      case FirmType.partner:        
        break;
      case FirmType.limitedCompany:
        result = this.getContactRelationDescForLimitedCompany(value);
        break;
      case FirmType.association:
      case FirmType.band:
      case FirmType.church:
      case FirmType.cooperative:
      case FirmType.society:
      case FirmType.union:        
        break;
    }   
    return result;
  }

  private generateRelationshipForPersonalShareholder(iReg: any, contactForm: UntypedFormGroup, typeValue: string) {
    if (typeValue != this.ownerOrShareholderTypeCode) {
        return;
    }     

    if ( iReg.participation?.length > 1) {        
      let participant = this.iRegHelper.findOwnerOrShareholder(iReg, this.ownerOrShareholderTypeCode);

      if (participant != null && participant.regLegalEntity?.item?.birthDate != null ) {
        const birthDateFormat = this.datetimeFormatter.dateObjectFormat(participant.regLegalEntity.item.birthDate);
        contactForm.get('birthDate').setValue(birthDateFormat); 
      }         

      if (participant != null  && participant.regLegalEntity?.legalEntityAddress != null 
          && participant.regLegalEntity.legalEntityAddress.length > 0) {                              
            let address = participant.regLegalEntity.legalEntityAddress[0];
            contactForm.get('addressLine1').setValue(address.streetLine1);   
            contactForm.get('addressLine2').setValue(address.streetLine2);           
            contactForm.get('country').setValue(address.country);  
            contactForm.get('postalCode').setValue(address.postalCode);   
            contactForm.get('city').setValue(address.city); 
            
            if (address.country === 'CA') {
              contactForm.get('province').setValue(address.provinceCode);
            } else {
              contactForm.get('state').setValue(address.provinceCode);          
            }
            contactForm.get('addressType').setValue(address.locationTypeCode);
      }  
    }        
  }
 

  private getBirthday(birthDateObj: any) {

    if (birthDateObj != null) {
      const birthDate = this.datetimeFormatter.payloadDateFormat(new Date(
        birthDateObj.year,
        birthDateObj.month - 1,
        birthDateObj.date
      ));
      return birthDate;
    }
    else {
      return null;
    }
  }
}
