<header class="pb-4">
    <div class="header-thinline"></div>
    <div class="header-topline">
      <div class="header-curve"></div>
    </div>
    <div class="pb-3">
      <div class="{{cssClass}}">
        <div class="row">
          <div class="col-6 wsbc-logo"><img class="img-fluid d-block" src="/assets/images/logo.jpg"></div>
          <div class="col-5 auto-save">
            <app-auto-save></app-auto-save>
          </div>
          <div class="col-1 exit">
            <a class="exit-link" tabindex="0" id="appHeaderExitLink" [href]="exitUrl" appExternalUrl>Exit</a>
          </div>
        </div>
      </div>
    </div>
</header>
