import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, tap, catchError, withLatestFrom, switchMap, finalize } from 'rxjs/operators';
import { AppService } from '../services/app.service';
import * as fromApp from '..';
import { environment } from 'src/environments/environment';
import { AccountType } from '../models/internet-registration';

@Injectable()
export class AppEffects {
  private getIsWorkerPayrollRequiredUrl: string = environment.registrationApiUrl + '/api/v1/PayrollCoverage/IsWorkerPayrollRequired';
  private requestCurrentDateTimeUrl: string = environment.registrationApiUrl + '/api/v1/Registration/GetCurrentDateTime';  
  private getRequestUrlInternetRegistrationByIRRefNumE2E: string = environment.registrationApiUrl + '/api/v1/E2E/InternetRegistrationByIRRefNum';
  private getClassificationPredictionRequestUrl: string = environment.registrationApiUrl + '/api/v1/ClassificationInfo/ClassificationPrediction';  
  private getClassificationBySearchCriteriaUrl: string = environment.registrationApiUrl + '/api/v1/ClassificationInfo/ClassificationBySearchCriteria';
  private requestCurrentDateTimeUrlE2E: string = environment.registrationApiUrl + '/api/v1/E2E/CurrentPacificStandardTime';
  
  private mergedClassificationPredictionPayload: any; 
  private mergedClassificationBySearchCriteriaPayload: any; 
  
  private email = '';
  private iRegIRNumber = '';
  private currentPayload: any;
  private mergedPayload: any;
  constructor(private appService: AppService,
              private actions$: Actions,
              private store$: Store<fromApp.State.State>) {}

  loadIsWorkerPayrollRequired$ = createEffect(() => this.actions$.pipe(
    ofType(fromApp.Actions.LoadIsWorkerPayrollRequired),
    tap(action => this.getCurrentPayload(action)),
    // dispatch action to add appstate.loadrers on current feature state
    tap(action => this.store$.dispatch(
      fromApp.Actions.LoadApps({payload: 'loadIsWorkerPayrollRequired$'}))),
    withLatestFrom(this.store$),
    tap(([action, payload]) => {
      this.mergePayload(payload.appstate.internetRegistration);
    }),
    switchMap(([action, state]) =>
      this.appService.postApp(this.getIsWorkerPayrollRequiredUrl, this.mergedPayload)
        .pipe(
          tap(response => console.log('[App Effect] loadIsWorkerPayrollRequired request response: ' + JSON.stringify(response))),
          // dispatch action to update appstate with new reponse
          map(response => (
            fromApp.Actions.LoadIsWorkerPayrollRequiredSuccess({payload: response})
          )),
          catchError(e => throwError(new Error(JSON.stringify(e)))),
          finalize(() => {
            // dispatch action to remove loader from appstate.loaders
            this.store$.dispatch(
              fromApp.Actions.LoadAppsSuccess({payload: 'loadIsWorkerPayrollRequired$'}));
          })
        )
    )));

  loadCurrentDateTime$ = createEffect(() => this.actions$.pipe(
    ofType(fromApp.Actions.LoadCurrentDateTime),
    // dispatch action to add appstate.loadrers on current feature state
    tap(action => this.store$.dispatch(
      fromApp.Actions.LoadApps({payload: 'loadCurrentDateTime$'}))),
    withLatestFrom(this.store$),
    switchMap(([action, payload]) =>
      this.appService.getApp(this.requestCurrentDateTimeUrl)
        .pipe(
          tap(response => console.log('[App Effect] loadCurrentDateTime request response: ' +
          JSON.stringify(response))),
          // dispact action to update current feature state
          map(response => (
            fromApp.Actions.LoadCurrentDateTimeSuccess({payload: response})
          )),
          catchError(e => throwError(new Error(JSON.stringify(e)))),
          finalize(() => {
            // dispatch action to remove loader from appstate.loaders
            this.store$.dispatch(
              fromApp.Actions.LoadAppsSuccess({payload: 'loadCurrentDateTime$'}));
          })
        )
    )
  ));
  loadCurrentDateTimeE2E$ = createEffect(() => this.actions$.pipe(
    ofType(fromApp.Actions.LoadCurrentDateTimeE2E),
    // dispatch action to add appstate.loadrers on current feature state
    tap(action => this.store$.dispatch(
      fromApp.Actions.LoadApps({payload: 'loadCurrentDateTimeE2E$'}))),
    withLatestFrom(this.store$),
    switchMap(([action, payload]) =>
      this.appService.getApp(this.requestCurrentDateTimeUrlE2E)
        .pipe(
          tap(response => console.log('[App Effect] loadCurrentDateTimeE2E request response: ' +
          JSON.stringify(response))),
          // dispact action to update current feature state
          map(response => (
            fromApp.Actions.LoadCurrentDateTimeSuccess({payload: response})
          )),          
          catchError(e => throwError(() => new Error(e))),
          finalize(() => {
            // dispatch action to remove loader from appstate.loaders
            this.store$.dispatch(
              fromApp.Actions.LoadAppsSuccess({payload: 'loadCurrentDateTimeE2E$'}));
          })
        )
    )
  )); 

  
  loadInternetRegistrationByIRNumberE2E$ = createEffect(() => this.actions$.pipe(
    ofType(fromApp.Actions.LoadInternetRegistrationByIRNumberE2E),
    tap(action => this.getiRegIRNumber(action)),
    // dispatch action to add appstate.loadrers on current feature state
    tap(action => this.store$.dispatch(fromApp.Actions.LoadApps({payload: 'LoadInternetRegistrationByIRNumberE2E$'}))),
    withLatestFrom(this.store$),
    tap(([action, payload]) => { this.getEmail(payload); }),
    switchMap((action) =>
      this.appService.getApp(`${this.getRequestUrlInternetRegistrationByIRRefNumE2E}?emailAddress=${encodeURIComponent(this.email)}&irRefNumber=${this.iRegIRNumber}`)
        .pipe(
          tap(response => console.log('[App Effect] request response: ' + JSON.stringify(response))),
         // dispact action to update current feature state
         map(response => (
          fromApp.Actions.LoadInternetRegistrationByIRNumberSuccess({payload: this.updateResponse(response)})
        )),
          catchError(e => throwError(new Error(JSON.stringify(e)))),
          finalize(() => {
            // dispatch action to remove loader from appstate.loaders
            this.store$.dispatch(fromApp.Actions.LoadAppsSuccess({payload: 'LoadInternetRegistrationByIRNumberE2E$'}));
          })
        )
    )
  ));

  loadClassificationPredictionInfo$ = createEffect(() => this.actions$.pipe(
    ofType(fromApp.Actions.LoadClassificationPredictionInfo),  
    tap(action => this.mergeClassificationPredictionPayload(action)),
    // dispatch action to add appstate.loadrers on current feature state
    tap(action => this.store$.dispatch(fromApp.Actions.LoadApps({payload: 'loadClassificationPredictionInfo$'}))),
    withLatestFrom(this.store$),
    tap(([action, payload]) => {
      this.mergePayload(payload.appstate.internetRegistration);
    }),
    switchMap(([action, state]) =>
      this.appService.postApp(this.getClassificationPredictionRequestUrl, this.mergedClassificationPredictionPayload)
        .pipe(
          tap(response => console.log('[App Effect] loadClassificationPredictionInfo request response: ' + JSON.stringify(response))),
          // dispatch action to update appstate with new reponse
          map(response => (
            fromApp.Actions.LoadClassificationPredictionInfoSuccess({
              payload: {
                searchResults: response?.classificationUnitSearchResults,
                searchPayload: this.mergedClassificationPredictionPayload,
                userSearchKeyWord: 'updated'
              }
            })
          )),
          catchError(e => throwError(() => new Error(e))),
          finalize(() => {
            // dispatch action to remove loader from appstate.loaders
            this.store$.dispatch(
              fromApp.Actions.LoadAppsSuccess({payload: 'loadClassificationPredictionInfo$'}));
          })
        )
    )));

    loadClassificationBySearchCriteria$ = createEffect(() => this.actions$.pipe(
      ofType(fromApp.Actions.LoadClassificationBySearchCriteria),  
      tap(action => this.mergeClassificationBySearchCriteriaPayload(action)),
      // dispatch action to add appstate.loadrers on current feature state
      tap(action => this.store$.dispatch(fromApp.Actions.LoadApps({payload: 'loadClassificationBySearchCriteria$'}))),
      withLatestFrom(this.store$),
      tap(([action, payload]) => {
        this.mergePayload(payload.appstate.internetRegistration);
      }),
      switchMap(([action, state]) =>
        this.appService.postApp(this.getClassificationBySearchCriteriaUrl, this.mergedClassificationBySearchCriteriaPayload)
          .pipe(
            tap(response => console.log('[App Effect] loadClassificationBySearchCriteria request response: ' + JSON.stringify(response))),
            // dispatch action to update appstate with new reponse
            map(response => (
              fromApp.Actions.LoadClassificationBySearchCriteriaSuccess({
                payload: {
                  searchResults: response?.classificationUnitSearchResults,
                  searchPayload: this.mergedClassificationPredictionPayload
                }
              })
            )),
            catchError(e => throwError(() => new Error(e))),
            finalize(() => {
              // dispatch action to remove loader from appstate.loaders
              this.store$.dispatch(
                fromApp.Actions.LoadAppsSuccess({payload: 'loadClassificationBySearchCriteria$'}));
            })
          )
      )));

    private mergeClassificationPredictionPayload(action: any): any {    
      try {
        this.mergedClassificationPredictionPayload = {
          businessDescription: action.payload.businessDescription,
          productsServices: action.payload.productsServices
        };
      } catch (ex) {
        this.mergedClassificationPredictionPayload = {
          businessDescription: '',
          productsServices: ''
        };
        console.log('mergeClassificationPredictionPayload=' + JSON.stringify(this.mergedClassificationPredictionPayload)) ;
      }
    }  

    private mergeClassificationBySearchCriteriaPayload(action: any): any {    
      try {
        this.mergedClassificationBySearchCriteriaPayload = {
          businessDescription: action.payload.businessDescription,
          productsServices: action.payload.productsServices,
          irRefNumber: action.payload.irRefNumber,
          effectiveDate: action.payload.effectiveDate,
          searchByML: action.payload.searchByML
        };
      } catch (ex) {
        this.mergedClassificationBySearchCriteriaPayload = {
          businessDescription: '',
          productsServices: '',
          irRefNumber: '',
          effectiveDate: '',
          searchByML: ''
        };
        console.log('mergedClassificationBySearchCriteriaPayload=' + JSON.stringify(this.mergedClassificationBySearchCriteriaPayload)) ;
      }
    } 
    

    private getEmail(payload: any) {
      console.log('[App Effect] payload=' + JSON.stringify(payload));
      if (payload &&  payload.appstate && payload.appstate.internetRegistration) {
        this.email = payload.appstate.internetRegistration.email;
        console.log('[App Effect] this.email=' + this.email);
      }
    }

    private getiRegIRNumber(action: any) {
      if (action.payload) {
        this.iRegIRNumber = action.payload.iRegIRNumber;
      }
      console.log('[App Effect] this.iRegIRNumber=' + this.iRegIRNumber);
    }

    private getCurrentPayload(action: any) {
      this.currentPayload = {
        internetRegistration: action.payload
      };
    }

    private mergePayload(internetRegistration: any): any {
      if (this.currentPayload && this.currentPayload.internetRegistration !== null) {
        this.mergedPayload = {
          ...internetRegistration,
          ...this.currentPayload?.internetRegistration
        };
      } else {
        this.mergedPayload = {
          ...internetRegistration
        };
      }
    }

    private updateResponse(response: any) {
      // convert AccountType to string
      // reset isReviewVisited to false to supoprt editing app again
      let mergedResponse = {};
      if (response) {
        try {
          mergedResponse = {
            ...response,
            accountType: AccountType[response.accountType],
            userChoice: {
              ...response.userChoice,
              isReviewVisited: false
            }
          };
        } catch (ex) {
          mergedResponse = response; // set to original response if the accountType is invalid
          console.log('[App Effect] convertAccountTypeToString:  exception response.accountType=' + response.accountType);
        }
      }
      console.log('[App Effect] convertAccountTypeToString: ' + JSON.stringify(mergedResponse));
      return mergedResponse;
    }
}
