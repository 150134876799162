import { Injectable } from '@angular/core';
import { LegalEntityCode, FirmType, ProfileType } from 'src/app/shared/common/common-constants';
import { AccountRegistrationDto } from 'src/app/store/models/internet-registration';

@Injectable({
  providedIn: 'root'
})
export class InternetRegistrationHelperService {
  getValidParticipant(iReg: any, participationId: string) {
    return this.getValidParticipants(iReg).find(p => p.participationId === participationId);
  }

  getValidParticipants(iReg: any): any[] {
    if (iReg && iReg.participation) {
      return iReg.participation.filter(p => p.participationId !== '0' &&
        p.regLegalEntity && p.regLegalEntity.internetLegalEntity);
    }
    return [];
  }

  getPersonalParticipants(iReg: any): any[] {
    return this.getValidParticipants(iReg).filter(p =>
      p.regLegalEntity.internetLegalEntity.legalEntityTypeCode === LegalEntityCode.personal);
  }

  findOwnerOrShareholder(iReg: any, ownerOrShareholderTypeCode : string) {   
    try {
      const item = iReg.participation[0].regLegalEntity.item;
      if (item && item.contactRelationshipTypeCode.trim().toLowerCase() != ownerOrShareholderTypeCode) {
          return null;
      }
    }
    catch{ }    

    let ple = null;
    let participants = this.getPersonalParticipants(iReg);
    const userChoiceContactNameFirst = iReg.userChoice.contactNameFirst?.trim();
    const userChoiceContactNameLast = iReg.userChoice.contactNameLast?.trim();
    const userChoiceContactTelephone = iReg.userChoice.contactTelephone?.trim();

    if (participants != null && participants.length > 0) {
      participants.forEach((p) => {
        if ( p.regParticipation.participationTypeCode?.trim() == 'sh') {            
            const plPhoneNumber = this.findPlePhoneNumber(p.regLegalEntity.legalEntityAddress, userChoiceContactTelephone);           
            if (plPhoneNumber != '' 
                && p.regLegalEntity?.item?.firstName?.trim()  === userChoiceContactNameFirst  
                && p.regLegalEntity?.item?.lastName?.trim() === userChoiceContactNameLast
                && plPhoneNumber === userChoiceContactTelephone ) {
                ple = p;                
            }               
        }              
      });      
    }
    return ple;
  }

  private findPlePhoneNumber(legalEntityAddress: any, userChoiceContactTelephone: string) {
    let plePhoneNumber = '';
    if (legalEntityAddress) {               
        legalEntityAddress.forEach((p) => {
          let combinePhone = p.phoneAreaCode + p.phoneNumber;
          if ( combinePhone == userChoiceContactTelephone) {           
              plePhoneNumber = combinePhone;                      
          }              
        });                                    
    }  
        
    return plePhoneNumber;
  }

  getLegalName(iReg: any): any[] {
    return iReg && iReg.participation && iReg.participation[0] &&
      iReg.participation[0].regLegalEntity &&
      iReg.participation[0].regLegalEntity.item &&
      iReg.participation[0].regLegalEntity.item.legalName
  }

  getBusinessParticipants(iReg: any): any[] {
    return this.getValidParticipants(iReg).filter(p =>
      p.regLegalEntity.internetLegalEntity.legalEntityTypeCode === LegalEntityCode.business);
  }

  getFirmType(iReg: any): FirmType | null {
    return iReg && iReg.participation && iReg.participation[0] &&
      iReg.participation[0].regLegalEntity &&
      iReg.participation[0].regLegalEntity.item ?
      iReg.participation[0].regLegalEntity.item.firmTypeCode : null;
  }

  getSharedDataPoolBusType(iReg: any): FirmType | null {
    return iReg && iReg.regSharedDataPool && iReg.regSharedDataPool.sharedDataPool ?
      iReg.regSharedDataPool.sharedDataPool.sdpBusType as FirmType : null;
  }

  // Using profileType as internetRegistration stores the string value
  hasShareholders(iReg: AccountRegistrationDto): boolean {
    if (iReg.accountType === ProfileType.other) {
      return this.getFirmType(iReg) === FirmType.limitedCompany &&
        iReg.eligibility.eligibilityResponseSet1.publiclyTradedIndicator === 'N' &&
        iReg.eligibility.eligibilityResponseSet1.movieTVProductionIndicator === 'N';
    } else if (iReg.accountType === ProfileType.retailer || iReg.accountType === ProfileType.restaurant) {
      return this.getFirmType(iReg) === FirmType.limitedCompany &&
        iReg.eligibility.eligibilityResponseSet1.publiclyTradedIndicator === 'N';
    }
    return false;
  }

  isReviewVisited(iReg: any): boolean {
    if (iReg && iReg.userChoice) {
      return iReg.userChoice.isReviewVisited;
    }
    return undefined;
  }

  getNumberOfWorkers(iReg: any): number {
    if (iReg && iReg.eligibility && iReg.eligibility.eligibilityResponseSet1 &&
      iReg.eligibility.eligibilityResponseSet1.registeredWorkerCount) {
      return iReg.eligibility.eligibilityResponseSet1.registeredWorkerCount;
    }
    return 0;
  }

  getNumberOfShareholders(iReg: any): number {
    if (iReg && iReg.eligibility && iReg.eligibility.eligibilityResponseSet1 &&
      iReg.eligibility.eligibilityResponseSet1.activeShareHolderCount) {
      return Number(iReg.eligibility.eligibilityResponseSet1.activeShareHolderCount);
    }
    return 0;
  }

  getAcceptedCU(iReg: any): string {
    // get acceptedCU
    if (iReg.classification &&
      iReg.classification.classificationSummary &&
      iReg.classification.classificationSummary != null &&
      iReg.classification.classificationSummary.finalClassificationUnit != null &&
      iReg.classification.classificationSession.slice(-1)[0] &&
      iReg.classification.classificationSession.slice(-1)[0].classificationAssignment.slice(-1)[0]) {
      return iReg.classification.classificationSession.slice(-1)[0].classificationAssignment.slice(-1)[0].classificationId;
    }

    return undefined;
  }

  getInternetRegistrationNumber(iReg: any): string {
    if (iReg && iReg.registrationSession) {
      return iReg.registrationSession.iRegReferenceNumber;
    }
    return '';
  }

  isSkipReviewCoverage(iReg: any): boolean {
    if (this.getFirmType(iReg) !== FirmType.limitedCompany ||
      (iReg.eligibility.eligibilityResponseSet1.publiclyTradedIndicator === 'Y' ||
        iReg.eligibility.eligibilityResponseSet1.movieTVProductionIndicator === 'Y')) {
      return true;
    }
    return false;
  }

  isIncorporated(firmType: FirmType, accountType: ProfileType): boolean {
    return accountType === ProfileType.other &&
      (firmType === FirmType.limitedCompany || this.isOtherIncorporatedFirmType(firmType));
  }

  isOtherIncorporatedFirmType(firmType: FirmType): boolean {
    switch (firmType) {
      case FirmType.band:
      case FirmType.association:
      case FirmType.church:
      case FirmType.cooperative:
      case FirmType.society:
      case FirmType.union:
        return true;
    }
    return false;
  }

  hasWorkerPayroll(iReg: any): boolean {
    const iRegPayroll = iReg && iReg.payroll ? iReg.payroll : null;
    const workerPayroll = iRegPayroll ? iRegPayroll.filter(p => p.payrollTypeCD && p.payrollTypeCD.toUpperCase().trim() === 'W') : [];
    if (workerPayroll && workerPayroll.length > 0) {
      return true;
    }
    return false;
  }

  getParticipantNames(iReg: any, firmType: FirmType): string[] {
    const names: string[] = [];
    if (firmType === FirmType.limitedCompany) {
      if (iReg && iReg.participation &&
        iReg.participation[0] &&
        iReg.participation[0].regLegalEntity &&
        iReg.participation[0].regLegalEntity.item) {
      const businessName = iReg.participation[0].regLegalEntity.item.legalName;
      names.push(businessName);
      return names;
      }
    } else if (firmType === FirmType.partner) {
      return this.getPartnershipNames(iReg);
    } else if (firmType === FirmType.proprietor) {
      if ( iReg.participation[1] != null  ) {
        const applicantFirstName = iReg.participation[1].regLegalEntity.item.firstName;
        const applicantLastName = iReg.participation[1].regLegalEntity.item.lastName;
        names.push(applicantFirstName + ' ' + applicantLastName);
        return names;
      }
    }
  }

  getPartnershipNames(iReg: any): string[] {
    const participants = this.getValidParticipants(iReg);
    const names: string[] = [];
    for (const participant of participants) {
      if (participant.regLegalEntity.internetLegalEntity.legalEntityTypeCode === LegalEntityCode.personal) {
        const firstName = participant.regLegalEntity.item.firstName;
        const lastName = participant.regLegalEntity.item.lastName;
        names.push(firstName + ' ' + lastName);
      } else if (participant.regLegalEntity.internetLegalEntity.legalEntityTypeCode === LegalEntityCode.business) {
        names.push(participant.regLegalEntity.item.legalName);
      }
    }
    return names;
  }

  hasShareholdersForRetailRestaurant(iReg: any): boolean {
    return this.getFirmType(iReg) === FirmType.limitedCompany &&
      iReg.eligibility.eligibilityResponseSet1.publiclyTradedIndicator === 'N';
  }

  hasActiveRegistrationApplication(iReg: any): boolean {
    return iReg && iReg.registrationSession && iReg.registrationSession.iRegReferenceNumber;
  }
}
