import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppInsightsService } from 'src/app/core/appinsights.service';

@Injectable()
export class StartPageViewLogGuard implements CanActivate {

    constructor(
        private appInsightsService: AppInsightsService) {
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        this.appInsightsService.logStartTrackpage(state.url + ' page loaded');       
        return true;
    }
}
